import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch, Navigate } from 'react-router-dom'
import authHeader from '../../services/AuthHeader'
import Button from '../UI/Button'
import AuthService from '../../services/AuthService';

const AddOrUpdateClientProject = () => {

    const match = useMatch('/addOrUpdateClientProject/:projectId')

    const navigate = useNavigate()

    const [clientsLoading, setClientsLoading] = useState(true)
    const [clients, setClients] = useState([])
    const [contactsLoading, setContactsLoading] = useState(true)

    const [projects, setProjects] = useState([])
    const [projectsLoading, setProjectsLoading] = useState(true)

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [contacts, setContacts] = useState([])
    const [deals, setDeals] = useState([])

    const [loading, setLoading] = useState(false)
    const [projectNumber, setProjectNumber] = useState('')
    const [projectStatus, setProjectStatus] = useState('')
    const [projectClientContactId, setProjectClientContactId] = useState('')
    const [projectManagerId, setProjectManagerId] = useState('')
    const [projectName, setProjectName] = useState('')
    const [clientId, setClientId] = useState('')
    const [projectDescription, setProjectDescription] = useState('')
    const [currency, setCurrency] = useState('')
    const [clientReference, setClientReference] = useState('')
    const [company, setCompany] = useState('')
    const [projectCreatedDate, setProjectCreatedDate] = useState('')
    const [projectLiveDate, setProjectLiveDate] = useState('')
    const [projectClosedDate, setProjectClosedDate] = useState('')
    const [todaysDate, setTodaysDate] = useState('')

    const [projectSustainabilityTargets, setProjectSustainabilityTargets] = useState('')
    const [projectLocation, setProjectLocation] = useState('')
    const [projectDrawings, setProjectDrawings] = useState('')
    const [projectUseClassOfBuilding, setProjectUseClassOfBuilding] = useState('')
    const [projectType, setProjectType] = useState('')
    const [projectGrossInternalArea, setProjectGrossInternalArea] = useState('')
    const [projectCurrentRIBAStage, setProjectCurrentRIBAStage] = useState('')
    const [projectProgrammeProvided, setProjectProgrammeProvided] = useState('0')

    const [projectSecondaryUseClassOfBuilding, setProjectSecondaryUseClassOfBuilding] = useState('')
    const [projectGrossInternalLandArea, setProjectGrossInternalLandArea] = useState('')
    const [projectBuildingDataLastUpdated, setProjectBuildingDataLastUpdated] = useState('')
    const [dealNumberSeed, setDealNumberSeed] = useState('')
    const [dealNumberSeedName, setDealNumberSeedName] = useState('')

    const [projectBillingPlanAndFeesChecked, setProjectBillingPlanAndFeesChecked] = useState('NO')
    const [projectInvoicingChecked, setProjectInvoicingChecked] = useState('NO')
    const [projectInvoicingNotes, setProjectInvoicingNotes] = useState('')
    const [projectPostcode, setProjectPostcode] = useState('')

    const [redirecting, setRedirecting] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL;

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const checkProjectName = (newProjectString) => {
        var stringOfProjects = ""
        var projectInstance
        var numberOfProjectsToShow
        if (projects.filter(item => item.projectName.toLowerCase().match(newProjectString.toLowerCase())).length > 0) {

            if (projects.filter(item => item.projectName.toLowerCase().match(newProjectString.toLowerCase())).length < 6) {
                numberOfProjectsToShow = projects.filter(item => item.projectName.toLowerCase().match(newProjectString.toLowerCase())).length
            } else {
                numberOfProjectsToShow = 5
            }

            for (projectInstance=0; projectInstance < numberOfProjectsToShow; projectInstance++) {

                stringOfProjects = stringOfProjects + projects.filter(item => item.projectName.toLowerCase().match(newProjectString.toLowerCase()))[projectInstance].projectName + "\n"

            }

            window.alert("These projects already exist with '" + newProjectString + "' in the project name:\n" + stringOfProjects + "....\n (" + (projects.filter(item => item.projectName.toLowerCase().match(newProjectString.toLowerCase())).length) + " in total)")
        
        } else {

            window.alert("No exisitng projects have '" + newProjectString + "' in the project name")
        }

    }

    useEffect(() => {

        axios.get(API_URL + '/projects')
            .then((res) => {
                setProjects(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setProjectsLoading(false)
            });    

        axios.get(API_URL + "/clients", { headers: authHeader() })
            .then((response) => {
                setClients(response.data.sort((item1, item2) => item1.clientName.toLowerCase() + item1.clientName.toLowerCase() < item2.clientName.toLowerCase() + item2.clientName.toLowerCase() ? -1 : 0));
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(_content);
                    setRedirecting(true)
                },
            ).finally(() => {
                setClientsLoading(false);
            });


        axios.get(API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                if (match.params.projectId === "_add") {
                    setProjectManagerId(res.data.sort((item1, item2) => item1.firstName < item2.firstName ? -1 : 0)[0].id)
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

        axios.get(API_URL + '/contacts')
            .then((res) => {
                setContacts(res.data.sort((item1, item2) => item1.contactName.toLowerCase() < item2.contactName.toLowerCase() ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setContactsLoading(false);
            });

        axios.post(process.env.REACT_APP_API_URL + '/hubspotDealsFiltered')
            .then((response) => {
                setDeals(response.data.results)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        if (match.params.projectId !== "_add") {

            setLoading(true)

            axios.get(API_URL + '/projects/' + match.params.projectId)
                .then((res) => {
                    setProjectNumber(res.data.projectNumber)
                    setProjectStatus(res.data.projectStatus)
                    setProjectClientContactId(res.data.projectClientContactId)
                    setProjectManagerId(res.data.projectManagerId)
                    setProjectName(res.data.projectName)
                    setClientId(res.data.clientId)
                    setProjectDescription(res.data.projectDescription)
                    setCurrency(res.data.currency)
                    setClientReference(res.data.clientReference)
                    setCompany(res.data.company)
                    setProjectCreatedDate(res.data.projectCreatedDate)
                    setProjectLiveDate(res.data.projectLiveDate)
                    setProjectClosedDate(res.data.projectClosedDate)
                    setProjectSustainabilityTargets(res.data.projectSustainabilityTargets)
                    setProjectLocation(res.data.projectLocation)
                    setProjectDrawings(res.data.projectDrawings)
                    setProjectUseClassOfBuilding(res.data.projectUseClassOfBuilding)
                    setProjectType(res.data.projectType)
                    setProjectGrossInternalArea(res.data.projectGrossInternalArea)
                    setProjectCurrentRIBAStage(res.data.projectCurrentRIBAStage)
                    setProjectProgrammeProvided(res.data.projectProgrammeProvided)
                    setProjectSecondaryUseClassOfBuilding(res.data.projectSecondaryUseClassOfBuilding)
                    setProjectGrossInternalLandArea(res.data.projectGrossInternalLandArea)
                    setProjectBuildingDataLastUpdated(res.data.projectBuildingDataLastUpdated)
                    setDealNumberSeed(res.data.dealNumberSeed)
                    setDealNumberSeedName(res.data.dealNumberSeedName)
                    setProjectBillingPlanAndFeesChecked(res.data.projectBillingPlanAndFeesChecked)
                    setProjectInvoicingChecked(res.data.projectInvoicingChecked)
                    setProjectInvoicingNotes(res.data.projectInvoicingNotes)
                    setProjectPostcode(res.data.projectPostcode)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }

        const newDate = new Date()

        setTodaysDate(formatDate(newDate))
        if (match.params.projectId === "_add") {
            setProjectCreatedDate(formatDate(newDate))
        }

    }, [match.params.projectId])

    const addForm = async (form) => {
        await axios.post(API_URL + '/projects', form)
    }

    const updateForm = async (form) => {
        await axios.put(API_URL + '/projects/' + match.params.projectId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (projectName && projectStatus !== "" && currency !== "" && company !== "" && clientId !== "" && projectClientContactId !== "" && projectManagerId !== "") {

            let varDealName = ""

            // if (projectStatus==="Live" && projectManagerId==="6303a09f7ba3f683fef4e25a") { //userID = TBC
            if (projectManagerId==="6303a09f7ba3f683fef4e25a") { //userID = TBC
                window.alert("Please select project manager")
            }else{
                if (dealNumberSeed.length!==0){
                    varDealName = deals.find(item => item.id === dealNumberSeed).properties.dealname
                }    
                if (match.params.projectId !== "_add") {
                    updateForm({ projectNumber, projectStatus, projectClientContactId, projectManagerId, projectName, clientId, projectDescription, currency, toBeDeleted: "NO", clientReference, company, projectCreatedDate, projectLiveDate, projectClosedDate, projectSustainabilityTargets, projectLocation, projectDrawings, projectUseClassOfBuilding, projectType, projectGrossInternalArea, projectCurrentRIBAStage, projectProgrammeProvided, projectSecondaryUseClassOfBuilding, projectGrossInternalLandArea, projectBuildingDataLastUpdated, dealNumberSeed, dealNumberSeedName: varDealName, projectBillingPlanAndFeesChecked, projectInvoicingChecked, projectInvoicingNotes, projectPostcode }).then(res => { navigate(-1) })
                } else {
                    //get lastProjectNumber and add one
                    axios.get(API_URL + '/projects')
                        .then((res) => {
                            setProjects(res.data)
                            if (res.data.length === 0) {
                                addForm({ projectNumber: 10000, projectStatus, projectClientContactId, projectManagerId, projectName, clientId, projectDescription, currency, toBeDeleted: "NO", clientReference, company, projectCreatedDate: todaysDate, projectLiveDate: "", projectClosedDate: "", projectSustainabilityTargets, projectLocation, projectDrawings, projectUseClassOfBuilding, projectType, projectGrossInternalArea, projectCurrentRIBAStage, projectProgrammeProvided, projectSecondaryUseClassOfBuilding, projectGrossInternalLandArea, projectBuildingDataLastUpdated, dealNumberSeed, dealNumberSeedName: varDealName, projectBillingPlanAndFeesChecked, projectInvoicingChecked, projectInvoicingNotes, projectPostcode }).then(res => { navigate(-1) })
                            } else {
                                addForm({ projectNumber: Number(res.data.sort((item1, item2) => (Number(item1.projectNumber) < Number(item2.projectNumber)) ? -1 : (Number(item1.projectNumber) > Number(item2.projectNumber)) ? 1 : 0)[res.data.length - 1].projectNumber) + 1, projectStatus, projectClientContactId, projectManagerId, projectName, clientId, projectDescription, currency, toBeDeleted: "NO", clientReference, company, projectCreatedDate: todaysDate, projectLiveDate: "", projectClosedDate: "", projectSustainabilityTargets, projectLocation, projectDrawings, projectUseClassOfBuilding, projectType, projectGrossInternalArea, projectCurrentRIBAStage, projectProgrammeProvided, projectSecondaryUseClassOfBuilding, projectGrossInternalLandArea, projectBuildingDataLastUpdated, dealNumberSeed, dealNumberSeedName: varDealName, projectBillingPlanAndFeesChecked, projectInvoicingChecked, projectInvoicingNotes, projectPostcode }).then(res => { navigate(-1) })
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        }).finally(() => {
                            setProjectsLoading(false)
                        });    
                }
            }
        } else {
            if (projectName === "") {
                window.alert("Please enter a project name")
            }
            if (projectStatus === "") {
                window.alert("Please set project status")
            }
            if (currency === "") {
                window.alert("Please select currency")
            }
            if (company === "") {
                window.alert("Please select company providing the service")
            }
            if (clientId === "") {
                window.alert("Please select client")
            }
            if (projectClientContactId === "") {
                window.alert("Please select client contact")
            }
            if (projectManagerId === "") {
                window.alert("Please select project manager")
            }
        }
    }

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (clients.length > 0 && contacts.length > 0 && users.length > 0 && !loading && !clientsLoading && !usersLoading && !contactsLoading) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Projects - Add / Edit Project" section="Project Main Details" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Project Name</label>
                            <input type="text" placeholder="Enter Project Name Here" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                            {match.params.projectId === "_add" &&
                            <Button color="bg-bondi-blue" text="Check Project Name" onClick={() => checkProjectName(projectName)} />
                            }
                        </div>
                        <div className="form-control-8A">
                            <label>Project Status</label>
                            <select value={projectStatus} onChange={(e) => { setProjectStatus(e.target.value); setProjectLiveDate(e.target.value === "Live" ? todaysDate : ""); setProjectClosedDate(e.target.value.slice(0, 6) === "Closed" ? todaysDate : "") }}>
                                <option value="">Please Select...</option>
                                <option value="Quote">Quote</option>
                                <option value="Live">Live</option>
                                <option value="Closed-completed">Closed-completed</option>
                                <option value="Closed-cancelled">Closed-cancelled</option>
                                <option value="Closed-lost">Closed-lost</option>
                                <option value="On-hold">On-hold</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Project Client</label>
                            <select value={clientId} onChange={(e) => { setClientId(e.target.value); setProjectClientContactId(contacts.filter(item => item.clientId === e.target.value)[0].id) }} onFocus={(e) => { setClientId(e.target.value); setProjectClientContactId(contacts.filter(item => item.clientId === e.target.value)[0].id) }}>
                                {clients.map((client) => (
                                    <option key={client.id} value={client.id}>{client.clientName}</option>
                                ))}
                            </select>
                        </div>
                        {clientId &&
                            <div className="form-control-8A">
                                <label>Project Client Contact</label>
                                <select value={projectClientContactId} onChange={(e) => setProjectClientContactId(e.target.value)}>
                                    {contacts.filter(item => item.clientId === clientId).map((contact) => (
                                        <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <div className="form-control-8A">
                            <label>Project Manager</label>
                            <select value={projectManagerId} onChange={(e) => setProjectManagerId(e.target.value)}>
                                {users.filter(user => user.role !== 9999 && user.role !== 9998 && user.role !== 3001).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Project Description</label>
                            <textarea className="w-full h-16 border-2 rounded-lg shadow-md" type="text" placeholder="Enter Project Description Here" value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Project Currency</label>
                            <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="£">£</option>
                                <option value="€">€</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Client Reference</label>
                            <input type="text" placeholder="Enter Client Reference Here" value={clientReference} onChange={(e) => setClientReference(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Company Providing Service</label>
                            <select value={company} onChange={(e) => setCompany(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Eight Versa">Eight Versa</option>
                                <option value="NCS">NCS</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Project Location Postcode</label>
                            <input type="text" placeholder="Enter Project Postcode Here" value={projectPostcode} onChange={(e) => setProjectPostcode(e.target.value)} />
                        </div>
                        {(AuthService.getCurrentUserName()==="stacey.cougill@eightversa.com" || AuthService.getCurrentUserName()==="yiota.paraskeva@eightversa.com" || AuthService.getCurrentUserName()==="chris.hocknell@eightversa.com" || AuthService.getCurrentUserName()==="violeta.kriauciunaite@eightversa.com" || AuthService.getCurrentUserName()==="amina.mustaeva@eightversa.com") &&
                        <div className="form-control-8A">
                            <label>Project Invoicing Notes</label>
                            <textarea className="w-full h-16 border-2 rounded-lg shadow-md" type="text" placeholder="Enter Project Invoicing Notes Here" value={projectInvoicingNotes} onChange={(e) => setProjectInvoicingNotes(e.target.value)} />
                        </div>}

                        <div className="form-control-8A">
                            <label>Project Management Fee Allocation %</label>
                            <select value={projectProgrammeProvided} onChange={(e) => setProjectProgrammeProvided(e.target.value)}>
                                <option value="10">YES</option>
                                <option value="0">NO</option>
                            </select>
                            {/* <input className="w-full h-16 border-2 rounded-lg shadow-md" type="text" placeholder="Enter Project Management Fee Allocation Here" value={projectProgrammeProvided} onChange={(e) => setProjectProgrammeProvided(e.target.value)} /> */}
                        </div>

                        <div className="form-control-8A">
                            <label>Project Created Date</label>
                            <input className="bg-slate-100" readOnly={true} type="text" placeholder="" value={projectCreatedDate} onChange={(e) => setProjectCreatedDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Project Live Date</label>
                            <input className="bg-slate-100" readOnly={true} type="text" placeholder="" value={projectLiveDate} onChange={(e) => setProjectLiveDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Project Closed Date</label>
                            <input className="bg-slate-100" readOnly={true} type="text" placeholder="" value={projectClosedDate} onChange={(e) => setProjectClosedDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>HubSpot Deal Number</label>
                            <select value={dealNumberSeed} onChange={(e) => setDealNumberSeed(e.target.value)}>
                                <option value="">Please Select...</option>
                                {deals.map((deal) => (                                    
                                    <option key={deal.id} value={deal.id}> {deal.id + ' - ' + deal.properties.dealname}</option>
                                ))}
                            </select>
                        </div>

                        {/* <p className="font-bold text-xl">Built Environment Projects Only:</p>

                        <div className="form-control-8A">
                            <label>Sustainability targets / requirements </label>
                            <input type="text" placeholder="Enter Project Description Here" value={projectSustainabilityTargets} onChange={(e) => setProjectSustainabilityTargets(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Location (incl. postcode) </label>
                            <input type="text" placeholder="Enter Project Description Here" value={projectLocation} onChange={(e) => setProjectLocation(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Drawings provided</label>
                            <select value={projectDrawings} onChange={(e) => setProjectDrawings(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Primary use class of building </label>
                            <select value={projectUseClassOfBuilding} onChange={(e) => setProjectUseClassOfBuilding(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="A1. Shops & retail">A1. Shops & retail</option>
                                <option value="A2. Financial and professional services">A2. Financial and professional services</option>
                                <option value="A3. Restaurants and cafes">A3. Restaurants and cafes</option>
                                <option value="A4. Drinking establishments">A4. Drinking establishments</option>
                                <option value="A5. Hot food takeaways">A5. Hot food takeaways</option>
                                <option value="B1. Business Offices">B1. Business Offices</option>
                                <option value="B2. General industrial">B2. General industrial</option>
                                <option value="B8. Storage or distribution">B8. Storage or distribution</option>
                                <option value="C1. Hotels">C1. Hotels</option>
                                <option value="C2. Residential institutions">C2. Residential institutions</option>
                                <option value="C3. Dwellings">C3. Dwellings</option>
                                <option value="D1. Non-residential institutions">D1. Non-residential institutions</option>
                                <option value="D2. Assembly and leisure">D2. Assembly and leisure</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Secondary use class of building </label>
                            <select value={projectSecondaryUseClassOfBuilding} onChange={(e) => setProjectSecondaryUseClassOfBuilding(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="A1. Shops & retail">A1. Shops & retail</option>
                                <option value="A2. Financial and professional services">A2. Financial and professional services</option>
                                <option value="A3. Restaurants and cafes">A3. Restaurants and cafes</option>
                                <option value="A4. Drinking establishments">A4. Drinking establishments</option>
                                <option value="A5. Hot food takeaways">A5. Hot food takeaways</option>
                                <option value="B1. Business Offices">B1. Business Offices</option>
                                <option value="B2. General industrial">B2. General industrial</option>
                                <option value="B8. Storage or distribution">B8. Storage or distribution</option>
                                <option value="C1. Hotels">C1. Hotels</option>
                                <option value="C2. Residential institutions">C2. Residential institutions</option>
                                <option value="C3. Dwellings">C3. Dwellings</option>
                                <option value="D1. Non-residential institutions">D1. Non-residential institutions</option>
                                <option value="D2. Assembly and leisure">D2. Assembly and leisure</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Project Type </label>
                            <select value={projectType} onChange={(e) => setProjectType(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="New Build">New Build</option>
                                <option value="Refurbishment">Refurbishment</option>
                                <option value="New build and refurbishment; state areas or % in description">New build and refurbishment (state areas or % in description)</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Gross internal area of building</label>
                            <input type="text" placeholder="Enter Internal Building Here" value={projectGrossInternalArea} onChange={(e) => setProjectGrossInternalArea(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Gross area of land</label>
                            <input type="text" placeholder="Enter Land Area Here" value={projectGrossInternalLandArea} onChange={(e) => setProjectGrossInternalLandArea(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Current RIBA Stage</label>
                            <select value={projectCurrentRIBAStage} onChange={(e) => setProjectCurrentRIBAStage(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="0 - Strategic definition">0 - Strategic definition</option>
                                <option value="1 - Preparation and briefing">1 - Preparation and briefing</option>
                                <option value="2 - Concept design">2 - Concept design</option>
                                <option value="3 - Spatial coordination">3 - Spatial coordination</option>
                                <option value="4 - Technical design">4 - Technical design</option>
                                <option value="5 - Manufacturing and construction">5 - Manufacturing and construction</option>
                                <option value="6 - Handover">6 - Handover</option>
                                <option value="7 - Use">7 - Use</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Programme provided </label>
                            <select value={projectProgrammeProvided} onChange={(e) => setProjectProgrammeProvided(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Building Data Last Updated</label>
                            <input type="text" placeholder="Enter Date Building Data Last Updated Here" value={projectBuildingDataLastUpdated} onChange={(e) => setProjectBuildingDataLastUpdated(e.target.value)} />
                        </div> */}

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                <Header title="Projects - Add / Edit Project" section="Project Main Details" />
                <p>Data is loading...</p>
            </div>
            </div>)

    }

}

export default AddOrUpdateClientProject
import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate, Navigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
// import Options from '../UI/Options'
import authHeader from '../../services/AuthHeader'
import ReactSelect from 'react-select'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import AuthService from '../../services/AuthService'
import { Doughnut, Line } from "react-chartjs-2";
import ReactTooltip from 'react-tooltip';


const PerformanceTracker = () => {

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [invoicesLoading, setInvoicesLoading] = useState(true)
    const [timesheetsLoading, setTimesheetsLoading] = useState(true)
    const [projectsServicesLoading, setProjectsServicesLoading] = useState(true)
    const [redirecting, setRedirecting] = useState(false)
    const [refreshTechSkill, setRefreshTechSkill] = useState(false)    
    const [selectedUser, setSelectedUser] = useState('')
    const [performanceGoalData, setPerformanceGoalData] = useState([])
    const [performanceTechSkillsData, setPerformanceTechSkillsData] = useState([])
    const [performanceGoalDataFiltered, setPerformanceGoalDataFiltered] = useState([])
    const [performanceTechSkillsDataFiltered, setPerformanceTechSkillsDataFiltered] = useState([])
    const [performanceExchRateData, setPerformanceExchRateData] = useState([])
    const [performanceExchRateDataId, setPerformanceExchRateDataId] = useState('')
    const [users, setUsers] = useState([])
    const [yearOptions, setYearOptions ]= useState([])
    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)
    const [showAddGoal, setShowAddGoal] = useState(false)
    const [showAddTechSkill, setShowAddTechSkill] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [arrayToSendToModal, setArrayToSendToModal] = useState('')
    const [selectedFinancialYear, setSelectedFinancialYear] = useState('')
    const [selectedYearHalf, setSelectedYearHalf] = useState('')
    const [selectedGoalType, setSelectedGoalType] = useState('')
    const [selectedTechSkill, setSelectedTechSkill] = useState('')
    const [selectedTechSkillAttainment, setSelectedTechSkillAttainment] = useState('')
    const [techSkillGoalPerServiceToAdd, setTechSkillGoalPerServiceToAdd] = useState('')
    const [techSkillDateToAdd, setTechSkillDateToAdd] = useState('')
    const [goalNotesToAdd, setGoalNotesToAdd] = useState('')
    const [goalDateToAdd, setGoalDateToAdd] = useState('')
    const [goalDetailsToAdd, setGoalDetailsToAdd] = useState('')
    const [userDepartment, setUserDepartment] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [invoices, setInvoices] = useState([])
    const [invoicesFiltered, setInvoicesFiltered] = useState([])
    const [timesheets, setTimesheets] = useState([])
    const [timesheetsFiltered, setTimesheetsFiltered] = useState([])
    const [projectsServices, setProjectsServices] = useState([])
    const [projectsServicesFiltered, setProjectsServicesFiltered] = useState([])
    const [workStagesList, setWorkStagesList] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [departmentsList, setDepartmentsList] = useState([])
    const [department, setDepartment] = useState('')
    const [service, setService] = useState('')
    const [workStage, setWorkStage] = useState('')
    const [departmentsServicesActivities, setDepartmentsServicesActivities] = useState([])
    const [hoursPerWeek, setHoursPerWeek] = useState([])
    const [currentUserName, setCurrentUserName] = useState('')
    const [selectedUsername, setSelectedUsername] = useState('')
    const [selectedUserLineManager, setSelectedUserLineManager] = useState('')
    const [selectedUserLineManagerId, setSelectedUserLineManagerId] = useState('')
    const [invMonthTotalGBP1, setInvMonthTotalGBP1] = useState(0)
    const [invMonthTotalGBP2, setInvMonthTotalGBP2] = useState(0)
    const [invMonthTotalGBP3, setInvMonthTotalGBP3] = useState(0)
    const [invMonthTotalGBP4, setInvMonthTotalGBP4] = useState(0)
    const [invMonthTotalGBP5, setInvMonthTotalGBP5] = useState(0)
    const [invMonthTotalGBP6, setInvMonthTotalGBP6] = useState(0)
    const [invMonthAverageGBP, setInvMonthAverageGBP] = useState(0);
    const [invMonthTotalGBP, setInvMonthTotalGBP] = useState(0)
    const [invMonthTotalEUR1, setInvMonthTotalEUR1] = useState(0)
    const [invMonthTotalEUR2, setInvMonthTotalEUR2] = useState(0)
    const [invMonthTotalEUR3, setInvMonthTotalEUR3] = useState(0)
    const [invMonthTotalEUR4, setInvMonthTotalEUR4] = useState(0)
    const [invMonthTotalEUR5, setInvMonthTotalEUR5] = useState(0)
    const [invMonthTotalEUR6, setInvMonthTotalEUR6] = useState(0)
    const [invMonthTotalEUR, setInvMonthTotalEUR] = useState(0)
    const [invMonthTargetGBP1, setInvMonthTargetGBP1] = useState(0)
    const [invMonthTargetGBP2, setInvMonthTargetGBP2] = useState(0)
    const [invMonthTargetGBP3, setInvMonthTargetGBP3] = useState(0)
    const [invMonthTargetGBP4, setInvMonthTargetGBP4] = useState(0)
    const [invMonthTargetGBP5, setInvMonthTargetGBP5] = useState(0)
    const [invMonthTargetGBP6, setInvMonthTargetGBP6] = useState(0)
    const [timesheetProductivityMonth1, setTimesheetProductivityMonth1] = useState(0)
    const [timesheetProductivityMonth2, setTimesheetProductivityMonth2] = useState(0)
    const [timesheetProductivityMonth3, setTimesheetProductivityMonth3] = useState(0)
    const [timesheetProductivityMonth4, setTimesheetProductivityMonth4] = useState(0)
    const [timesheetProductivityMonth5, setTimesheetProductivityMonth5] = useState(0)
    const [timesheetProductivityMonth6, setTimesheetProductivityMonth6] = useState(0)
    const [timesheetProductivityAverage, setTimesheetProductivityAverage] = useState(0)
    const [timesheetCompletenessMonth1, setTimesheetCompletenessMonth1] = useState(0)
    const [timesheetCompletenessMonth2, setTimesheetCompletenessMonth2] = useState(0)
    const [timesheetCompletenessMonth3, setTimesheetCompletenessMonth3] = useState(0)
    const [timesheetCompletenessMonth4, setTimesheetCompletenessMonth4] = useState(0)
    const [timesheetCompletenessMonth5, setTimesheetCompletenessMonth5] = useState(0)
    const [timesheetCompletenessMonth6, setTimesheetCompletenessMonth6] = useState(0)
    const [timesheetCompletenessAverage, setTimesheetCompletenessAverage] = useState(0)
    const [monthHeading1, setMonthHeading1] = useState(0)
    const [monthHeading2, setMonthHeading2] = useState(0)
    const [monthHeading3, setMonthHeading3] = useState(0)
    const [monthHeading4, setMonthHeading4] = useState(0)
    const [monthHeading5, setMonthHeading5] = useState(0)
    const [monthHeading6, setMonthHeading6] = useState(0)
    const [svcCompleteEstVsActual1, setSvcCompleteEstVsActual1] = useState(0)
    const [svcCompleteEstVsActual2, setSvcCompleteEstVsActual2] = useState(0)
    const [svcCompleteEstVsActual3, setSvcCompleteEstVsActual3] = useState(0)
    const [svcCompleteEstVsActual4, setSvcCompleteEstVsActual4] = useState(0)
    const [svcCompleteEstVsActual5, setSvcCompleteEstVsActual5] = useState(0)
    const [svcCompleteEstVsActual6, setSvcCompleteEstVsActual6] = useState(0)
    const [monthRecovery1, setMonthRecovery1] = useState(0)
    const [monthRecovery2, setMonthRecovery2] = useState(0)
    const [monthRecovery3, setMonthRecovery3] = useState(0)
    const [monthRecovery4, setMonthRecovery4] = useState(0)
    const [monthRecovery5, setMonthRecovery5] = useState(0)
    const [monthRecovery6, setMonthRecovery6] = useState(0)
    const [currentUserId, setCurrentUserId] = useState('')
    const [goalTargetInvoiceDataUpdated, setGoalTargetInvoiceDataUpdated] = useState(false)
    const [goalDateChanged, setGoalDateChanged] = useState(false)
    const [techSkillDateChanged, setTechSkillDateChanged] = useState(false)
    const [exchRateChanged, setExchRateChanged] = useState(false)
    const [productivityData, setProductivityData] = useState([]);
    const [productivityLabels, setProductivityLabels] = useState([]);
    const [invoicingDataGBP, setInvoicingDataGBP] = useState([]);
    const [invoicingLabels, setInvoicingLabels] = useState([]);
    const [invoicingAverageData, setInvoicingAverageData] = useState([]);
    const [invoicingTargetData, setInvoicingTargetData] = useState([]);
    const [rateEURGBP, setRateEURGBP] = useState('')
    const [isLineManager, setIsLineManager] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState(false)
    const [isTheUserCurrentlySelected, setIsTheUserCurrentlySelected] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL;

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Productivity',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                // maxWidth: 100,
                position: "bottom",
                // labels: {
                //     font: {
                //         size: 15,
                //         family: 'TT Norms Pro'
                //     },
                // },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    const invoicingChartOptions = {

        indexAxis: 'x',
        scales: {
            y: {
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Invoices Issued',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                position: 'bottom', // You can change the position as needed (top, bottom, left, right)
            },
            datalabels: {
                display: false,
            }
        },
        
        responsive: true,

    };

    const productivityChart = {
        labels: productivityLabels,
        datasets: [
            {
                data: productivityData,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const invoicingChart = {
        labels: invoicingLabels,
        datasets: [
            {
                label: 'Total Invoices',
                data: invoicingDataGBP,
                backgroundColor: 'white',
                // hoverBackgroundColor: 'white',
                borderColor: '#0E2343', 
            },
            {
                label: 'Average',
                data: invoicingAverageData,
                backgroundColor: 'white',
                // hoverBackgroundColor: chartHoverColours,
                borderColor: '#007980', 
                borderDash: [5, 5], // Dashed line
            },
            {
                label: 'Target',
                data: invoicingTargetData,
                backgroundColor: 'white',
                // hoverBackgroundColor: chartHoverColours,
                borderColor: '#732912', 
                borderDash: [5, 5], // Dashed line
            },
            // {
            //     label: 'Invoices €',
            //     data: invoicingDataEUR,
            //     backgroundColor: 'white',
            //     borderColor: '#96abb3',
            // },
        ],
    };

    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    //Date formatting to set today's date as yyyy-mm-dd
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = yyyy + '-' + mm + '-' + dd;

    const currentYear = date => {

        const dateConverted = new Date(date)
        const yyyy = dateConverted.getFullYear();
        const formattedYear = yyyy

        return formattedYear
    }

    function stringToDate(_date,_format,_delimiter)
    {
        var formatLowerCase=_format.toLowerCase();
        var formatItems=formatLowerCase.split(_delimiter);
        var dateItems=_date.split(_delimiter);
        var monthIndex=formatItems.indexOf("mm");
        var dayIndex=formatItems.indexOf("dd");
        var yearIndex=formatItems.indexOf("yyyy");
        var month=parseInt(dateItems[monthIndex]);
        month-=1;
        var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
        return formatedDate;
    }

    function countMondaysInMonth(year, month) {
        const firstDayOfMonth = new Date(year, month - 1, 1); // month is 0-based index, so subtract 1
        const lastDayOfMonth = new Date(year, month, 0);
        let count = 0;
    
        for (let date = firstDayOfMonth; date <= lastDayOfMonth; date.setDate(date.getDate() + 1)) {
            if (date.getDay() === 1) { // Monday is represented by 1 in JavaScript's getDay() function
                count++;
            }
        }
    
        return count;
    }

    const populateYears = () => {

        const tempArray = []

        tempArray.push( { value: 2022, label: '2022-2023' } )
        tempArray.push( { value: 2023, label: '2023-2024' } )
        tempArray.push( { value: 2024, label: '2024-2025' } )
        tempArray.push( { value: 2025, label: '2025-2026' } )
        tempArray.push( { value: 2026, label: '2026-2027' } )
        tempArray.push( { value: 2027, label: '2027-2028' } )
        tempArray.push( { value: 2028, label: '2028-2029' } )
        tempArray.push( { value: 2029, label: '2029-2030' } )
        tempArray.push( { value: 2030, label: '2030-2031' } )
        
        setYearOptions(tempArray)

    }

    // const onMenuOpen = () => {

    //     setTimeout(()=>{
    //         const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
    //         if(selectedEl){
    //         selectedEl.scrollIntoView({behavior:'smooth', block:'nearest'});
    //         }
    //     },15);

    // };

    const noAccess = () => {

        if (company && role && !userPropertiesLoading) {
            // Since admins can have both roles and users only have 1 role
            // we have to exclude if this person has an admin role as well
            // noAccess is going to be true if the person is not admin
            const admin = [2001, 2002, 2003, 2004]
            const notadmin = !admin.includes(role)
            return notadmin
        }
    }

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                    department: val.department
                });
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department
                });
            };
            return acc;
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                acc.push({
                    workStage: val.workStage
                });
            };
            return acc;
        }, []);
    };

    const deleteGoal = async (id) => {

        if (window.confirm("Are you sure you would like to delete this record?")) {

            if (performanceGoalDataFiltered.find((item) => item.id === id).goalType === "Invoice Target")

                setInvMonthTargetGBP1(0)
                setInvMonthTargetGBP2(0)
                setInvMonthTargetGBP3(0)
                setInvMonthTargetGBP4(0)
                setInvMonthTargetGBP5(0)
                setInvMonthTargetGBP6(0)

                setInvoicingTargetData([null, null, null, null, null, null ])

                axios.delete(API_URL + '/performance_tracker_goals/' + id)

                setPerformanceGoalData(performanceGoalData.filter((item) => item.id !== id))
                setPerformanceGoalDataFiltered(performanceGoalDataFiltered.filter((item) => item.id !== id))

        }

    }

    const lockGoal = async (id, lockedStatus) => {

        const tmpPerformanceGoalData = performanceGoalData.map(item => {
            if (item.id === id) {
              return { ...item, locked: lockedStatus };
            }
            return item;
          });
        setPerformanceGoalData(tmpPerformanceGoalData);

        const tmpPerformanceGoalDataFiltered = performanceGoalDataFiltered.map(item => {
            if (item.id === id) {
              return { ...item, locked: lockedStatus };
            }
            return item;
          });
        setPerformanceGoalDataFiltered(tmpPerformanceGoalDataFiltered);

        await axios.put(API_URL + '/performance_tracker_goals/' + id, { ...performanceGoalData.filter((item) => item.id === id)[0], locked: lockedStatus })

    }

    const getGoalData = async (id, filterFromDate, filterToDate) => {

        if (id!=="" && filterFromDate!=="" && filterToDate!==""){

            await axios.get(API_URL + '/performance_tracker_goals')
            .then((response) => {
                setPerformanceGoalData(response.data)
                setPerformanceGoalDataFiltered(response.data.filter((item) => item.userId === id && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(filterFromDate,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(filterToDate,"YYYY-MM-DD","-") ))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        }

    }

    const getSkillData = async (id, filterFromDate, filterToDate) => {

        if (id!=="" && filterFromDate!=="" && filterToDate!==""){

            await axios.get(API_URL + '/performance_tracker_tech_skills')
            .then((response) => {
                setPerformanceTechSkillsData(response.data)
                setPerformanceTechSkillsDataFiltered(response.data.filter((item) => item.userId === id && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(filterFromDate,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(filterToDate,"YYYY-MM-DD","-") ))                
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
                setRefreshTechSkill(true)
            });

        }

    }

    const deleteTechSkill = async (id) => {

        if(window.confirm("Are you sure you would like to delete this record?")) {
            axios.delete(API_URL + '/performance_tracker_tech_skills/' + id)
            setPerformanceTechSkillsData(performanceTechSkillsData.filter((item) => item.id !== id))
            setPerformanceTechSkillsDataFiltered(performanceTechSkillsData.filter((item) => item.id !== id && item.userId === selectedUser.value && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(fromDate,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(toDate,"YYYY-MM-DD","-") ))
            setRefreshTechSkill(true)
          }

    }

    const resetAddGoalComponents = () => {

        setSelectedGoalType('')
        setGoalDetailsToAdd('')
        setGoalDateToAdd('')        
        setGoalNotesToAdd('')

    }

    const resetAddTechSkillComponents =() => {

        setSelectedTechSkill('')
        setSelectedTechSkillAttainment('')
        setTechSkillDateToAdd('')
        setTechSkillGoalPerServiceToAdd('')
        setDepartment('')
        setWorkStage('')
        setService('')

    }

    const addGoalToDB = async () => {

        if (selectedGoalType===''){

            window.alert("Please choose a Goal Type")
            setShowAddGoal(true)

        }else if (goalDateToAdd===''){

            window.alert("Please select a date for the goal")
            setShowAddGoal(true)

        }else if (selectedGoalType==='Invoice Target') {

            //check if there's already an invoice target for the chosen date
            //which half-year dataset should we check?
            var date = new Date(goalDateToAdd)
            var mm = date.getMonth() + 1
            var yy = date.getFullYear()
            var varFrom, varTo

            if (mm === 4 | mm === 5 | mm === 6 | mm === 7 | mm === 8 | mm === 9){
                varFrom = yy + "-04-01"
                varTo = yy + "-09-30"
            }else{
                varFrom = yy-1 + "-10-01"
                varTo = yy + "-03-31"
            }

            var tmpPerformanceGoalDataFiltered = performanceGoalData.filter((item) => item.userId === selectedUser.value && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-") && item.goalType==="Invoice Target")

            if (tmpPerformanceGoalDataFiltered.length>0){

                window.alert("Error - Invoice Target is already set for this time period.  Please edit the existing entry.")
                setShowAddGoal(true)

            }else{

                await axios.post(API_URL + '/performance_tracker_goals', { userId: selectedUser.value, managerId: selectedUserLineManagerId, startDate: goalDateToAdd, goalType:selectedGoalType.value, goalDetail:goalDetailsToAdd, goalNotes:goalNotesToAdd, goalMonth1:"", goalMonth2:"", goalMonth3:"", goalMonth4:"", goalMonth5:"", goalMonth6:"", goalSummary:"", goalStatus: "Live", locked: "NO"})
                getGoalData(selectedUser.value, fromDate, toDate)
                window.alert("Goal Added")

            }

        }else{

            await axios.post(API_URL + '/performance_tracker_goals', { userId: selectedUser.value, managerId: selectedUserLineManagerId, startDate: goalDateToAdd, goalType:selectedGoalType.value, goalDetail:goalDetailsToAdd, goalNotes:goalNotesToAdd, goalMonth1:"", goalMonth2:"", goalMonth3:"", goalMonth4:"", goalMonth5:"", goalMonth6:"", goalSummary:"", goalStatus: "Live", locked: "NO"})
            getGoalData(selectedUser.value, fromDate, toDate)
            window.alert("Goal Added")

        }

    }

    const addTechSkillToDB = async () => {
      if (service.value === "" || service.value === undefined) {
        window.alert("Please choose a skill/service");
        setShowAddTechSkill(true);
      } else if (selectedTechSkillAttainment.value === undefined) {
        window.alert("Please choose the attainment level");
        setShowAddTechSkill(true);
      } else if (techSkillDateToAdd === "") {
        window.alert("Please select a date for the tech skill");
        setShowAddTechSkill(true);
      } else {
        await axios.post(API_URL + "/performance_tracker_tech_skills", {
          userId: selectedUser.value,
          managerId: selectedUserLineManagerId,
          startDate: techSkillDateToAdd,
          attainment: selectedTechSkillAttainment.value,
          goalPerService: techSkillGoalPerServiceToAdd !== "" ? techSkillGoalPerServiceToAdd : 0,
          goalStatus: "Live",
          department: department.value,
          workStage: workStage.value,
          service: service.value,
        });
        getSkillData(selectedUser.value, fromDate, toDate);
        window.alert("Tech Skill Added");
      }
    };
    
    const getUsersForTheDropdownList = (users, currentUserId) => {

        if (users.length>0){

            var allReportingStaff =[]
            var varUsers = users.filter(item => item.lineManager === currentUserId)
            var varUsers1 = []
            var varUsers2 = []
            var varUsers3 = []
            var varUsers4 = []

            //loop through users where this user is the linemanager
            varUsers.forEach(user => {
                allReportingStaff.push({id: user.id, firstName: user.firstName, lastName: user.lastName })
                varUsers1 = users.filter(item => item.lineManager === user.id)
                varUsers1.forEach(user1 => {
                    allReportingStaff.push({id: user1.id, firstName: user1.firstName, lastName: user1.lastName })
                    varUsers2 = users.filter(item => item.lineManager === user1.id)
                    varUsers2.forEach(user2 => {
                        allReportingStaff.push({id: user2.id, firstName: user2.firstName, lastName: user2.lastName })
                        varUsers3 = users.filter(item => item.lineManager === user2.id)
                        varUsers3.forEach(user3 => {
                            allReportingStaff.push({id: user3.id, firstName: user3.firstName, lastName: user3.lastName })
                            varUsers4 = users.filter(item => item.lineManager === user3.id)
                            varUsers4.forEach(user4 => {
                                allReportingStaff.push({id: user4.id, firstName: user4.firstName, lastName: user4.lastName })
                            })
                        })
                    })
                });
            });

            allReportingStaff.push({id: currentUserId, firstName: users.find(item => item.id === currentUserId).firstName, lastName: users.find(item => item.id === currentUserId).lastName })

            setFilteredUsers(allReportingStaff.sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
        }

    }

    const getAllData = async () => {

        //  Set the Finaincial year to the current year and year half to the current year half
        const dateStr2 = currentYear(Date()) + "-04-01";

        const date1 = new Date();
        const date2 = new Date(dateStr2);

        let varFrom, varTo, userID
        let varFinancialYear, varYearHalf

        if (date1.getTime() >= date2.getTime()) {
            //set the current financial year to the one starting this April
            setSelectedFinancialYear({ value: currentYear(Date()), label: currentYear(Date()) + "-" + Number(currentYear(Date())+1) })
            varFinancialYear=currentYear(Date()) + "-" + Number(currentYear(Date())+1)
        } else{
            //set the current financial year to the one starting last April
            setSelectedFinancialYear({ value: Number(currentYear(Date())-1), label: Number(currentYear(Date())-1) + "-" + currentYear(Date()) })
            varFinancialYear=Number(currentYear(Date())-1) + "-" + currentYear(Date())
        }

        if (mm === "04" | mm === "05" | mm === "06" | mm === "07" | mm === "08" | mm === "09"){
            setSelectedYearHalf( { value: "Apr-Sep", label: "Apr-Sep" } )
            varYearHalf="Apr-Sep"
            setFromDate(currentYear(Date()) + "-04-01")
            setToDate(currentYear(Date()) + "-09-30")
            varFrom = currentYear(Date()) + "-04-01"
            varTo = currentYear(Date()) + "-09-30"
            setMonthHeading1("APR " + currentYear(Date()))
            setMonthHeading2("MAY " + currentYear(Date()))
            setMonthHeading3("JUN " + currentYear(Date()))
            setMonthHeading4("JUL " + currentYear(Date()))
            setMonthHeading5("AUG " + currentYear(Date()))
            setMonthHeading6("SEP " + currentYear(Date()))
        }else{
            setSelectedYearHalf( { value: "Oct-Mar", label: "Oct-Mar" } )
            varYearHalf="Oct-Mar"
            setFromDate(currentYear(Date())-1 + "-10-01")
            setToDate(currentYear(Date()) + "-03-31")
            varFrom = currentYear(Date())-1 + "-10-01"
            varTo = currentYear(Date()) + "-03-31"
            setMonthHeading1("OCT " + currentYear(Date()))
            setMonthHeading2("NOV " + currentYear(Date()))
            setMonthHeading3("DEC " + currentYear(Date()))
            setMonthHeading4("JAN " + currentYear(Date()))
            setMonthHeading5("FEB " + currentYear(Date()))
            setMonthHeading6("MAR " + currentYear(Date()))
        }

        await axios.get(API_URL + '/users')
        .then((res) => {
            setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            setSelectedUser({ value: res.data.find(item => item.emailId === AuthService.getCurrentUserName()).id, label: res.data.find(item => item.emailId === AuthService.getCurrentUserName()).firstName + " " + res.data.find(item => item.emailId === AuthService.getCurrentUserName()).lastName })
            setUserDepartment(res.data.find(item => item.emailId === AuthService.getCurrentUserName()).department)
            setHoursPerWeek(res.data.find(item => item.emailId === AuthService.getCurrentUserName()).hoursPerWeek)
            
            userID = res.data.find(item => item.emailId === AuthService.getCurrentUserName()).id
            setIsTheUserCurrentlySelected(userID === res.data.find(item => item.emailId === AuthService.getCurrentUserName()).id)
            
            setCurrentUserId(userID)
            setCurrentUserName(userID)
            setSelectedUsername(userID)
            var lineManagerId
            lineManagerId = res.data.find(item => item.id === userID).lineManager
            setSelectedUserLineManager(lineManagerId !== "" ? res.data.find(item => item.id === lineManagerId).firstName + " " + res.data.find(item => item.id === lineManagerId).lastName : "Not set")
            setSelectedUserLineManagerId(lineManagerId)
            setRole(res.data.find(item => item.emailId === AuthService.getCurrentUserName()).role)
            var varIsLineManager = res.data.some(item => item.lineManager === userID)
            setIsLineManager(varIsLineManager)
            var username = AuthService.getCurrentUserName()

            if (username==='stacey.cougill@eightversa.com' || username==='yiota.paraskeva@eightversa.com' || username==='cristina.porubin@eightversa.com' || username==='chris.hocknell@eightversa.com' || username==='james.wilton@eightversa.com') {
                setFilteredUsers(res.data.filter(item => item.company === 'Eight' && item.role !== 9999 && item.id !=='6303a09f7ba3f683fef4e25a').sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))  //Everyone
                setIsLineManager(true)
            }else if (!varIsLineManager) {
                setFilteredUsers([userID]) //Just this user
            }else{
                getUsersForTheDropdownList(res.data, userID) //Get a list of reporting employees
            }            
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setUsersLoading(false);
        });

        await axios.get(process.env.REACT_APP_API_URL + '/invoices_report')
        .then((res) => {
            // setInvoices(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.invoiceLineStatus !== "Third Party Fee" && item.invoiceLineStatus !== "Sub-contractor Fee" && item.invoiceLineStatus !== "Project Expenses" && item.okToInvoice === "YES" && item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && stringToDate(item.serviceCreatedDate,"YYYY-MM-DD","-") >= stringToDate("2022-09-01","YYYY-MM-DD","-")))
            // setInvoicesFiltered(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.invoiceLineStatus !== "Third Party Fee" && item.invoiceLineStatus !== "Sub-contractor Fee" && item.invoiceLineStatus !== "Project Expenses" && item.okToInvoice === "YES"  && item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && stringToDate(item.serviceCreatedDate,"YYYY-MM-DD","-") >= stringToDate("2022-09-01","YYYY-MM-DD","-") && item.serviceManager === userID && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
            setInvoices(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.okToInvoice === "YES" && item.draftInvoice === "NO" && item.invoiceLineStatus === "Green"))
            setInvoicesFiltered(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.okToInvoice === "YES"  && item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && item.serviceManager === userID && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setInvoicesLoading(false);
        });

        await axios.get(process.env.REACT_APP_API_URL + '/timesheets')
        .then((res) => {
            setTimesheets(res.data)
            setTimesheetsFiltered(res.data.filter((item) => item.userId === userID && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setTimesheetsLoading(false);
        });

        await axios.get(API_URL + '/departments_services_activities')
        .then((res) => {

            setDepartmentsServicesActivities(res.data)
            setWorkStagesList(aggregateWorkStagesArray(res.data).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0))
            setServicesList(aggregateServicesArray(res.data).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
            setDepartmentsList(aggregateDepartmentsArray(res.data).sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : 0))

        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });

        await axios.get(API_URL + '/performance_tracker_goals')
        .then((response) => {
            setPerformanceGoalData(response.data)
            setPerformanceGoalDataFiltered(response.data.filter((item) => item.userId === userID && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-") ))
            var invTargetsGBP = response.data.filter((item) => item.userId === userID && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-") && item.goalType === 'Invoice Target')
            
            if (invTargetsGBP.length > 0) {
                setInvMonthTargetGBP1(Number(invTargetsGBP[0].goalMonth1))
                setInvMonthTargetGBP2(Number(invTargetsGBP[0].goalMonth2))
                setInvMonthTargetGBP3(Number(invTargetsGBP[0].goalMonth3))
                setInvMonthTargetGBP4(Number(invTargetsGBP[0].goalMonth4))
                setInvMonthTargetGBP5(Number(invTargetsGBP[0].goalMonth5))
                setInvMonthTargetGBP6(Number(invTargetsGBP[0].goalMonth6))
            }else{
                setInvMonthTargetGBP1(0)
                setInvMonthTargetGBP2(0)
                setInvMonthTargetGBP3(0)
                setInvMonthTargetGBP4(0)
                setInvMonthTargetGBP5(0)
                setInvMonthTargetGBP6(0)
            }
            
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });

        await axios.get(API_URL + '/performance_tracker_tech_skills')
        .then((response) => {
            setPerformanceTechSkillsData(response.data)
            setPerformanceTechSkillsDataFiltered(response.data.filter((item) => item.userId === userID && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-") ))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });

        await axios.get(API_URL + '/projects_services')
        .then((response) => {
            setProjectsServices(response.data)  //I'm not filtering out closed-completed at this stage as some of the live records are required to calculate the avg hourly rate for the service
            setProjectsServicesFiltered(response.data.filter((item) => item.serviceStatus==="Closed-completed" && item.serviceManager === userID && stringToDate(item.serviceClosedDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.serviceClosedDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setProjectsServicesLoading(false);
        });

        await axios.get(API_URL + '/performance_tracker_exchange_rates')
        .then((response) => {
            setPerformanceExchRateData(response.data)
            setRateEURGBP(response.data.find((item) => item.financialYear===varFinancialYear && item.halfYear===varYearHalf && item.currencyPair==="EUR/GBP").rate)
            setPerformanceExchRateDataId(response.data.find((item) => item.financialYear===varFinancialYear && item.halfYear===varYearHalf && item.currencyPair==="EUR/GBP").id)
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)
        populateYears()

    }, [])
    
    useEffect(() => {

        setUsersLoading(true)
        setLoading(true);
        setUserPropertiesLoading(true)
        setInvoicesLoading(true)
        setTimesheetsLoading(true)
        setProjectsServicesLoading(true)
        populateYears()

        getAllData()

        setLoading(false);

    }, []);
   
    useEffect(() => {

        if (selectedUser.value!=='' && selectedUser.value !== undefined && invoices.length !== 0 && timesheets.length !== 0){
            
          if (selectedYearHalf.value==="Apr-Sep"){
              setMonthHeading1("APR " + selectedFinancialYear.value)
              setMonthHeading2("MAY " + selectedFinancialYear.value)
              setMonthHeading3("JUN " + selectedFinancialYear.value)
              setMonthHeading4("JUL " + selectedFinancialYear.value)
              setMonthHeading5("AUG " + selectedFinancialYear.value)
              setMonthHeading6("SEP " + selectedFinancialYear.value)
          }else{
              setMonthHeading1("OCT " + selectedFinancialYear.value)
              setMonthHeading2("NOV " + selectedFinancialYear.value)
              setMonthHeading3("DEC " + selectedFinancialYear.value)
              setMonthHeading4("JAN " + (Number(selectedFinancialYear.value)+1))
              setMonthHeading5("FEB " + (Number(selectedFinancialYear.value)+1))
              setMonthHeading6("MAR " + (Number(selectedFinancialYear.value)+1))
          }

          let varFrom, varTo
      
          if (selectedYearHalf.value === "Apr-Sep"){
              setFromDate(selectedFinancialYear.value + "-04-01")
              setToDate(selectedFinancialYear.value + "-09-30")
              varFrom = selectedFinancialYear.value + "-04-01"
              varTo = selectedFinancialYear.value + "-09-30"
          }else{
              setFromDate(selectedFinancialYear.value + "-10-01")
              setToDate(selectedFinancialYear.value+1 + "-03-31")
              varFrom = selectedFinancialYear.value + "-10-01"
              varTo = selectedFinancialYear.value+1 + "-03-31"
          }

          setHoursPerWeek(users.find(item => item.id === selectedUser.value).hoursPerWeek)
          //var varInvoicesFiltered = invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.invoiceLineStatus !== "Third Party Fee" && item.invoiceLineStatus !== "Sub-contractor Fee" && item.invoiceLineStatus !== "Project Expenses" && item.okToInvoice === "YES" && item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && stringToDate(item.serviceCreatedDate,"YYYY-MM-DD","-") >= stringToDate("2022-09-01","YYYY-MM-DD","-") && item.serviceManager === selectedUser.value && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-"))
          var varInvoicesFiltered = invoices.filter((item) => item.serviceManager === selectedUser.value && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-"))
          //var varInvoicesFiltered = invoices.filter((item) => item.serviceManager === selectedUser.value && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate("2023-05-01","YYYY-MM-DD","-") && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") <= stringToDate("2023-05-31","YYYY-MM-DD","-"))
          setInvoicesFiltered(varInvoicesFiltered)
          setTimesheetsFiltered(timesheets.filter((item) => item.userId === selectedUser.value && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
          setPerformanceGoalDataFiltered(performanceGoalData.filter((item) => item.userId === selectedUser.value && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
          setPerformanceTechSkillsDataFiltered(performanceTechSkillsData.filter((item) => item.userId === selectedUser.value && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
          setProjectsServicesFiltered(projectsServices.filter((item) => item.serviceStatus==="Closed-completed" && item.serviceManager === selectedUser.value && stringToDate(item.serviceClosedDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.serviceClosedDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-")))
          var lineManagerId
          lineManagerId = users.find(item => item.id === selectedUser.value).lineManager
          setSelectedUserLineManager(lineManagerId !== "" ? users.find(item => item.id === lineManagerId).firstName + " " + users.find(item => item.id === lineManagerId).lastName : "Not set")
          setSelectedUserLineManagerId(lineManagerId)
          setSelectedUsername(selectedUser.value)
          setUserDepartment(users.find(item => item.id === selectedUser.value).department)
          setRateEURGBP(performanceExchRateData.find((item) => item.financialYear===selectedFinancialYear.label && item.halfYear===selectedYearHalf.value && item.currencyPair==="EUR/GBP").rate)
          setPerformanceExchRateDataId(performanceExchRateData.find((item) => item.financialYear===selectedFinancialYear.label && item.halfYear===selectedYearHalf.value && item.currencyPair==="EUR/GBP").id)

          setIsTheUserCurrentlySelected(currentUserId === selectedUser.value)


          var invTargetsGBP = performanceGoalData.filter((item) => item.userId === selectedUser.value && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-") && item.goalType === 'Invoice Target')
          
          if (invTargetsGBP.length > 0) {
              setInvMonthTargetGBP1(Number(invTargetsGBP[0].goalMonth1))
              setInvMonthTargetGBP2(Number(invTargetsGBP[0].goalMonth2))
              setInvMonthTargetGBP3(Number(invTargetsGBP[0].goalMonth3))
              setInvMonthTargetGBP4(Number(invTargetsGBP[0].goalMonth4))
              setInvMonthTargetGBP5(Number(invTargetsGBP[0].goalMonth5))
              setInvMonthTargetGBP6(Number(invTargetsGBP[0].goalMonth6))
          }else{
              setInvMonthTargetGBP1(0)
              setInvMonthTargetGBP2(0)
              setInvMonthTargetGBP3(0)
              setInvMonthTargetGBP4(0)
              setInvMonthTargetGBP5(0)
              setInvMonthTargetGBP6(0)
          }

          //get actual vs est based on closed complete and closed date of this time period
          var tmpProjectServicesFiltered = projectsServices.filter((item) => item.serviceStatus==="Closed-completed" && item.serviceManager === selectedUser.value && stringToDate(item.serviceClosedDate,"YYYY-MM-DD","-") >= stringToDate(varFrom,"YYYY-MM-DD","-") && stringToDate(item.serviceClosedDate,"YYYY-MM-DD","-") <= stringToDate(varTo,"YYYY-MM-DD","-") && stringToDate(item.serviceCreatedDate,"YYYY-MM-DD","-") >= stringToDate('2022-09-01',"YYYY-MM-DD","-"))
          var month1EstHrs = 0
          var month2EstHrs = 0
          var month3EstHrs = 0
          var month4EstHrs = 0
          var month5EstHrs = 0
          var month6EstHrs = 0
          var month1ActHrs = 0
          var month2ActHrs = 0
          var month3ActHrs = 0
          var month4ActHrs = 0
          var month5ActHrs = 0
          var month6ActHrs = 0
          var serviceIndex
          var activityIndex
          var activityItem = 1
          var serviceId
          var activityId
          var invoiceHours1 = 0
          var invoiceHours2 = 0
          var invoiceHours3 = 0
          var invoiceHours4 = 0
          var invoiceHours5 = 0
          var invoiceHours6 = 0
          var timeSheetHours1 = 0
          var timeSheetHours2 = 0
          var timeSheetHours3 = 0
          var timeSheetHours4 = 0
          var timeSheetHours5 = 0
          var timeSheetHours6 = 0

          for (serviceIndex = 0; serviceIndex < tmpProjectServicesFiltered.length; serviceIndex++) {

            var date = new Date(tmpProjectServicesFiltered[serviceIndex].serviceClosedDate)
            var dateMonth = date.getMonth() + 1
            serviceId = tmpProjectServicesFiltered[serviceIndex].id

            for (activityIndex = 0; activityIndex < tmpProjectServicesFiltered[serviceIndex].activities.length; activityIndex++) {

                //filter timesheet data here to serviceId and activityId to get actual hours
                activityId = tmpProjectServicesFiltered[serviceIndex].activities[activityIndex].activityId

                if (dateMonth===10 || dateMonth===4) {
                    month1EstHrs = month1EstHrs + tmpProjectServicesFiltered[serviceIndex].activities[activityIndex].estimatedHours
                    month1ActHrs = month1ActHrs + timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                  }else if (dateMonth===11 || dateMonth===5) {
                    month2EstHrs = month2EstHrs + tmpProjectServicesFiltered[serviceIndex].activities[activityIndex].estimatedHours
                    month2ActHrs = month2ActHrs + timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                  }else if (dateMonth===12 || dateMonth===6) {
                    month3EstHrs = month3EstHrs + tmpProjectServicesFiltered[serviceIndex].activities[activityIndex].estimatedHours
                    month3ActHrs = month3ActHrs + timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                  }else if (dateMonth===1 || dateMonth===7) {
                    month4EstHrs = month4EstHrs + tmpProjectServicesFiltered[serviceIndex].activities[activityIndex].estimatedHours
                    month4ActHrs = month4ActHrs + timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                  }else if (dateMonth===2 || dateMonth===8) {
                    month5EstHrs = month5EstHrs + tmpProjectServicesFiltered[serviceIndex].activities[activityIndex].estimatedHours
                    month5ActHrs = month5ActHrs + timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                  }else if (dateMonth===3 || dateMonth===9) {
                    month6EstHrs = month6EstHrs + tmpProjectServicesFiltered[serviceIndex].activities[activityIndex].estimatedHours
                    month6ActHrs = month6ActHrs + timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                  }

              }

              activityItem++

          }

          setSvcCompleteEstVsActual1(isNaN(month1EstHrs/month1ActHrs) ? 0 : month1EstHrs/month1ActHrs)
          setSvcCompleteEstVsActual2(isNaN(month2EstHrs/month2ActHrs) ? 0 : month2EstHrs/month2ActHrs)
          setSvcCompleteEstVsActual3(isNaN(month3EstHrs/month3ActHrs) ? 0 : month3EstHrs/month3ActHrs)
          setSvcCompleteEstVsActual4(isNaN(month4EstHrs/month4ActHrs) ? 0 : month4EstHrs/month4ActHrs)
          setSvcCompleteEstVsActual5(isNaN(month5EstHrs/month5ActHrs) ? 0 : month5EstHrs/month5ActHrs)
          setSvcCompleteEstVsActual6(isNaN(month6EstHrs/month6ActHrs) ? 0 : month6EstHrs/month6ActHrs)

          //get the monthly recovery rate for invoices during this time period
          var tmpInvoicesFiltered = varInvoicesFiltered.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.serviceManager < item2.serviceManager ? -1 :item1.serviceManager > item2.serviceManager ? 1 : 0)
          month1ActHrs = 0
          month2ActHrs = 0
          month3ActHrs = 0
          month4ActHrs = 0
          month5ActHrs = 0
          month6ActHrs = 0
          activityIndex = 0
          activityItem = 1
          serviceId = 0
          activityId = 0
          invoiceHours1 = 0
          invoiceHours2 = 0
          invoiceHours3 = 0
          invoiceHours4 = 0
          invoiceHours5 = 0
          invoiceHours6 = 0
          timeSheetHours1 = 0
          timeSheetHours2 = 0
          timeSheetHours3 = 0
          timeSheetHours4 = 0
          timeSheetHours5 = 0
          timeSheetHours6 = 0
          var yearMonth = ""

          for (serviceIndex = 0; serviceIndex < tmpInvoicesFiltered.length; serviceIndex++) {

            var date = new Date(tmpInvoicesFiltered[serviceIndex].invoiceDate)
            var dateMonth = date.getMonth() + 1
            serviceId = tmpInvoicesFiltered[serviceIndex].serviceId

            var tmpProjectServicesFilteredForHourlyRate
            var hourlyRateTotal = 0
            var hourlyRateCount = 0
            var avgHourlyRate = 0
            var previousInvoiceDate = ""
            var timesheetFromDate = ""
            var timesheetToDate = ""
            
            //Need to lookup the proper rate rather than 85!!!!!!!!!
            //get the avg hourly rate for the activities on this service
            //for each service get the total hourly rates for all activities which do not have a cost assigned (filter out 3rd party records)
            //also count the number of records which do not have a cost so that we can accurately divide
            //I should really create functions for a lot of this...
            if (dateMonth===10) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours1 = invoiceHours1 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-11-01"
                timeSheetHours1 = timeSheetHours1 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===11) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours2 = invoiceHours2 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-12-01"
                timeSheetHours2 = timeSheetHours2 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===12) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours3 = invoiceHours3 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = (Number(selectedFinancialYear.value)+1) + "-01-01"
                timeSheetHours3 = timeSheetHours3 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===1) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours4 = invoiceHours4 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = (Number(selectedFinancialYear.value)+1) + "-02-01"
                timeSheetHours4 = timeSheetHours4 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===2) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours5 = invoiceHours5 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = (Number(selectedFinancialYear.value)+1) + "-03-01"
                timeSheetHours5 = timeSheetHours5 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===3) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours6 = invoiceHours6 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = (Number(selectedFinancialYear.value)+1) + "-04-01"
                timeSheetHours6 = timeSheetHours6 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===4) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours1 = invoiceHours1 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-05-01"
                timeSheetHours1 = timeSheetHours1 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===5) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours2 = invoiceHours2 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-06-01"
                timeSheetHours2 = timeSheetHours2 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===6) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours3 = invoiceHours3 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-07-01"
                timeSheetHours3 = timeSheetHours3 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===7) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours4 = invoiceHours4 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-08-01"
                timeSheetHours4 = timeSheetHours4 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===8) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours5 = invoiceHours5 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-09-01"
                timeSheetHours5 = timeSheetHours5 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }else if (dateMonth===9) {
                tmpProjectServicesFilteredForHourlyRate = projectsServices.filter((item) => item.id===serviceId)
                for (let index = 0; index < tmpProjectServicesFilteredForHourlyRate[0].activities.length; index++) {
                        hourlyRateTotal = hourlyRateTotal + (tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate !==  0 ? tmpProjectServicesFilteredForHourlyRate[0].activities[index].hourlyRate : 85)
                        hourlyRateCount++
                }
                avgHourlyRate = hourlyRateTotal/hourlyRateCount
                invoiceHours6 = invoiceHours6 + tmpInvoicesFiltered[serviceIndex].invoiceLineValue / avgHourlyRate
                //get the last invoice date for this service                
                var invoicesFilteredToThisService = invoices.filter((item) => item.serviceId === serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") < stringToDate(tmpInvoicesFiltered[serviceIndex].invoiceDate,"YYYY-MM-DD","-") ).sort((item1, item2) => Number(item1.invoiceDate) < Number(item2.invoiceDate) ? -1 : Number(item1.invoiceDate) > Number(item2.invoiceDate) ? 1 : 0)
                if (invoicesFilteredToThisService.length>0){
                    for (let index = 0; index < invoicesFilteredToThisService.length; index++) {
                        timesheetFromDate = invoicesFilteredToThisService[index].invoiceDate
                    }
                }else{
                    timesheetFromDate = "2022-09-01"                    
                }
                timesheetToDate = Number(selectedFinancialYear.value) + "-10-01"
                timeSheetHours6 = timeSheetHours6 + timesheets.filter((item) => item.serviceId === serviceId && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") > stringToDate(timesheetFromDate,"YYYY-MM-DD","-") && stringToDate(item.timesheetDate,"YYYY-MM-DD","-") < stringToDate(timesheetToDate,"YYYY-MM-DD","-")).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
            }

          }

        setMonthRecovery1(invoiceHours1/timeSheetHours1)
        setMonthRecovery2(invoiceHours2/timeSheetHours2)
        setMonthRecovery3(invoiceHours3/timeSheetHours3)
        setMonthRecovery4(invoiceHours4/timeSheetHours4)
        setMonthRecovery5(invoiceHours5/timeSheetHours5)
        setMonthRecovery6(invoiceHours6/timeSheetHours6)

      }
    }, [selectedUser, selectedFinancialYear, selectedYearHalf]);

    useEffect(() => {

        //Process the invoice data

        if (selectedUser.value!=='' && selectedUser.value !== undefined && invoicesFiltered.length>0){

            const varDummyArray = invoicesFiltered.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.serviceManager < item2.serviceManager ? -1 :item1.serviceManager > item2.serviceManager ? 1 : 0)

            let varInvTotalGBP=0
            let varAprTotalGBP=0
            let varMayTotalGBP=0
            let varJunTotalGBP=0
            let varJulTotalGBP=0
            let varAugTotalGBP=0
            let varSepTotalGBP=0
            let varOctTotalGBP=0
            let varNovTotalGBP=0
            let varDecTotalGBP=0
            let varJanTotalGBP=0
            let varFebTotalGBP=0
            let varMarTotalGBP=0
    
            let varInvTotalEUR=0
            let varAprTotalEUR=0
            let varMayTotalEUR=0
            let varJunTotalEUR=0
            let varJulTotalEUR=0
            let varAugTotalEUR=0
            let varSepTotalEUR=0
            let varOctTotalEUR=0
            let varNovTotalEUR=0
            let varDecTotalEUR=0
            let varJanTotalEUR=0
            let varFebTotalEUR=0
            let varMarTotalEUR=0

            var invoicingDataForChartGBP = []
            var invoicingLabelsForChart = []

            varDummyArray.forEach(item => {
                
                if (item.projectCurrency==="£"){
                    varInvTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    if (item.invoiceMonth.slice(-2)==="04"){
                        varAprTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="05"){
                        varMayTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="06"){
                        varJunTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="07"){
                        varJulTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="08"){
                        varAugTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="09"){
                        varSepTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="10"){
                        varOctTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="11"){
                        varNovTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="12"){
                        varDecTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="01"){
                        varJanTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="02"){
                        varFebTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="03"){
                        varMarTotalGBP += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }
                }else if (item.projectCurrency==="€" || item.projectCurrency==="$"){
                    varInvTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    if (item.invoiceMonth.slice(-2)==="04"){
                        varAprTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="05"){
                        varMayTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="06"){
                        varJunTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="07"){
                        varJulTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="08"){
                        varAugTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="09"){
                        varSepTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="10"){
                        varOctTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="11"){
                        varNovTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="12"){
                        varDecTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="01"){
                        varJanTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="02"){
                        varFebTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }else if (item.invoiceMonth.slice(-2)==="03"){
                        varMarTotalEUR += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
                    }
                }

            })

            var monthsToDivideByForAverageGBP = 0

            if (selectedYearHalf.value==="Apr-Sep"){

                setInvMonthTotalGBP1(varAprTotalGBP)
                setInvMonthTotalGBP2(varMayTotalGBP)
                setInvMonthTotalGBP3(varJunTotalGBP)
                setInvMonthTotalGBP4(varJulTotalGBP)
                setInvMonthTotalGBP5(varAugTotalGBP)
                setInvMonthTotalGBP6(varSepTotalGBP)
                setInvMonthTotalGBP(varInvTotalGBP)

                if (varAprTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varMayTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varJunTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varJulTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varAugTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varSepTotalGBP > 0) {monthsToDivideByForAverageGBP ++}

                setInvMonthAverageGBP(varInvTotalGBP / monthsToDivideByForAverageGBP)

                setInvMonthTotalEUR1(varAprTotalEUR)
                setInvMonthTotalEUR2(varMayTotalEUR)
                setInvMonthTotalEUR3(varJunTotalEUR)
                setInvMonthTotalEUR4(varJulTotalEUR)
                setInvMonthTotalEUR5(varAugTotalEUR)
                setInvMonthTotalEUR6(varSepTotalEUR)
                setInvMonthTotalEUR(varInvTotalEUR)

                //convert EUR to GBP
                varAprTotalEUR = varAprTotalEUR/rateEURGBP
                varMayTotalEUR = varMayTotalEUR/rateEURGBP
                varJunTotalEUR = varJunTotalEUR/rateEURGBP
                varJulTotalEUR = varJulTotalEUR/rateEURGBP
                varAugTotalEUR = varAugTotalEUR/rateEURGBP
                varSepTotalEUR = varSepTotalEUR/rateEURGBP

                //total GBP and convereted GBP for the chart
                varAprTotalGBP = varAprTotalGBP+varAprTotalEUR
                varMayTotalGBP = varMayTotalGBP+varMayTotalEUR
                varJunTotalGBP = varJunTotalGBP+varJunTotalEUR
                varJulTotalGBP = varJulTotalGBP+varJulTotalEUR
                varAugTotalGBP = varAugTotalGBP+varAugTotalEUR
                varSepTotalGBP = varSepTotalGBP+varSepTotalEUR

                invoicingDataForChartGBP = [varAprTotalGBP === 0 ? null : varAprTotalGBP, varMayTotalGBP === 0 ? null : varMayTotalGBP, varJunTotalGBP === 0 ? null : varJunTotalGBP, varJulTotalGBP=== 0 ? null : varJulTotalGBP, varAugTotalGBP=== 0 ? null : varAugTotalGBP, varSepTotalGBP === 0 ? null : varSepTotalGBP]
                invoicingLabelsForChart = ["April", "May", "June", "July", "August", "September"]
                setInvoicingDataGBP(invoicingDataForChartGBP)
                setInvoicingLabels(invoicingLabelsForChart)
                setInvoicingAverageData([isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP, isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP])
                setInvoicingTargetData([invMonthTargetGBP1 === 0 ? null : invMonthTargetGBP1, invMonthTargetGBP2 === 0 ? null : invMonthTargetGBP2, invMonthTargetGBP3 === 0 ? null : invMonthTargetGBP3, invMonthTargetGBP4 === 0 ? null : invMonthTargetGBP4, invMonthTargetGBP5 === 0 ? null : invMonthTargetGBP5, invMonthTargetGBP6 === 0 ? null : invMonthTargetGBP6])

                //for each record in the skills tracker
                //count how many projects have been worked on for it and put the value into the correct bucket

                var tempPerformanceTechSkillsDataFiltered = performanceTechSkillsData.filter((item) => item.userId === selectedUser.value && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(fromDate,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(toDate,"YYYY-MM-DD","-") )

                for (let index = 0; index < tempPerformanceTechSkillsDataFiltered.length; index++) {
                  const service = tempPerformanceTechSkillsDataFiltered[index];

                  var month1SvcCount = 0
                  var month2SvcCount = 0
                  var month3SvcCount = 0
                  var month4SvcCount = 0
                  var month5SvcCount = 0
                  var month6SvcCount = 0

                  invoicesFiltered.forEach(invoice => {

                    if (invoice.department===service.department && invoice.workStage===service.workStage && invoice.serviceDescription===service.service) {
                      
                      var date = new Date(invoice.invoiceDate)
                      var dateMonth = date.getMonth() + 1
                      
                      if (dateMonth===4) {
                        month1SvcCount++
                      }else if (dateMonth===5) {
                        month2SvcCount++
                      }else if (dateMonth===6) {
                        month3SvcCount++
                      }else if (dateMonth===7) {
                        month4SvcCount++
                      }else if (dateMonth===8) {
                        month5SvcCount++
                      }else if (dateMonth===9) {
                        month6SvcCount++
                      }
                    }
                  });

                  tempPerformanceTechSkillsDataFiltered[index].month1SvcCount = month1SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month2SvcCount = month2SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month3SvcCount = month3SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month4SvcCount = month4SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month5SvcCount = month5SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month6SvcCount = month6SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].monthAvgSvcCount = (month1SvcCount+month2SvcCount+month3SvcCount+month4SvcCount+month5SvcCount+month6SvcCount)/6;

                };

                setPerformanceTechSkillsDataFiltered(tempPerformanceTechSkillsDataFiltered)
                setRefreshTechSkill(false)

            }else{

                setInvMonthTotalGBP1(varOctTotalGBP)
                setInvMonthTotalGBP2(varNovTotalGBP)
                setInvMonthTotalGBP3(varDecTotalGBP)
                setInvMonthTotalGBP4(varJanTotalGBP)
                setInvMonthTotalGBP5(varFebTotalGBP)
                setInvMonthTotalGBP6(varMarTotalGBP)
                setInvMonthTotalGBP(varInvTotalGBP)

                if (varOctTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varNovTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varDecTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varJanTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varFebTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                if (varMarTotalGBP > 0) {monthsToDivideByForAverageGBP ++}
                
                setInvMonthAverageGBP(varInvTotalGBP / monthsToDivideByForAverageGBP)

                setInvMonthTotalEUR1(varOctTotalEUR)
                setInvMonthTotalEUR2(varNovTotalEUR)
                setInvMonthTotalEUR3(varDecTotalEUR)
                setInvMonthTotalEUR4(varJanTotalEUR)
                setInvMonthTotalEUR5(varFebTotalEUR)
                setInvMonthTotalEUR6(varMarTotalEUR)
                setInvMonthTotalEUR(varInvTotalEUR)

                //convert EUR to GBP
                varOctTotalEUR = varOctTotalEUR/rateEURGBP
                varNovTotalEUR = varNovTotalEUR/rateEURGBP
                varDecTotalEUR = varDecTotalEUR/rateEURGBP
                varJanTotalEUR = varJanTotalEUR/rateEURGBP
                varFebTotalEUR = varFebTotalEUR/rateEURGBP
                varMarTotalEUR = varMarTotalEUR/rateEURGBP

                //total GBP and convereted GBP for the chart
                varOctTotalGBP = varOctTotalGBP+varOctTotalEUR
                varNovTotalGBP = varNovTotalGBP+varNovTotalEUR
                varDecTotalGBP = varDecTotalGBP+varDecTotalEUR
                varJanTotalGBP = varJanTotalGBP+varJanTotalEUR
                varFebTotalGBP = varFebTotalGBP+varFebTotalEUR
                varMarTotalGBP = varMarTotalGBP+varMarTotalEUR

                invoicingDataForChartGBP = [varOctTotalGBP === 0 ? null : varOctTotalGBP, varNovTotalGBP === 0 ? null : varNovTotalGBP, varDecTotalGBP === 0 ? null : varDecTotalGBP, varJanTotalGBP=== 0 ? null : varJanTotalGBP, varFebTotalGBP=== 0 ? null : varFebTotalGBP, varMarTotalGBP === 0 ? null : varMarTotalGBP]
                invoicingLabelsForChart = ["October", "November", "December", "January", "February", "March"]
                setInvoicingDataGBP(invoicingDataForChartGBP)
                setInvoicingLabels(invoicingLabelsForChart)
                setInvoicingAverageData([isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP, isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP,isNaN(varInvTotalGBP/monthsToDivideByForAverageGBP) ? 0 : varInvTotalGBP/monthsToDivideByForAverageGBP])
                setInvoicingTargetData([invMonthTargetGBP1 === 0 ? null : invMonthTargetGBP1, invMonthTargetGBP2 === 0 ? null : invMonthTargetGBP2, invMonthTargetGBP3 === 0 ? null : invMonthTargetGBP3, invMonthTargetGBP4 === 0 ? null : invMonthTargetGBP4, invMonthTargetGBP5 === 0 ? null : invMonthTargetGBP5, invMonthTargetGBP6 === 0 ? null : invMonthTargetGBP6])

                //for each record in the skills tracker
                //count how many projects have been worked on for it and put the value into the correct bucket
                var tempPerformanceTechSkillsDataFiltered = performanceTechSkillsData.filter((item) => item.userId === selectedUser.value && stringToDate(item.startDate,"YYYY-MM-DD","-") >= stringToDate(fromDate,"YYYY-MM-DD","-") && stringToDate(item.startDate,"YYYY-MM-DD","-") <= stringToDate(toDate,"YYYY-MM-DD","-") )
                for (let index = 0; index < tempPerformanceTechSkillsDataFiltered.length; index++) {
                  const service = tempPerformanceTechSkillsDataFiltered[index];

                  var month1SvcCount = 0
                  var month2SvcCount = 0
                  var month3SvcCount = 0
                  var month4SvcCount = 0
                  var month5SvcCount = 0
                  var month6SvcCount = 0

                  invoicesFiltered.forEach(invoice => {

                    if (invoice.department===service.department && invoice.workStage===service.workStage && invoice.serviceDescription===service.service) {
                      
                      var date = new Date(invoice.invoiceDate)
                      var dateMonth = date.getMonth() + 1
                      
                      if (dateMonth===10) {
                        month1SvcCount++
                      }else if (dateMonth===11) {
                        month2SvcCount++
                      }else if (dateMonth===12) {
                        month3SvcCount++
                      }else if (dateMonth===1) {
                        month4SvcCount++
                      }else if (dateMonth===2) {
                        month5SvcCount++
                      }else if (dateMonth===3) {
                        month6SvcCount++
                      }
                    }
                  });

                  tempPerformanceTechSkillsDataFiltered[index].month1SvcCount = month1SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month2SvcCount = month2SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month3SvcCount = month3SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month4SvcCount = month4SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month5SvcCount = month5SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].month6SvcCount = month6SvcCount;
                  tempPerformanceTechSkillsDataFiltered[index].monthAvgSvcCount = (month1SvcCount+month2SvcCount+month3SvcCount+month4SvcCount+month5SvcCount+month6SvcCount)/6;

                };

                setPerformanceTechSkillsDataFiltered(tempPerformanceTechSkillsDataFiltered)
                setRefreshTechSkill(false)

            }

            // setInvoicesLoading(false)

        }else{

            setInvMonthTotalGBP1(0)
            setInvMonthTotalGBP2(0)
            setInvMonthTotalGBP3(0)
            setInvMonthTotalGBP4(0)
            setInvMonthTotalGBP5(0)
            setInvMonthTotalGBP6(0)
            setInvMonthTotalGBP(0)

            setInvMonthAverageGBP(0)

            setInvMonthTotalEUR1(0)
            setInvMonthTotalEUR2(0)
            setInvMonthTotalEUR3(0)
            setInvMonthTotalEUR4(0)
            setInvMonthTotalEUR5(0)
            setInvMonthTotalEUR6(0)
            setInvMonthTotalEUR(0)

            setInvoicingDataGBP([])
            setInvoicingAverageData([])
            setInvoicingTargetData([])

        }

    }, [invoicesFiltered, rateEURGBP, refreshTechSkill])

    useEffect(() => {

        //Process the timesheet data

        if (selectedUser.value!=='' && selectedUser.value !== undefined && timesheetsFiltered.length>0){

            const varDummyArray = timesheetsFiltered.sort((item1, item2) => Number(item1.timesheetDate) < Number(item2.timesheetDate) ? -1 : Number(item1.timesheetDate) > Number(item2.timesheetDate) ? 1 : 0)

            //completeness = booked hrs / weekly hrs
            //productity = project hrs / booked hours            
            
            // let varTimesheetTotalHrs=0
            let varAprTimesheetProjectHrs=0
            let varMayTimesheetProjectHrs=0
            let varJunTimesheetProjectHrs=0
            let varJulTimesheetProjectHrs=0
            let varAugTimesheetProjectHrs=0
            let varSepTimesheetProjectHrs=0
            let varOctTimesheetProjectHrs=0
            let varNovTimesheetProjectHrs=0
            let varDecTimesheetProjectHrs=0
            let varJanTimesheetProjectHrs=0
            let varFebTimesheetProjectHrs=0
            let varMarTimesheetProjectHrs=0

            let varAprTimesheetNonProjectHrs=0
            let varMayTimesheetNonProjectHrs=0
            let varJunTimesheetNonProjectHrs=0
            let varJulTimesheetNonProjectHrs=0
            let varAugTimesheetNonProjectHrs=0
            let varSepTimesheetNonProjectHrs=0
            let varOctTimesheetNonProjectHrs=0
            let varNovTimesheetNonProjectHrs=0
            let varDecTimesheetNonProjectHrs=0
            let varJanTimesheetNonProjectHrs=0
            let varFebTimesheetNonProjectHrs=0
            let varMarTimesheetNonProjectHrs=0

            varDummyArray.forEach(item => {
                
                //Total the monthly hrs and assign it to the correct month bucket
                var date = new Date(item.timesheetDate)
                var dateMonth = date.getMonth() + 1
                if (dateMonth===4){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varAprTimesheetNonProjectHrs = varAprTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varAprTimesheetProjectHrs = varAprTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===5){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varMayTimesheetNonProjectHrs = varMayTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varMayTimesheetProjectHrs = varMayTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===6){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varJunTimesheetNonProjectHrs = varJunTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varJunTimesheetProjectHrs = varJunTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===7){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varJulTimesheetNonProjectHrs = varJulTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varJulTimesheetProjectHrs = varJulTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===8){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varAugTimesheetNonProjectHrs = varAugTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varAugTimesheetProjectHrs = varAugTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===9){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varSepTimesheetNonProjectHrs = varSepTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varSepTimesheetProjectHrs = varSepTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===10){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varOctTimesheetNonProjectHrs = varOctTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varOctTimesheetProjectHrs = varOctTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===11){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varNovTimesheetNonProjectHrs = varNovTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varNovTimesheetProjectHrs = varNovTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===12){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varDecTimesheetNonProjectHrs = varDecTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varDecTimesheetProjectHrs = varDecTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===1){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varJanTimesheetNonProjectHrs = varJanTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varJanTimesheetProjectHrs = varJanTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===2){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varFebTimesheetNonProjectHrs = varFebTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varFebTimesheetProjectHrs = varFebTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }else if (dateMonth===3){
                    if (item.projectId==='630c6b53c35272b916fdb09a'){ //non-chargeable time
                        varMarTimesheetNonProjectHrs = varMarTimesheetNonProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }else{
                        varMarTimesheetProjectHrs = varMarTimesheetProjectHrs + (Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday))
                    }
                }

            })

            var totalAvailableHrsMonth1, timesheetTotalHrsMonth1, totalAvailableHrsMonth2, timesheetTotalHrsMonth2, totalAvailableHrsMonth3, timesheetTotalHrsMonth3, totalAvailableHrsMonth4, timesheetTotalHrsMonth4, totalAvailableHrsMonth5, timesheetTotalHrsMonth5, totalAvailableHrsMonth6, timesheetTotalHrsMonth6
            var totalProjectHrs, totalNonProjectHrs, totalAvailableHrs, totalTimesheetHrs

            if (selectedYearHalf.value==="Apr-Sep"){
                //productity = project hrs / booked hours

                totalAvailableHrsMonth1 = countMondaysInMonth(Number(selectedFinancialYear.value), 4) * hoursPerWeek
                timesheetTotalHrsMonth1 = varAprTimesheetProjectHrs+varAprTimesheetNonProjectHrs
                setTimesheetCompletenessMonth1(((timesheetTotalHrsMonth1/totalAvailableHrsMonth1)*100).toFixed(0))
                setTimesheetProductivityMonth1(((varAprTimesheetProjectHrs/(varAprTimesheetProjectHrs+varAprTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth2 = countMondaysInMonth(Number(selectedFinancialYear.value), 5) * hoursPerWeek
                timesheetTotalHrsMonth2 = varMayTimesheetProjectHrs+varMayTimesheetNonProjectHrs
                setTimesheetCompletenessMonth2(((timesheetTotalHrsMonth2/totalAvailableHrsMonth2)*100).toFixed(0))
                setTimesheetProductivityMonth2(((varMayTimesheetProjectHrs/(varMayTimesheetProjectHrs+varMayTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth3 = countMondaysInMonth(Number(selectedFinancialYear.value), 6) * hoursPerWeek
                timesheetTotalHrsMonth3 = varJunTimesheetProjectHrs+varJunTimesheetNonProjectHrs
                setTimesheetCompletenessMonth3(((timesheetTotalHrsMonth3/totalAvailableHrsMonth3)*100).toFixed(0))
                setTimesheetProductivityMonth3(((varJunTimesheetProjectHrs/(varJunTimesheetProjectHrs+varJunTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth4 = countMondaysInMonth(Number(selectedFinancialYear.value), 7) * hoursPerWeek
                timesheetTotalHrsMonth4 = varJulTimesheetProjectHrs+varJulTimesheetNonProjectHrs
                setTimesheetCompletenessMonth4(((timesheetTotalHrsMonth4/totalAvailableHrsMonth4)*100).toFixed(0))
                setTimesheetProductivityMonth4(((varJulTimesheetProjectHrs/(varJulTimesheetProjectHrs+varJulTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth5 = countMondaysInMonth(Number(selectedFinancialYear.value), 8) * hoursPerWeek
                timesheetTotalHrsMonth5 = varAugTimesheetProjectHrs+varAugTimesheetNonProjectHrs
                setTimesheetCompletenessMonth5(((timesheetTotalHrsMonth5/totalAvailableHrsMonth5)*100).toFixed(0))
                setTimesheetProductivityMonth5(((varAugTimesheetProjectHrs/(varAugTimesheetProjectHrs+varAugTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth6 = countMondaysInMonth(Number(selectedFinancialYear.value), 9) * hoursPerWeek
                timesheetTotalHrsMonth6 = varSepTimesheetProjectHrs+varSepTimesheetNonProjectHrs
                setTimesheetCompletenessMonth6(((timesheetTotalHrsMonth6/totalAvailableHrsMonth6)*100).toFixed(0))
                setTimesheetProductivityMonth6(((varSepTimesheetProjectHrs/(varSepTimesheetProjectHrs+varSepTimesheetNonProjectHrs))*100).toFixed(0))

                totalProjectHrs = varAprTimesheetProjectHrs + varMayTimesheetProjectHrs + varJunTimesheetProjectHrs + varJulTimesheetProjectHrs + varAugTimesheetProjectHrs + varSepTimesheetProjectHrs
                totalNonProjectHrs = varAprTimesheetNonProjectHrs + varMayTimesheetNonProjectHrs + varJunTimesheetNonProjectHrs + varJulTimesheetNonProjectHrs + varAugTimesheetNonProjectHrs + varSepTimesheetNonProjectHrs
                setTimesheetProductivityAverage(((totalProjectHrs/(totalProjectHrs+totalNonProjectHrs))*100).toFixed(0))   
                
                totalAvailableHrs = totalAvailableHrsMonth1 + totalAvailableHrsMonth2 + totalAvailableHrsMonth3 + totalAvailableHrsMonth4 + totalAvailableHrsMonth5 + totalAvailableHrsMonth6
                totalTimesheetHrs = timesheetTotalHrsMonth1 + timesheetTotalHrsMonth2 + timesheetTotalHrsMonth3 + timesheetTotalHrsMonth4 + timesheetTotalHrsMonth5 + timesheetTotalHrsMonth6
                setTimesheetCompletenessAverage(((totalTimesheetHrs/totalAvailableHrs)*100).toFixed(0))

            }else{

                totalAvailableHrsMonth1 = countMondaysInMonth(Number(selectedFinancialYear.value), 10) * hoursPerWeek
                timesheetTotalHrsMonth1 = varOctTimesheetProjectHrs+varOctTimesheetNonProjectHrs
                setTimesheetCompletenessMonth1(((timesheetTotalHrsMonth1/totalAvailableHrsMonth1)*100).toFixed(0))
                setTimesheetProductivityMonth1(((varOctTimesheetProjectHrs/(varOctTimesheetProjectHrs+varOctTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth2 = countMondaysInMonth(Number(selectedFinancialYear.value), 11) * hoursPerWeek
                timesheetTotalHrsMonth2 = varNovTimesheetProjectHrs+varNovTimesheetNonProjectHrs
                setTimesheetCompletenessMonth2(((timesheetTotalHrsMonth2/totalAvailableHrsMonth2)*100).toFixed(0))
                setTimesheetProductivityMonth2(((varNovTimesheetProjectHrs/(varNovTimesheetProjectHrs+varNovTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth3 = countMondaysInMonth(Number(selectedFinancialYear.value), 12) * hoursPerWeek
                timesheetTotalHrsMonth3 = varDecTimesheetProjectHrs+varDecTimesheetNonProjectHrs
                setTimesheetCompletenessMonth3(((timesheetTotalHrsMonth3/totalAvailableHrsMonth3)*100).toFixed(0))
                setTimesheetProductivityMonth3(((varDecTimesheetProjectHrs/(varDecTimesheetProjectHrs+varDecTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth4 = countMondaysInMonth(Number(selectedFinancialYear.value)+1, 1) * hoursPerWeek
                timesheetTotalHrsMonth4 = varJanTimesheetProjectHrs+varJanTimesheetNonProjectHrs
                setTimesheetCompletenessMonth4(((timesheetTotalHrsMonth4/totalAvailableHrsMonth4)*100).toFixed(0))
                setTimesheetProductivityMonth4(((varJanTimesheetProjectHrs/(varJanTimesheetProjectHrs+varJanTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth5 = countMondaysInMonth(Number(selectedFinancialYear.value)+1, 2) * hoursPerWeek
                timesheetTotalHrsMonth5 = varFebTimesheetProjectHrs+varFebTimesheetNonProjectHrs
                setTimesheetCompletenessMonth5(((timesheetTotalHrsMonth5/totalAvailableHrsMonth5)*100).toFixed(0))
                setTimesheetProductivityMonth5(((varFebTimesheetProjectHrs/(varFebTimesheetProjectHrs+varFebTimesheetNonProjectHrs))*100).toFixed(0))

                totalAvailableHrsMonth6 = countMondaysInMonth(Number(selectedFinancialYear.value)+1, 3) * hoursPerWeek
                timesheetTotalHrsMonth6 = varMarTimesheetProjectHrs+varMarTimesheetNonProjectHrs
                setTimesheetCompletenessMonth6(((timesheetTotalHrsMonth6/totalAvailableHrsMonth6)*100).toFixed(0))
                setTimesheetProductivityMonth6(((varMarTimesheetProjectHrs/(varMarTimesheetProjectHrs+varMarTimesheetNonProjectHrs))*100).toFixed(0))
                
                totalProjectHrs = varOctTimesheetProjectHrs + varNovTimesheetProjectHrs + varDecTimesheetProjectHrs + varJanTimesheetProjectHrs + varFebTimesheetProjectHrs + varMarTimesheetProjectHrs
                totalNonProjectHrs = varOctTimesheetNonProjectHrs + varNovTimesheetNonProjectHrs + varDecTimesheetNonProjectHrs + varJanTimesheetNonProjectHrs + varFebTimesheetNonProjectHrs + varMarTimesheetNonProjectHrs
                setTimesheetProductivityAverage(((totalProjectHrs/(totalProjectHrs+totalNonProjectHrs))*100).toFixed(0))

                totalAvailableHrs = totalAvailableHrsMonth1 + totalAvailableHrsMonth2 + totalAvailableHrsMonth3 + totalAvailableHrsMonth4 + totalAvailableHrsMonth5 + totalAvailableHrsMonth6
                totalTimesheetHrs = timesheetTotalHrsMonth1 + timesheetTotalHrsMonth2 + timesheetTotalHrsMonth3 + timesheetTotalHrsMonth4 + timesheetTotalHrsMonth5 + timesheetTotalHrsMonth6
                setTimesheetCompletenessAverage(((totalTimesheetHrs/totalAvailableHrs)*100).toFixed(0))

            }

            var productivityDataForChart = [totalProjectHrs, totalNonProjectHrs]
            var productivityLabelsForChart = ["Project Hours", "Non-Project Hours"]
            setProductivityData(productivityDataForChart)
            setProductivityLabels(productivityLabelsForChart)

        }else{
            setTimesheetCompletenessMonth1("NA")
            setTimesheetProductivityMonth1("NA")
            setTimesheetCompletenessMonth2("NA")
            setTimesheetProductivityMonth2("NA")
            setTimesheetCompletenessMonth3("NA")
            setTimesheetProductivityMonth3("NA")
            setTimesheetCompletenessMonth4("NA")
            setTimesheetProductivityMonth4("NA")
            setTimesheetCompletenessMonth5("NA")
            setTimesheetProductivityMonth5("NA")
            setTimesheetCompletenessMonth6("NA")
            setTimesheetProductivityMonth6("NA")
            
            setTimesheetProductivityAverage("NA")
            setTimesheetCompletenessAverage("NA")
        }

    }, [timesheetsFiltered])

    useEffect(() => {
      if (department !== "All") {
        setWorkStagesList(aggregateWorkStagesArray(departmentsServicesActivities.filter((service) => service.department === department.value)).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0));
        setServicesList(aggregateServicesArray(departmentsServicesActivities.filter((service) => service.department === department.value && service.workStage === workStage.value)).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0));
      }
    }, [department, workStage, departmentsServicesActivities]);

    useEffect(() => {

        if (goalTargetInvoiceDataUpdated === true) {

            //grab the data set
            var tmpPerformanceGoalDataFiltered = performanceGoalDataFiltered.filter((item) => item.goalType === "Invoice Target")

            if (tmpPerformanceGoalDataFiltered.length>0) {

                var target1 = Number(tmpPerformanceGoalDataFiltered[0].goalMonth1)
                var target2 = Number(tmpPerformanceGoalDataFiltered[0].goalMonth2)
                var target3 = Number(tmpPerformanceGoalDataFiltered[0].goalMonth3)
                var target4 = Number(tmpPerformanceGoalDataFiltered[0].goalMonth4)
                var target5 = Number(tmpPerformanceGoalDataFiltered[0].goalMonth5)
                var target6 = Number(tmpPerformanceGoalDataFiltered[0].goalMonth6)

                setInvMonthTargetGBP1(target1)
                setInvMonthTargetGBP2(target2)
                setInvMonthTargetGBP3(target3)
                setInvMonthTargetGBP4(target4)
                setInvMonthTargetGBP5(target5)
                setInvMonthTargetGBP6(target6)

                setInvoicingTargetData([target1 === 0 ? null : target1, target2 === 0 ? null : target2, target3 === 0 ? null : target3, target4 === 0 ? null : target4, target5 === 0 ? null : target5, target6 === 0 ? null : target6])
                setGoalTargetInvoiceDataUpdated(false)

            }

        }

    }, [goalTargetInvoiceDataUpdated])

    useEffect(() => {

        if (goalDateChanged === true) {

            // window.alert("Goal Date Changed, refresh the data")

            //grab the data set
            getGoalData(selectedUser.value, fromDate, toDate)
            setGoalDateChanged(false)

        }

    }, [goalDateChanged])

    useEffect(() => {

      if (techSkillDateChanged === true) {

          //grab the data set
          getSkillData(selectedUser.value, fromDate, toDate)
          setTechSkillDateChanged(false)
          setRefreshTechSkill(true)

      }

  }, [techSkillDateChanged])

  useEffect(() => {

    if (exchRateChanged === true) {

        setRateEURGBP(performanceExchRateData.find((item) => item.id===performanceExchRateDataId).rate)
        setExchRateChanged(false)

    }

}, [exchRateChanged])  

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (!loading && !usersLoading && !invoicesLoading && !timesheetsLoading && !projectsServicesLoading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Performance Tracker" section="Performance Tracker Page" showUserMenu='true' />
                        <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-3 gap-2 sm:gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
                                        <div>
                                            <p className="text-xs">User</p>
                                            <ReactSelect
                                                className="text-xs z-20"
                                                styles={styles}
                                                value={selectedUser}
                                                onChange={setSelectedUser}
                                                isDisabled={!isLineManager}
                                                options={filteredUsers.map((item) => {
                                                    return {
                                                        label: item.firstName + ' ' + item.lastName,
                                                        value: item.id
                                                    }
                                                })} />
                                        </div>
                                        <div className="px-3 md:px-5" >
                                            <p className="text-xs">Financial Year</p>
                                            <ReactSelect
                                                className="text-xs z-20"
                                                // className ={"MyDropdown"}
                                                // classNamePrefix={"MyDropdown"}
                                                styles={styles}
                                                // onMenuOpen={onMenuOpen}
                                                value={selectedFinancialYear}
                                                onChange={setSelectedFinancialYear}
                                                options={yearOptions}
                                            />
                                        </div>
                                        <div className="px-3 md:px-5">
                                            <p className="text-xs">Half Year</p>
                                            <ReactSelect
                                                className="text-xs z-20"
                                                styles={styles}
                                                value={selectedYearHalf}
                                                onChange={setSelectedYearHalf}
                                                isDisabled={noAccess()}
                                                options = {[
                                                    { value: "Apr-Sep", label: "Apr-Sep" },
                                                    { value: "Oct-Mar", label: "Oct-Mar" }
                                                  ]} />
                                        </div>
                                        <div className="px-3 md:px-5">
                                            <p className="text-xs">&nbsp;</p>
                                            <Button color="bg-bondi-blue" text="Add Goal" onClick={() => { setShowAddGoal(!showAddGoal); resetAddGoalComponents() }} />
                                        </div>
                                        <div className="px-3 md:px-5">
                                            <p className="text-xs">&nbsp;</p>
                                            <Button color="bg-bondi-blue" text="Add Technical Skill" onClick={() => { setShowAddTechSkill(!showAddTechSkill); resetAddTechSkillComponents() }} />
                                        </div>
                                        <div className="px-3 md:px-5 grid-cols-2">
                                            <p className="text-xs" hidden>User Signed-in: {currentUserName}</p>
                                            <p className="text-xs" hidden>Selected User: {selectedUsername}</p>
                                            <p className="text-xs">Line Manager: {selectedUserLineManager}</p>
                                            <p className="text-xs">Department: {userDepartment}</p>
                                        </div>
                                    </div>
                                    {showAddGoal === true && (
                                      <div className="p-3 grid grid-cols-12 mb-5 mt-5 bg-slate-200 rounded-2xl">
                                        <div className="p-3 md:px-5 col-span-2">
                                          <p className="text-xs">Goal Type</p>
                                          <ReactSelect
                                            className="text-xs z-10 w-64"
                                            value={selectedGoalType}
                                            onChange={setSelectedGoalType}
                                            isDisabled={noAccess()}
                                            options={[
                                              {
                                                value: "General Sustainability Knowledge",
                                                label: "General Sustainability Knowledge",
                                              },
                                              { value: "Invoice Target", label: "Invoice Target" },
                                              { value: "Non-technical Team Goal", label: "Non-technical Team Goal" },
                                              { value: "Soft Skills", label: "Soft Skills" },
                                              { value: "Training", label: "Training" },
                                            ]}
                                          />
                                        </div>
                                        <div className="p-3 md:px-5 col-span-2">
                                          <p className="text-xs">Goal Detail</p>
                                          <input className="w-full text-sm p-2" type="Text" placeholder="Enter Details Here" value={goalDetailsToAdd} onChange={(e) => setGoalDetailsToAdd(e.target.value)}/>
                                        </div>
                                        <div className="p-3 md:px-5 col-span-2">
                                          <p className="text-xs">Start Date</p>
                                          <input
                                            className="w-auto text-xs p-2"
                                            type="Date"
                                            placeholder="Enter Date Here"
                                            value={goalDateToAdd}
                                            onChange={(e) => setGoalDateToAdd(e.target.value)}
                                          />
                                        </div>
                                        <div className="p-3 md:px-5 col-span-3">
                                          <p className="text-xs">Notes</p>
                                          <input
                                            className="w-full text-sm p-2"
                                            type="Text"
                                            placeholder="Enter Notes Here"
                                            value={goalNotesToAdd}
                                            onChange={(e) => setGoalNotesToAdd(e.target.value)}
                                          />
                                        </div>
                                        <div className="px-3 md:px-5 py-2 pt-8">
                                          <button className="btn-8A btn-block-8A px-3 md:px-5 py-2 pt-12" onClick={() => {setShowAddGoal(false); addGoalToDB();}}>Add</button>
                                        </div>
                                        <div className="px-3 md:px-5 py-2 pt-8">
                                          <button className="btn-8A btn-block-8A px-3 md:px-5 py-2 pt-12" onClick={() => {setShowAddGoal(false);}}>Cancel</button>
                                          </div>
                                        <br></br>
                                        <hr></hr>
                                        <br></br>
                                      </div>
                                    )}
                                    {showAddTechSkill === true && (
                                      <div className="bg-slate-200 rounded-2xl">
                                        <div className="p-3 grid grid-cols-12 mt-5">
                                          <div className="p-3 md:px-5 col-span-2 text-xs">
                                            <label className="text-xs">Department</label>
                                            <ReactSelect
                                              className="text-xs w-64"
                                              value={department}
                                              onChange={setDepartment}
                                              onFocus={setDepartment}
                                              // isDisabled={noAccess()}
                                              options={departmentsList.map((item) => {
                                                return {
                                                  label: item.department,
                                                  value: item.department,
                                                };
                                              })}
                                            />
                                          </div>
                                          {department && (
                                            <div className="p-3 md:px-5 col-span-2 text-xs">
                                              <label className="text-xs">Work Stage</label>
                                              <ReactSelect
                                                className="text-xs w-64"
                                                value={workStage}
                                                onChange={setWorkStage}
                                                onFocus={setWorkStage}
                                                // isDisabled={noAccess()}
                                                options={workStagesList.map((item) => {
                                                  return {
                                                    label: item.workStage,
                                                    value: item.workStage,
                                                  };
                                                })}
                                              />
                                            </div>
                                          )}
                                          {/* {!department && (
                                            <div className="form-control-8A">
                                              <label className="text-red-500 text-xs">
                                                Select Department
                                              </label>
                                              <div className="text-red-500 text-xs">
                                                to Add New Service
                                              </div>
                                            </div>
                                          )} */}
                                          {workStage && (
                                            <div className="p-3 md:px-5 col-span-2 text-xs">
                                              <label className="text-xs">Service</label>
                                              <ReactSelect
                                                className="text-xs w-64"
                                                value={service}
                                                onChange={setService}
                                                onFocus={setService}
                                                // isDisabled={noAccess()}
                                                options={servicesList.map((item) => {
                                                  return {
                                                    label: item.service,
                                                    value: item.service,
                                                  };
                                                })}
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="p-3 grid grid-cols-12 mb-5">
                                          <div className="p-3 md:px-5 col-span-2">
                                            <p className="text-xs">Attainment</p>
                                            <ReactSelect
                                              className="text-xs z-5 w-64"
                                              value={selectedTechSkillAttainment}
                                              onChange={setSelectedTechSkillAttainment}
                                              isDisabled={noAccess()}
                                              options={[
                                                {
                                                  value: "Level 1 - Knowledge and Understanding",
                                                  label: "Level 1 - Knowledge and Understanding",
                                                },
                                                {
                                                  value:
                                                    "Level 2 - Application of Knowledge and Understanding",
                                                  label:
                                                    "Level 2 - Application of knowledge and understanding",
                                                },
                                                {
                                                  value:
                                                    "Level 3 - Reasoned Advice and Depth of Technical Knowledge",
                                                  label:
                                                    "Level 3 - Reasoned advice and depth of technical knowledge",
                                                },
                                              ]}
                                            />
                                          </div>
                                          <div className="p-3 md:px-5 col-span-2">
                                            <p className="text-xs">Start Date</p>
                                            <input
                                              className="w-auto text-xs p-2"
                                              type="Date"
                                              placeholder="Enter Date Here"
                                              value={techSkillDateToAdd}
                                              onChange={(e) =>
                                                setTechSkillDateToAdd(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="p-3 md:px-5 col-span-3">
                                            <p className="text-xs">Goal per Service</p>
                                            <input
                                              className="w-full text-sm p-2"
                                              type="number"
                                              placeholder="Enter a number"
                                              value={techSkillGoalPerServiceToAdd}
                                              onChange={(e) =>
                                                setTechSkillGoalPerServiceToAdd(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="px-3 md:px-5 py-2 pt-8">
                                            <button
                                              className="btn-8A btn-block-8A px-3 md:px-5 py-2 pt-12"
                                              onClick={() => {
                                                setShowAddTechSkill(false);
                                                addTechSkillToDB();
                                              }}
                                            >
                                              Add
                                            </button>
                                          </div>
                                          <div className="px-3 md:px-5 py-2 pt-8">
                                            <button
                                              className="btn-8A btn-block-8A px-3 md:px-5 py-2 pt-12"
                                              onClick={() => {
                                                setShowAddTechSkill(false);
                                              }}
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                            </div>
                            <div className="pb-1">
                                <h2 className='pt-10 px-3 pb-2 mx-auto header-8A'>Performance Overview</h2>
                                <div className='w-2/3 pr-3'>
                                    <p className='text-xs text-right'>EUR/GBP Exch Rate: <input className="w-full text-xs p-1 w-12 inline-block" readOnly disabled={isLineManager ? false : true} placeholder="EUR/GBP" value={rateEURGBP} onClick={() => {setShowModal(true); setValueToSendToModal(rateEURGBP); setFieldToSendToModal("rateEURGBP"); setObjectIdToSendToModal(performanceExchRateDataId); setCollectionToSendToModal("performance_tracker_exchange_rates"); setArrayToSendToModal(performanceExchRateData) }} /></p>
                                </div>                                
                                <div className="grid lg:grid-cols-3 grid-cols-1 w-2/3">
                                    <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={productivityChart} options={pieOptions} />
                                    </div>
                                    <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Line data={invoicingChart} options={invoicingChartOptions} />
                                    </div>
                                </div>
                            <h2 className='pt-20 px-3 pb-2 mx-auto header-8A' >Main Goals - 6 Months</h2>
                            
                            <table className="w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">Goal Type</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}>Goal Detail</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}>Notes</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}>Summary</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading1}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading2}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading3}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading4}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading5}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading6}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">Status</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">Created Date</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {performanceGoalDataFiltered.map((item) => (
                                        <tr key={item.id} style={{"backgroundColor": `${item.goalStatus === "Complete" ? '#f2fff2' : item.goalStatus === "Live" ? '#fffef2' : '#fff7f7'}`}}>                                            
                                            {item.locked === "YES" ? <td className="px-4 md:px-2.5 py-2 text-xs">{item.goalType}</td> : <td onClick={() => { setShowModal(true); setValueToSendToModal(item.goalType); setFieldToSendToModal("goalType"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalType}</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"detail" + item.id} className="px-4 md:px-2.5 py-2 text-xs whitespace-pre-line">{item.goalDetail.length > 150 ? item.goalDetail.substring(0,150) + "..." : item.goalDetail }</td> : <td data-tip data-for={"detail" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalDetail); setFieldToSendToModal("goalDetail"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer whitespace-pre-line">{item.goalDetail.length > 150 ? item.goalDetail.substring(0,150) + "..." : item.goalDetail }</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"notes" + item.id} className="px-4 md:px-2.5 py-2 text-xs whitespace-pre-line">{item.goalNotes.length > 150 ? item.goalNotes.substring(0,150) + "..." : item.goalNotes }</td> : <td data-tip data-for={"notes" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalNotes); setFieldToSendToModal("goalNotes"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer whitespace-pre-line">{item.goalNotes.length > 150 ? item.goalNotes.substring(0,150) + "..." : item.goalNotes }</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"summary" + item.id} className="px-4 md:px-2.5 py-2 text-xs whitespace-pre-line">{item.goalSummary.length > 150 ? item.goalSummary.substring(0,150) + "..." : item.goalSummary }</td> : <td data-tip data-for={"summary" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalSummary); setFieldToSendToModal("goalSummary"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer whitespace-pre-line">{item.goalSummary.length > 150 ? item.goalSummary.substring(0,150) + "..." : item.goalSummary }</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"goalMonth1" + item.id} className="px-4 md:px-2.5 py-2 text-xs">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth1) : item.goalMonth1.length > 150 ? item.goalMonth1.substring(0,150) + "..." : item.goalMonth1}</td> : <td data-tip data-for={"goalMonth1" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalMonth1); setFieldToSendToModal("goalMonth1"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth1) : item.goalMonth1.length > 150 ? item.goalMonth1.substring(0,150) + "..." : item.goalMonth1}</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"goalMonth2" + item.id} className="px-4 md:px-2.5 py-2 text-xs">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth2) : item.goalMonth2.length > 150 ? item.goalMonth2.substring(0,150) + "..." : item.goalMonth2}</td> : <td data-tip data-for={"goalMonth2" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalMonth2); setFieldToSendToModal("goalMonth2"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth2) : item.goalMonth2.length > 150 ? item.goalMonth2.substring(0,150) + "..." : item.goalMonth2}</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"goalMonth3" + item.id} className="px-4 md:px-2.5 py-2 text-xs">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth3) : item.goalMonth3.length > 150 ? item.goalMonth3.substring(0,150) + "..." : item.goalMonth3}</td> : <td data-tip data-for={"goalMonth3" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalMonth3); setFieldToSendToModal("goalMonth3"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth3) : item.goalMonth3.length > 150 ? item.goalMonth3.substring(0,150) + "..." : item.goalMonth3}</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"goalMonth4" + item.id} className="px-4 md:px-2.5 py-2 text-xs">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth4) : item.goalMonth4.length > 150 ? item.goalMonth4.substring(0,150) + "..." : item.goalMonth4}</td> : <td data-tip data-for={"goalMonth4" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalMonth4); setFieldToSendToModal("goalMonth4"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth4) : item.goalMonth4.length > 150 ? item.goalMonth4.substring(0,150) + "..." : item.goalMonth4}</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"goalMonth5" + item.id} className="px-4 md:px-2.5 py-2 text-xs">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth5) : item.goalMonth5.length > 150 ? item.goalMonth5.substring(0,150) + "..." : item.goalMonth5}</td> : <td data-tip data-for={"goalMonth5" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalMonth5); setFieldToSendToModal("goalMonth5"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth5) : item.goalMonth5.length > 150 ? item.goalMonth5.substring(0,150) + "..." : item.goalMonth5}</td>}
                                            {item.locked === "YES" ? <td data-tip data-for={"goalMonth6" + item.id} className="px-4 md:px-2.5 py-2 text-xs">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth6) : item.goalMonth6.length > 150 ? item.goalMonth6.substring(0,150) + "..." : item.goalMonth6}</td> : <td data-tip data-for={"goalMonth6" + item.id} onClick={() => { setShowModal(true); setValueToSendToModal(item.goalMonth6); setFieldToSendToModal("goalMonth6"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalType==="Invoice Target" ? "£" + internationalNumberFormat.format(item.goalMonth6) : item.goalMonth6.length > 150 ? item.goalMonth6.substring(0,150) + "..." : item.goalMonth6}</td>}
                                            {item.locked === "YES" ? <td className="px-4 md:px-2.5 py-2 text-xs">{item.goalStatus}</td> : <td onClick={() => { setShowModal(true); setValueToSendToModal(item.goalStatus); setFieldToSendToModal("goalStatus"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalStatus}</td>}
                                            {item.locked === "YES" ? <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.startDate}</td> : <td onClick={() => { setShowModal(true); setValueToSendToModal(item.startDate); setFieldToSendToModal("startDate"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_goals"); setArrayToSendToModal(performanceGoalDataFiltered) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer whitespace-nowrap">{item.startDate}</td>}
                                            {item.locked === "YES" ? <td></td> : <td><button onClick={() => { deleteGoal(item.id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button></td>}
                                            {isTheUserCurrentlySelected ? <td></td> : item.locked === "YES" ? <td><button onDoubleClick={() => { lockGoal(item.id, "NO") }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16"><path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/></svg></button></td> : <td><button onDoubleClick={() => { lockGoal(item.id, "YES") }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-unlock" viewBox="0 0 16 16"> <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2M3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"/> </svg></button></td>}
                                            {/* <ReactTooltip id={"detail" + item.id} place="top" effect="solid" type="info"><div style={{ maxWidth: 800 }}>Goal Detail: {item.goalDetail}<br/><br/>Notes: {item.goalNotes}<br/><br/>Summary: {item.goalSummary}</div></ReactTooltip> */}
                                            <ReactTooltip id={"detail" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Goal Detail: {item.goalDetail}</div></ReactTooltip>
                                            <ReactTooltip id={"notes" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Notes: {item.goalNotes}</div></ReactTooltip>
                                            <ReactTooltip id={"summary" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Summary: {item.goalSummary}</div></ReactTooltip>
                                            <ReactTooltip id={"goalMonth1" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Month 1: {item.goalMonth1}</div></ReactTooltip>
                                            <ReactTooltip id={"goalMonth2" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Month 2: {item.goalMonth2}</div></ReactTooltip>
                                            <ReactTooltip id={"goalMonth3" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Month 3: {item.goalMonth3}</div></ReactTooltip>
                                            <ReactTooltip id={"goalMonth4" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Month 4: {item.goalMonth4}</div></ReactTooltip>
                                            <ReactTooltip id={"goalMonth5" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Month 5: {item.goalMonth5}</div></ReactTooltip>
                                            <ReactTooltip id={"goalMonth6" + item.id} place="top" effect="solid"><div className="whitespace-pre-line" style={{ maxWidth: 800 }}>Month 6: {item.goalMonth6}</div></ReactTooltip>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* <ReactTooltip id="my-tooltip" /> */}
                            
                            {/* {isGoalsLoading && <LoadingSpinner />} */}
                            <h2 className='pt-20 px-3 pb-2 mx-auto header-8A'>Technical Skills</h2>
                            <table className="w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '150px' }}>Department</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '150px' }}>Work Stage</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '150px' }}>Service</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">Attainment</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">Goal per service</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading1}</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading2}</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading3}</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading4}</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading5}</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">{monthHeading6}</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">Average</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">Created Date</th>
                                        <th scope="col" className="px-4 md:px-2.5  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '0px' }}></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {performanceTechSkillsDataFiltered.map((item) => (
                                    <tr key={item.id}>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.department}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.workStage}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs">{item.service}</td>
                                        {isTheUserCurrentlySelected ? <td className="px-4 md:px-2.5 py-2 text-xs">{item.attainment}</td> : <td onClick={() => { setShowModal(true); setValueToSendToModal(item.attainment); setFieldToSendToModal("techSkillAttainment"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_tech_skills"); setArrayToSendToModal(performanceTechSkillsData) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.attainment}</td>}
                                        {isTheUserCurrentlySelected ? <td className="px-4 md:px-2.5 py-2 text-xs">{item.goalPerService}</td> : <td onClick={() => { setShowModal(true); setValueToSendToModal(item.goalPerService); setFieldToSendToModal("techSkillGoalPerService"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_tech_skills"); setArrayToSendToModal(performanceTechSkillsData) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer">{item.goalPerService}</td>}
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.month1SvcCount === undefined ? 0 : item.month1SvcCount}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.month2SvcCount === undefined ? 0 : item.month2SvcCount}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.month3SvcCount === undefined ? 0 : item.month3SvcCount}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.month4SvcCount === undefined ? 0 : item.month4SvcCount}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.month5SvcCount === undefined ? 0 : item.month5SvcCount}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.month6SvcCount === undefined ? 0 : item.month6SvcCount}</td>
                                        <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{(item.monthAvgSvcCount === NaN || item.monthAvgSvcCount === undefined) ? 0 : Number(item.monthAvgSvcCount).toFixed(2)}</td>
                                        {isTheUserCurrentlySelected ? <td className="px-4 md:px-2.5 py-2 text-xs whitespace-nowrap">{item.startDate}</td> : <td onClick={() => { setShowModal(true); setValueToSendToModal(item.startDate); setFieldToSendToModal("techSkillStartDate"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("performance_tracker_tech_skills"); setArrayToSendToModal(performanceTechSkillsData) }} className="px-4 md:px-2.5 py-2 text-xs hover:cursor-pointer whitespace-nowrap">{item.startDate}</td>}
                                        {isTheUserCurrentlySelected ? <td></td> : <td><button onClick={() => { deleteTechSkill(item.id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button></td>}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <h2 className='pt-20 px-3 pb-2 mx-auto header-8A'>Monthly Tracker</h2>
                            <table className="w-full divide-y divide-gray-200"> 
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '600px' }}>Monthly Tracker</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '240px' }}>Target</th>                                        
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '90px' }}>{monthHeading1}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '90px' }}>{monthHeading2}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '90px' }}>{monthHeading3}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '90px' }}>{monthHeading4}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '90px' }}>{monthHeading5}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '90px' }}>{monthHeading6}</th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '90px' }}>Average</th>
                                        {/* <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '50px' }}></th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '50px' }}></th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '50px' }}></th>
                                        <th scope="col" className="px-4 md:px-2.5 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '50px' }}></th> */}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    <tr >
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">​Invoices Target</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">£{internationalNumberFormat.format((invMonthTargetGBP1).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">£{internationalNumberFormat.format((invMonthTargetGBP2).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">£{internationalNumberFormat.format((invMonthTargetGBP3).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">£{internationalNumberFormat.format((invMonthTargetGBP4).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">£{internationalNumberFormat.format((invMonthTargetGBP5).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">£{internationalNumberFormat.format((invMonthTargetGBP6).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                    </tr>
                                    <tr >
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">​Invoices £</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${invMonthTotalGBP1 >= invMonthTargetGBP1 ? '#f2fff2' : '#fffef2'}`}}>£{internationalNumberFormat.format((invMonthTotalGBP1).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${invMonthTotalGBP2 >= invMonthTargetGBP2 ? '#f2fff2' : '#fffef2'}`}}>£{internationalNumberFormat.format((invMonthTotalGBP2).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${invMonthTotalGBP3 >= invMonthTargetGBP3 ? '#f2fff2' : '#fffef2'}`}}>£{internationalNumberFormat.format((invMonthTotalGBP3).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${invMonthTotalGBP4 >= invMonthTargetGBP4 ? '#f2fff2' : '#fffef2'}`}}>£{internationalNumberFormat.format((invMonthTotalGBP4).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${invMonthTotalGBP5 >= invMonthTargetGBP5 ? '#f2fff2' : '#fffef2'}`}}>£{internationalNumberFormat.format((invMonthTotalGBP5).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${invMonthTotalGBP6 >= invMonthTargetGBP6 ? '#f2fff2' : '#fffef2'}`}}>£{internationalNumberFormat.format((invMonthTotalGBP6).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${invMonthAverageGBP >= 10000 ? '#f2fff2' : '#fffef2'}`}}>£{internationalNumberFormat.format((invMonthAverageGBP).toFixed(2))}</td>
                                    </tr>
                                    <tr >
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">​Invoices €</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">€{internationalNumberFormat.format((invMonthTotalEUR1).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">€{internationalNumberFormat.format((invMonthTotalEUR2).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">€{internationalNumberFormat.format((invMonthTotalEUR3).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">€{internationalNumberFormat.format((invMonthTotalEUR4).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">€{internationalNumberFormat.format((invMonthTotalEUR5).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">€{internationalNumberFormat.format((invMonthTotalEUR6).toFixed(2))}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">€{internationalNumberFormat.format((invMonthTotalEUR/6).toFixed(2))}</td>
                                    </tr>
                                    <tr >
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">Timesheets (100% completeness)</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">100%</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetCompletenessMonth1 >= 80 ? '#f2fff2' : timesheetCompletenessMonth1 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetCompletenessMonth1) ? 'NA' : timesheetCompletenessMonth1 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetCompletenessMonth2 >= 80 ? '#f2fff2' : timesheetCompletenessMonth2 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetCompletenessMonth2) ? 'NA' : timesheetCompletenessMonth2 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetCompletenessMonth3 >= 80 ? '#f2fff2' : timesheetCompletenessMonth3 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetCompletenessMonth3) ? 'NA' : timesheetCompletenessMonth3 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetCompletenessMonth4 >= 80 ? '#f2fff2' : timesheetCompletenessMonth4 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetCompletenessMonth4) ? 'NA' : timesheetCompletenessMonth4 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetCompletenessMonth5 >= 80 ? '#f2fff2' : timesheetCompletenessMonth5 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetCompletenessMonth5) ? 'NA' : timesheetCompletenessMonth5 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetCompletenessMonth6 >= 80 ? '#f2fff2' : timesheetCompletenessMonth6 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetCompletenessMonth6) ? 'NA' : timesheetCompletenessMonth6 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetCompletenessAverage >= 80 ? '#f2fff2' : timesheetCompletenessAverage >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetCompletenessAverage) ? 'NA' : timesheetCompletenessAverage + '%'}</td>
                                    </tr>
                                    <tr >
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">Productivity</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">80%</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetProductivityMonth1 >= 80 ? '#f2fff2' : timesheetProductivityMonth1 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetProductivityMonth1) ? 'NA' : timesheetProductivityMonth1 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetProductivityMonth2 >= 80 ? '#f2fff2' : timesheetProductivityMonth2 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetProductivityMonth2) ? 'NA' : timesheetProductivityMonth2 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetProductivityMonth3 >= 80 ? '#f2fff2' : timesheetProductivityMonth3 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetProductivityMonth3) ? 'NA' : timesheetProductivityMonth3 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetProductivityMonth4 >= 80 ? '#f2fff2' : timesheetProductivityMonth4 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetProductivityMonth4) ? 'NA' : timesheetProductivityMonth4 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetProductivityMonth5 >= 80 ? '#f2fff2' : timesheetProductivityMonth5 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetProductivityMonth5) ? 'NA' : timesheetProductivityMonth5 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetProductivityMonth6 >= 80 ? '#f2fff2' : timesheetProductivityMonth6 >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetProductivityMonth6) ? 'NA' : timesheetProductivityMonth6 + '%'}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${timesheetProductivityAverage >= 80 ? '#f2fff2' : timesheetProductivityAverage >= 50 ? '#fffef2' : '#fff7f7'}`}}>{isNaN(timesheetProductivityAverage) ? 'NA' : timesheetProductivityAverage + '%'}</td>
                                    </tr>
                                    <tr >
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">Project Recovery Rate</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${svcCompleteEstVsActual1 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(svcCompleteEstVsActual1) || svcCompleteEstVsActual1 === Infinity ? "NA" : Number(svcCompleteEstVsActual1).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${svcCompleteEstVsActual2 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(svcCompleteEstVsActual2) || svcCompleteEstVsActual2 === Infinity ? "NA" : Number(svcCompleteEstVsActual2).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${svcCompleteEstVsActual3 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(svcCompleteEstVsActual3) || svcCompleteEstVsActual3 === Infinity ? "NA" : Number(svcCompleteEstVsActual3).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${svcCompleteEstVsActual4 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(svcCompleteEstVsActual4) || svcCompleteEstVsActual4 === Infinity ? "NA" : Number(svcCompleteEstVsActual4).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${svcCompleteEstVsActual5 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(svcCompleteEstVsActual5) || svcCompleteEstVsActual5 === Infinity ? "NA" : Number(svcCompleteEstVsActual5).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${svcCompleteEstVsActual6 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(svcCompleteEstVsActual6) || svcCompleteEstVsActual6 === Infinity ? "NA" : Number(svcCompleteEstVsActual6).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                    </tr>
                                    <tr >
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap">Monthly Project Recovery Rate</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${monthRecovery1 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(monthRecovery1) || monthRecovery1 === Infinity ? "NA" : Number(monthRecovery1).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${monthRecovery2 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(monthRecovery2) || monthRecovery2 === Infinity ? "NA" : Number(monthRecovery2).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${monthRecovery3 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(monthRecovery3) || monthRecovery3 === Infinity ? "NA" : Number(monthRecovery3).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${monthRecovery4 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(monthRecovery4) || monthRecovery4 === Infinity ? "NA" : Number(monthRecovery4).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${monthRecovery5 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(monthRecovery5) || monthRecovery5 === Infinity ? "NA" : Number(monthRecovery5).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap" style={{"backgroundColor": `${monthRecovery6 >= 1 ? '#f2fff2' : '#fffef2'}`}}>{isNaN(monthRecovery6) || monthRecovery6 === Infinity ? "NA" : Number(monthRecovery6).toFixed(2)}</td>
                                        <td className="px-4 md:px-2.5 py-3 text-xs whitespace-nowrap"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={arrayToSendToModal} updateInvData={() => setGoalTargetInvoiceDataUpdated(true)} dateChanged={() => setGoalDateChanged(true)} skillDateChanged={() => setTechSkillDateChanged(true)} exchRateChanged={() => setExchRateChanged(true)} onClose={() => setShowModal(false)} show={showModal} title="Update Data" />
                        </div>

                    </div>

                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Performance Tracker" section="Performance Tracker Page" showUserMenu='true' /> 
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default PerformanceTracker
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'


const Options = (props) => {

    const navigate = useNavigate()

    const editObject = (url) => {
        navigate(url);
    }

    const editObjectWithoutNavigatingToURL = (id) => {
        props.editFunction(id)
    }

    const moveObjectWithoutNavigatingToURL = (id) => {
        props.moveFunction(id)
    }

    const deleteObject = (id) => {
        props.deleteFunction(id)
    }

    const cloneObject = (id) => {
        props.cloneFunction(id)
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex justify-center border-2 border-slate-100 w-full rounded-2xl shadow-md shadow-slate-300 px-3 py-1 bg-white text-sm font-medium text-slate-400 hover:bg-gray-50 focus:outline-none ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                    </svg>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={props.position === "left" ? "py-1 origin-top-right backdrop-blur bg-white/60 absolute left-16 bottom-1 mt-2 w-24 rounded-xl shadow-md shadow-slate-300 ring-1 ring-slate-500 ring-opacity-5 divide-y divide-gray-200 focus:outline-none z-30" : "py-1 origin-top-right backdrop-blur bg-white/60 absolute right-16 bottom-1 mt-2 w-24 rounded-xl shadow-md shadow-slate-300 ring-1 ring-slate-500 ring-opacity-5 divide-y divide-gray-200 focus:outline-none z-30"}>
                    <div className="">
                    {props.edit &&
                        <Menu.Item>
                            <p onClick={() => editObject(props.edit)}
                                className='hover:cursor-pointer hover:underline text-gray-700 block px-4 pt-1 text-sm'>
                                Edit
                            </p>
                        </Menu.Item>
                    }
                    {props.editFunction &&
                        <Menu.Item>
                            <p onClick={() => editObjectWithoutNavigatingToURL(props.editObject)}
                                className='hover:cursor-pointer hover:underline text-gray-700 block px-4 pt-1 text-sm'>
                                Edit
                            </p>
                        </Menu.Item>
                    }
                    {props.moveFunction &&
                        <Menu.Item>
                            <p onClick={() => moveObjectWithoutNavigatingToURL(props.moveObject)}
                                className='hover:cursor-pointer hover:underline text-gray-700 block px-4 pt-1 text-sm'>
                                Move
                            </p>
                        </Menu.Item>
                    }
                    {props.cloneObject &&
                        <Menu.Item>
                            <p onClick={() => cloneObject(props.cloneObject)}
                            className=' hover:cursor-pointer hover:underline text-gray-700 block px-4 py-1 text-sm'>
                                Clone
                            </p>
                        </Menu.Item>
                    }
                    </div>
                    
                    {props.deleteObject &&
                    <div className="">
                        <Menu.Item>
                            <p onClick={() => deleteObject(props.deleteObject)}
                                className='hover:cursor-pointer hover:underline text-red-600 block px-4 py-1 text-sm'>
                                Delete
                            </p>
                        </Menu.Item>
                    </div>
                    }
                </Menu.Items>
            </Transition>
        </Menu>)
}
Options.defaultProps ={
    //edit: '/addOrUpdateClientProject/'
    position: "right"
}
export default Options
import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Sidebar from '../UI/Sidebar'
import { ExportToCsv } from 'export-to-csv'
import { useNavigate } from 'react-router-dom'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import { MultiSelect } from "react-multi-select-component";


const ProjectsTrackerServices = () => {

    const [services, setServices] = useState([])
    const [servicesFiltered, setServicesFiltered] = useState([])
    const [clients, setClients] = useState([])

    const [invoices, setInvoices] = useState([])

    const [timesheets, setTimesheets] = useState([])

    const [servicesInvoiced, setServicesInvoiced] = useState([])

    const [users, setUsers] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)
    const [invoicesLoading, setInvoicesLoading] = useState(true)
    const [servicesInvoicedLoading, setServicesInvoicedLoading] = useState(true)
    const [timesheetsLoading, setTimesheetsLoading] = useState(true)

    const [showWIP, setShowWIP] = useState(false)

    const [selectedServiceManager, setSelectedServiceManager] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [selectedProjectManager, setSelectedProjectManager] = useState([])
    const [selectedProjectName, setSelectedProjectName] = useState([])
    const [selectedDealName, setSelectedDealName] = useState([])
    const [selectedProjectNumber, setSelectedProjectNumber] = useState([])
    const [selectedServiceStatus, setSelectedServiceStatus] = useState([])
    const [selectedProjectStatus, setSelectedProjectStatus] = useState([])
    const [selectedBillingPlanStatus, setSelectedBillingPlanStatus] = useState([])
    const [selectedWorkStage, setSelectedWorkStage] = useState([])
    const [selectedServiceCreatedMonth, setSelectedServiceCreatedMonth] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState([])
    const [selectedCustomerPo, setSelectedCustomerPo] = useState([])
    const [selectedServiceInvoiceClient, setSelectedServiceInvoiceClient] = useState([])
    const [selectedProjectClient, setSelectedProjectClient] = useState([])
    const [selectedProjectBillingPlanAndFeesChecked, setSelectedProjectBillingPlanAndFeesChecked] = useState([])
    const [selectedProjectInvoicingChecked, setSelectedProjectInvoicingChecked] = useState([])

    const [sortedField, setSortedField] = useState('projectNumber');
    const [sortedOrder, setSortedOrder] = useState('asc');

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')

    const currentMonth = date => {

        const dateConverted = new Date(date)

        const yyyy = dateConverted.getFullYear();
        let mm = dateConverted.getMonth() + 1; // Months start at 0!

        if (mm < 10) mm = '0' + mm;

        const formattedMonth = yyyy + '-' + mm

        return formattedMonth
    }

    // const internationalNumberFormat = new Intl.NumberFormat('en-US')
    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const navigate = useNavigate()

    const arrayToCsv = (data) => {

        var dataToExport = []
        var j
        var projectManagerFirstName, projectManagerLastName, serviceManagerFirstName, serviceManagerLastName

        for (j = 0; j < data.length; j++) {

            projectManagerFirstName = users.find(item => item.id === data[j].projectManager).firstName
            projectManagerLastName = users.find(item => item.id === data[j].projectManager).lastName
            serviceManagerFirstName = users.find(item => item.id === data[j].serviceManager).firstName
            serviceManagerLastName = users.find(item => item.id === data[j].serviceManager).lastName

            dataToExport.push({
                ...data[j],
                "Project Manager First Name" : projectManagerFirstName,
                "Project Manager Last Name" : projectManagerLastName,
                "Service Manager First Name" : serviceManagerFirstName,
                "Service Manager Last Name" : serviceManagerLastName
            })
            
        }
        var newArrayToExport = []
        var lineItem

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Services Tracker',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        for (lineItem = 0; lineItem < dataToExport.length; lineItem++) {

            newArrayToExport.push({...dataToExport[lineItem], activities: []})

        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(newArrayToExport)

    }

    const serviceIncludedInBillingPlan = (id) => {

        //checks to see if serviceId is present on an invoice and
        //checks total fee by service against total value added to billing plan by service

        var invoice
        var invoiceLine
        var totalFeeOnBillingPlan = 0

        for (invoice = 0; invoice < invoices.length; invoice++) {
            for (invoiceLine = 0; invoiceLine < invoices[invoice].invoiceLines.length; invoiceLine++) {

                if (
                    invoices[invoice].invoiceLines[invoiceLine].serviceId === id
                ) {
                    totalFeeOnBillingPlan = totalFeeOnBillingPlan + invoices[invoice].invoiceLines[invoiceLine].value
                }
            }
        }

        return totalFeeOnBillingPlan

    }

    const calculcateAndShowWIP = () => {

        if (showWIP) {

            setShowWIP(false)

        } else {

            if (window.confirm('This may take several minutes to calculate.  Would you like to proceed?')) {

                setShowWIP(true)

            }
        }

    }

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            // width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            // width: 160,
            fontSize: 13
        }),
    }

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

    }

    const next100Records = () => {

        if (lastRecord <= servicesFiltered.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }

    const clearFilters = () => {

        setSelectedServiceManager(aggregateServiceManagerArray(services).map((item) => {
            return {
                label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                value: item.serviceManager
            }
        }))


        setSelectedDealName(aggregateDealNameArray(services).map((item) => {
            return {
                label: item.dealName,
                value: item.dealName
            }
        }))

        setSelectedService(aggregateServicesArray(services).map((item) => {
            return {
                label: item.service,
                value: item.service
            }
        }))

        setSelectedProjectManager(aggregateProjectManagerArray(services).map((item) => {
            return {
                label: item.projectManager === "" ? "" : users.find(user => user.id === item.projectManager).firstName + " " + users.find(user => user.id === item.projectManager).lastName,
                value: item.projectManager
            }
        }))

        setSelectedProjectName(aggregateProjectNameArray(services).map((item) => {
            return {
                label: item.projectName,
                value: item.projectName
            }
        }))

        setSelectedProjectNumber(aggregateProjectNumberArray(services).map((item) => {
            return {
                label: item.projectNumber,
                value: item.projectNumber
            }
        }))

        setSelectedServiceStatus(aggregateServiceStatusArray(services).map((item) => {
            return {
                label: item.serviceStatus,
                value: item.serviceStatus
            }
        }))

        setSelectedProjectStatus(aggregateProjectStatusArray(services).map((item) => {
            return {
                label: item.projectStatus,
                value: item.projectStatus
            }
        }))

        setSelectedBillingPlanStatus([{label: "On Billing Plan", value: "On Billing Plan"}, {label: "Not On Billing Plan", value: "Not On Billing Plan"}, {label: "Partially On Billing Plan", value: "Partially On Billing Plan"}, {label: "Too Much On Billing Plan", value: "Too Much On Billing Plan"}])

        setSelectedWorkStage(aggregateWorkStagesArray(services).map((item) => {
            return {
                label: item.workStage,
                value: item.workStage
            }
        }))
        setSelectedServiceCreatedMonth(aggregateServiceCreatedMonthsArray(services).map((item) => {
            return {
                label: item.serviceCreatedMonth,
                value: item.serviceCreatedMonth
            }
        }))

        setSelectedDepartment(aggregateDepartmentsArray(services).map((item) => {
            return {
                label: item.department,
                value: item.department
            }
        }))

        setSelectedCustomerPo(aggregateCustomerPoArray(services).map((item) => {
            return {
                label: item.customerPo,
                value: item.customerPo
            }
        }))

        setSelectedServiceInvoiceClient(aggregateServiceInvoiceClientArray(services).map((item) => {
            return {
                label: item.serviceInvoiceClient === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClient).clientName,
                value: item.serviceInvoiceClient
            }
        }))

        setSelectedProjectClient(aggregateProjectClientArray(services).map((item) => {
            return {
                label: item.clientName,
                value: item.clientName
            }
        }))

        setSelectedProjectBillingPlanAndFeesChecked(aggregateBillingPlanAndFeesCheckedArray(services).map((item) => {
            return {
                label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                value: item.projectBillingPlanAndFeesChecked
            }
        }))

        setSelectedProjectInvoicingChecked(aggregateProjectInvoicingCheckedArray(services).map((item) => {
            return {
                label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                value: item.projectInvoicingChecked
            }
        }))
        
        setSortedField('projectNumber')
        setSortedOrder('asc')

    }

    const aggregateDealNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.dealName === val.dealName);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        dealName: val.dealName
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateProjectManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectManager === val.projectManager);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        projectManager: val.projectManager
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServiceManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceManager === val.serviceManager);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        serviceManager: val.serviceManager
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectName === val.projectName);
            if (index === -1) {
                acc.push({
                    projectName: val.projectName,
                });
            };
            return acc.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : 0)
        }, []);
    };

    const aggregateProjectNumberArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectNumber === val.projectNumber);
            if (index === -1) {
                acc.push({
                    projectNumber: val.projectNumber,
                });
            };
            return acc.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                if (val.workStage.length > 0) {
                    acc.push({
                        workStage: val.workStage
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateServiceCreatedMonthsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceCreatedMonth === val.serviceCreatedMonth);
            if (index === -1) {
                if (val.serviceCreatedMonth.length > 0) {
                    acc.push({
                        serviceCreatedMonth: val.serviceCreatedMonth
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department,
                });
            };
            return acc.sort((item1, item2) => item1.department < item2.department ? -1 : 0)
        }, []);
    };

    const aggregateCustomerPoArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.customerPo === val.customerPo);
            if (index === -1) {
                // if (val.customerPo.length > 0) {
                    acc.push({
                        customerPo: val.customerPo
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServiceStatusArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceStatus === val.serviceStatus);
            if (index === -1) {
                acc.push({
                    serviceStatus: val.serviceStatus,
                });
            };
            return acc.sort((item1, item2) => item1.serviceStatus < item2.serviceStatus ? -1 : 0)
        }, []);
    };

    const aggregateProjectStatusArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectStatus === val.projectStatus);
            if (index === -1) {
                acc.push({
                    projectStatus: val.projectStatus,
                });
            };
            return acc.sort((item1, item2) => item1.projectStatus < item2.projectStatus ? -1 : 0)
        }, []);
    };

    const aggregateServiceInvoiceClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceInvoiceClient === val.serviceInvoiceClient);
            if (index === -1) {
                acc.push({
                    serviceInvoiceClient: val.serviceInvoiceClient,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientName === val.clientName);
            if (index === -1) {
                acc.push({
                    clientName: val.clientName,
                });
            };
            return acc;
        }, []);
    };

    const aggregateBillingPlanAndFeesCheckedArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectBillingPlanAndFeesChecked === val.projectBillingPlanAndFeesChecked);
            if (index === -1) {
                acc.push({
                    projectBillingPlanAndFeesChecked: val.projectBillingPlanAndFeesChecked,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectInvoicingCheckedArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectInvoicingChecked === val.projectInvoicingChecked);
            if (index === -1) {
                acc.push({
                    projectInvoicingChecked: val.projectInvoicingChecked,
                });
            };
            return acc;
        }, []);
    };

    const viewProject = (id) => {
        window.open(`/viewClientProject/${id}`);
    }

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/services_invoiced')
            .then((res) => res.data)
            .then((res) => {
                setServicesInvoiced(res)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setServicesInvoicedLoading(false);
                // console.log("services_invoiced loaded")
            });

        axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
                // console.log("users loaded")
            });

        axios.get(process.env.REACT_APP_API_URL + '/invoices')
            .then((res) => {
                setInvoices(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setInvoicesLoading(false);
                // console.log("invoices loaded")
            });

        // setSelectedServiceStatus({ value: "Live", label: "Live" })

        axios.get(process.env.REACT_APP_API_URL + '/clients')
        .then((res) => {
            setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setClientsLoading(false);
            // console.log("clients loaded")
        });

        axios.get(process.env.REACT_APP_API_URL + '/timesheets')
        .then((res) => {
            setTimesheets(res.data)
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setTimesheetsLoading(false);
            // console.log("timesheets loaded")
        });

    }, [])

    useEffect(() => {

        if (users.length > 0 && !usersLoading && !clientsLoading && clients.length > 0) {

            // console.log("useEffect to get services report")

            // console.log("get services report")

        axios.get(process.env.REACT_APP_API_URL + '/services_report')
            .then((res) => res.data)
            .then((res) => {
                setServices(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0))
                setServicesFiltered(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0))
                setSelectedProjectManager(aggregateProjectManagerArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                    return {
                        label: item.projectManager === "" ? "" : users.find(user => user.id === item.projectManager).firstName + " " + users.find(user => user.id === item.projectManager).lastName,
                        value: item.projectManager
                    }
                }))
                setSelectedServiceManager(aggregateServiceManagerArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                        value: item.serviceManager
                    }
                }))
                setSelectedProjectName(aggregateProjectNameArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectName,
                        value: item.projectName
                    }
                }))
                setSelectedDealName(aggregateDealNameArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.dealName < item2.dealName ? -1 : 0)).map((item) => {
                    return {
                        label: item.dealName,
                        value: item.dealName
                    }
                }))
                setSelectedProjectNumber(aggregateProjectNumberArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                    return {
                        label: item.projectNumber,
                        value: item.projectNumber
                    }
                }))
                setSelectedServiceStatus(aggregateServiceStatusArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceStatus,
                        value: item.serviceStatus
                    }
                }))
                setSelectedProjectStatus(aggregateProjectStatusArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectStatus,
                        value: item.projectStatus
                    }
                }))

                setSelectedBillingPlanStatus([{label: "On Billing Plan", value: "On Billing Plan"}, {label: "Not On Billing Plan", value: "Not On Billing Plan"}, {label: "Partially On Billing Plan", value: "Partially On Billing Plan"}, {label: "Too Much On Billing Plan", value: "Too Much On Billing Plan"}])

                setSelectedWorkStage(aggregateWorkStagesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.workStage,
                        value: item.workStage
                    }
                }))

                setSelectedService(aggregateServicesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.service,
                        value: item.service
                    }
                }))

                setSelectedServiceCreatedMonth(aggregateServiceCreatedMonthsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceCreatedMonth,
                        value: item.serviceCreatedMonth
                    }
                }))

                // setSelectedServiceCreatedMonth([{label: currentMonth(Date()), value: currentMonth(Date())}])

                setSelectedDepartment(aggregateDepartmentsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.department,
                        value: item.department
                    }
                }))

                setSelectedCustomerPo(aggregateCustomerPoArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.customerPo,
                        value: item.customerPo
                    }
                }))

                setSelectedServiceInvoiceClient(aggregateServiceInvoiceClientArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceInvoiceClient === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClient).clientName,
                        value: item.serviceInvoiceClient
                    }
                }))

                setSelectedProjectClient(aggregateProjectClientArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.clientName,
                        value: item.clientName
                    }
                }))

                setSelectedProjectBillingPlanAndFeesChecked(aggregateBillingPlanAndFeesCheckedArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                        value: item.projectBillingPlanAndFeesChecked
                    }
                }))

                setSelectedProjectInvoicingChecked(aggregateProjectInvoicingCheckedArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                        value: item.projectInvoicingChecked
                    }
                }))

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
                // console.log("report loaded")
            });

        }

    }, [users, clients, usersLoading, clientsLoading])

    useEffect(() => {
        
        if (services.length > 0 && !loading) {
            // console.log("useEffect to set filtered services")
            setServicesFiltered(services)

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceManager.find((itemB) => {
                    return itemA.serviceManager === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedService.find((itemB) => {
                    return itemA.service === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectManager.find((itemB) => {
                    return itemA.projectManager === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectName.find((itemB) => {
                    return itemA.projectName === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectNumber.find((itemB) => {
                    return itemA.projectNumber === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceStatus.find((itemB) => {
                    return itemA.serviceStatus === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectStatus.find((itemB) => {
                    return itemA.projectStatus === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedBillingPlanStatus.find((itemB) => {
                    return itemA.billingPlanStatus === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedWorkStage.find((itemB) => {
                    return itemA.workStage === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceCreatedMonth.find((itemB) => {
                    return itemA.serviceCreatedMonth === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedDepartment.find((itemB) => {
                    return itemA.department === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedCustomerPo.find((itemB) => {
                    return itemA.customerPo === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceInvoiceClient.find((itemB) => {
                    return itemA.serviceInvoiceClient === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectClient.find((itemB) => {
                    return itemA.clientName === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectInvoicingChecked.find((itemB) => {
                    return itemA.projectInvoicingChecked === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectBillingPlanAndFeesChecked.find((itemB) => {
                    return itemA.projectBillingPlanAndFeesChecked === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedDealName.find((itemB) => {
                    return itemA.dealName === itemB.value;
                })
            }))

            setFirstRecord(0)
            setLastRecord(100)

        }

        // setSortedField('projectNumber')
        // setSortedOrder('asc')


    }, [loading, selectedServiceManager, selectedService, selectedProjectManager, selectedProjectName, selectedProjectNumber, services, selectedServiceStatus, selectedProjectStatus, selectedBillingPlanStatus, selectedWorkStage, selectedServiceCreatedMonth, selectedDepartment, selectedCustomerPo, selectedServiceInvoiceClient, selectedProjectClient, selectedProjectBillingPlanAndFeesChecked, selectedProjectInvoicingChecked, selectedDealName, showWIP])


    useEffect(() => {


        if (services.length > 0 && timesheets.length > 0 && invoices.length > 0 ) {

            // console.log("useEffect to calculate totals")
            var service
            var feeOnThisServiceId = 0
            var status = "Not On Billing Plan"
            var estimatedTotalHours = 0
            var actualTotalHours = 0
            var totalFeeEstimated = 0
            var billedProgress = 0
            var timeProgress = 0
            var completionProgress = 0
            var feesComplete = 0
            var wip = 0
            var wipPercentage = 0
            var feesInvoicedAndIssued
            var invoice, invoiceLine
            var allInvoiceLines = []
            var invoicedAndIssuedInvoices
            var progressVsBill
            var labourCostsOfTimeOverProgress

            invoicedAndIssuedInvoices = invoices.filter(item => item.invoiceIssued === "YES")

            for (invoice = 0; invoice < invoicedAndIssuedInvoices.length; invoice++) {

                for (invoiceLine = 0; invoiceLine < invoicedAndIssuedInvoices[invoice].invoiceLines.length; invoiceLine++) {

                    allInvoiceLines.push({serviceId: invoicedAndIssuedInvoices[invoice].invoiceLines[invoiceLine].serviceId, invoicedAndIssuedVal: invoicedAndIssuedInvoices[invoice].invoiceLines[invoiceLine].value })

                }

            }



            for (service = 0; service < services.length; service++) {

                feeOnThisServiceId = serviceIncludedInBillingPlan(services[service].id)

                if (feeOnThisServiceId.toFixed(2) === services[service].totalFee.toFixed(2)) {
                    status = "On Billing Plan"
                }
                if (feeOnThisServiceId === 0 && services[service].totalFee > 0) {
                    status = "Not On Billing Plan"
                }
                if ((feeOnThisServiceId > 0 && Number(feeOnThisServiceId.toFixed(2)) < Number(services[service].totalFee.toFixed(2)))) {
                    status = "Partially On Billing Plan"
                }
                if (Number((feeOnThisServiceId.toFixed(2)) > Number(services[service].totalFee.toFixed(2)))) {
                    status = "Too Much On Billing Plan"
                }

                //Get how much has actually been invoiced (invoice ISSUED="YES") here!!!!!!!!

                    if (allInvoiceLines.filter(item => item.serviceId === services[service].id)) {
                        feesInvoicedAndIssued = allInvoiceLines.filter(item => item.serviceId === services[service].id).map(item => item.invoicedAndIssuedVal).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
                    } else {

                        feesInvoicedAndIssued = 0.00
    
                    }

                //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

                //Code needed here to get percentage of fees marked as complete === YES vs percentage of fees marked as complete === NO


                if (showWIP) {

                    // console.log("calculating WIP")

                
                    feesComplete = services[service].activities.filter(item => item.activityComplete === "YES").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    
                    actualTotalHours = timesheets.filter(item => item.serviceId === services[service].id).map(item => item.timesheetMonday + item.timesheetTuesday + item.timesheetWednesday + item.timesheetThursday + item.timesheetFriday + item.timesheetSaturday + item.timesheetSunday).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
                    estimatedTotalHours = services[service].activities.map(item => item.estimatedHours).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
                    totalFeeEstimated = services[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
                    
                    if (feesComplete / services[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0) && isFinite(feesComplete / services[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {
                        completionProgress = ((feesComplete / services[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2)
                    } else {
                        completionProgress = 0.00
                    }

                    if (feesInvoicedAndIssued / totalFeeEstimated && isFinite(feesInvoicedAndIssued / totalFeeEstimated)) {
                        billedProgress = ((feesInvoicedAndIssued / totalFeeEstimated)*100).toFixed(2)
                    } else {
                        billedProgress = 0.00
                    }
                    if (actualTotalHours / estimatedTotalHours && isFinite(actualTotalHours / estimatedTotalHours)) {
                        timeProgress = ((actualTotalHours / estimatedTotalHours)*100).toFixed(2)
                    } else {
                        timeProgress = 0.00
                    }

                    progressVsBill = completionProgress - billedProgress

                    if (timeProgress < completionProgress) {
                        labourCostsOfTimeOverProgress = 0
                    } else {
                        labourCostsOfTimeOverProgress = (timeProgress-completionProgress)*0.5
                    }

                    wip =(totalFeeEstimated*(progressVsBill/100))+(totalFeeEstimated*(labourCostsOfTimeOverProgress/100))

                    if (wip / totalFeeEstimated && isFinite(wip / estimatedTotalHours)) {
                        wipPercentage = (wip / totalFeeEstimated).toFixed(2)
                    } else {
                        wipPercentage = 0.00
                    }

                    wip = wip.toFixed(2)

                }

                services[service] = { ...services[service], invoicedVal: feeOnThisServiceId, billingPlanStatus: status, feesInvoicedAndIssued, actualTotalHours, estimatedTotalHours, totalFeeEstimated, billedProgress, timeProgress, wip, wipPercentage, completionProgress, feesComplete }

            }

        }

    }, [services, invoices, timesheets, showWIP])

    useEffect(() => {

        // console.log("useEffect set order")

        let sortedData = [...servicesFiltered];

        if (sortedField === "totalFee" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.totalFee) < Number(item2.totalFee) ? -1 : Number(item1.totalFee) > Number(item2.totalFee) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "totalFee" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.totalFee) < Number(item2.totalFee) ? 1 : Number(item1.totalFee) > Number(item2.totalFee) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "totalCost" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.totalCost) < Number(item2.totalCost) ? -1 : Number(item1.totalCost) > Number(item2.totalCost) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "totalCost" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.totalCost) < Number(item2.totalCost) ? 1 : Number(item1.totalCost) > Number(item2.totalCost) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "invoicedVal" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.invoicedVal) < Number(item2.invoicedVal) ? -1 : Number(item1.invoicedVal) > Number(item2.invoicedVal) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "invoicedVal" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.invoicedVal) < Number(item2.invoicedVal) ? 1 : Number(item1.invoicedVal) > Number(item2.invoicedVal) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "projectNumber" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "projectNumber" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? 1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? -1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "billingPlanStatus" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.billingPlanStatus < item2.billingPlanStatus ? -1 : item1.billingPlanStatus > item2.billingPlanStatus ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "billingPlanStatus" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.billingPlanStatus < item2.billingPlanStatus ? 1 : item1.billingPlanStatus > item2.billingPlanStatus ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "serviceCreatedDate" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceCreatedDate < item2.serviceCreatedDate ? -1 : item1.serviceCreatedDate > item2.serviceCreatedDate ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "serviceCreatedDate" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceCreatedDate < item2.serviceCreatedDate ? 1 : item1.serviceCreatedDate > item2.serviceCreatedDate ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "serviceLiveDate" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceLiveDate < item2.serviceLiveDate ? -1 : item1.serviceLiveDate > item2.serviceLiveDate ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "serviceLiveDate" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceLiveDate < item2.serviceLiveDate ? 1 : item1.serviceLiveDate > item2.serviceLiveDate ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "serviceClosedDate" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceClosedDate < item2.serviceClosedDate ? -1 : item1.serviceClosedDate > item2.serviceClosedDate ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "serviceClosedDate" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceClosedDate < item2.serviceClosedDate ? 1 : item1.serviceClosedDate > item2.serviceClosedDate ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "wip" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.wip) < Number(item2.wip) ? -1 : Number(item1.wip) > Number(item2.wip) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "wip" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.wip) < Number(item2.wip) ? 1 : Number(item1.wip) > Number(item2.wip) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "wipPercentage" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.wipPercentage) < Number(item2.wipPercentage) ? -1 : Number(item1.wipPercentage) > Number(item2.wipPercentage) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "wipPercentage" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.wipPercentage) < Number(item2.wipPercentage) ? 1 : Number(item1.wipPercentage) > Number(item2.wipPercentage) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "timeProgress" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.timeProgress) < Number(item2.timeProgress) ? -1 : Number(item1.timeProgress) > Number(item2.timeProgress) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "timeProgress" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.timeProgress) < Number(item2.timeProgress) ? 1 : Number(item1.timeProgress) > Number(item2.timeProgress) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "completionProgress" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.completionProgress) < Number(item2.completionProgress) ? -1 : Number(item1.completionProgress) > Number(item2.completionProgress) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "completionProgress" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.completionProgress) < Number(item2.completionProgress) ? 1 : Number(item1.completionProgress) > Number(item2.completionProgress) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "billedProgress" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.billedProgress) < Number(item2.billedProgress) ? -1 : Number(item1.billedProgress) > Number(item2.billedProgress) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "billedProgress" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.billedProgress) < Number(item2.billedProgress) ? 1 : Number(item1.billedProgress) > Number(item2.billedProgress) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } 

    }, [sortedField, sortedOrder])

    if (services.length > 0 && servicesInvoiced.length > 0 && users.length > 0 && !loading && !invoicesLoading && !servicesInvoicedLoading && !usersLoading && !clientsLoading && clients.length > 0 && timesheets.length > 0 && !timesheetsLoading && invoices.length > 0) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Projects" section="Services Tracker" showUserMenu='true' />

                            <hr></hr>
                            <br></br>


                            <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div>
                                        <p className="text-xs">Deal Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateDealNameArray(services).map((item) => {
                                                return {
                                                    label: item.dealName,
                                                    value: item.dealName
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedDealName}
                                            onChange={setSelectedDealName}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Manager</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectManagerArray(services).map((item) => {
                                                return {
                                                    label: item.projectManager === "" ? "" : users.find(user => user.id === item.projectManager).firstName + " " + users.find(user => user.id === item.projectManager).lastName,
                                                    value: item.projectManager
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectManager}
                                            onChange={setSelectedProjectManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Service Manager</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServiceManagerArray(services).map((item) => {
                                                return {
                                                    label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                                                    value: item.serviceManager
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedServiceManager}
                                            onChange={setSelectedServiceManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Service</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServicesArray(services).map((item) => {
                                                return {
                                                    label: item.service,
                                                    value: item.service
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedService}
                                            onChange={setSelectedService}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectNameArray(services).map((item) => {
                                                return {
                                                    label: item.projectName,
                                                    value: item.projectName
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectName}
                                            onChange={setSelectedProjectName}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Number</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectNumberArray(services).map((item) => {
                                                return {
                                                    label: item.projectNumber,
                                                    value: item.projectNumber
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectNumber}
                                            onChange={setSelectedProjectNumber}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Service Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServiceStatusArray(services).map((item) => {
                                                return {
                                                    label: item.serviceStatus,
                                                    value: item.serviceStatus
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedServiceStatus}
                                            onChange={setSelectedServiceStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectStatusArray(services).map((item) => {
                                                return {
                                                    label: item.projectStatus,
                                                    value: item.projectStatus
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectStatus}
                                            onChange={setSelectedProjectStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Billing Plan Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={[{label: "On Billing Plan", value: "On Billing Plan"}, {label: "Not On Billing Plan", value: "Not On Billing Plan"}, {label: "Partially On Billing Plan", value: "Partially On Billing Plan"}, {label: "Too Much On Billing Plan", value: "Too Much On Billing Plan"}]}
                                            value={selectedBillingPlanStatus}
                                            onChange={setSelectedBillingPlanStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Work Stage</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateWorkStagesArray(services).map((item) => {
                                                return {
                                                    label: item.workStage,
                                                    value: item.workStage
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedWorkStage}
                                            onChange={setSelectedWorkStage}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Service Created Month</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServiceCreatedMonthsArray(services).map((item) => {
                                                return {
                                                    label: item.serviceCreatedMonth,
                                                    value: item.serviceCreatedMonth
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedServiceCreatedMonth}
                                            onChange={setSelectedServiceCreatedMonth}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Department</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateDepartmentsArray(services).map((item) => {
                                                return {
                                                    label: item.department,
                                                    value: item.department
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedDepartment}
                                            onChange={setSelectedDepartment}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Client PO</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateCustomerPoArray(services).map((item) => {
                                                return {
                                                    label: item.customerPo,
                                                    value: item.customerPo
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedCustomerPo}
                                            onChange={setSelectedCustomerPo}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Client</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectClientArray(services).map((item) => {
                                                return {
                                                    label: item.clientName,
                                                    value: item.clientName
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectClient}
                                            onChange={setSelectedProjectClient}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Client to Invoice</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServiceInvoiceClientArray(services).map((item) => {
                                                return {
                                                    label: item.serviceInvoiceClient === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClient).clientName,
                                                    value: item.serviceInvoiceClient
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedServiceInvoiceClient}
                                            onChange={setSelectedServiceInvoiceClient}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>
                                        <div>
                                        <p className="text-xs">QA Checked - Fees</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateBillingPlanAndFeesCheckedArray(services).map((item) => {
                                                return {
                                                    label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                                                    value: item.projectBillingPlanAndFeesChecked
                                                }
                                            })}
                                            value={selectedProjectBillingPlanAndFeesChecked}
                                            onChange={setSelectedProjectBillingPlanAndFeesChecked}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">QA Checked - Appointment</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectInvoicingCheckedArray(services).map((item) => {
                                                return {
                                                    label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                                                    value: item.projectInvoicingChecked
                                                }
                                            })}
                                            value={selectedProjectInvoicingChecked}
                                            onChange={setSelectedProjectInvoicingChecked}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>
                                        <div className="pl-3">
                                            <p className="text-xs font-bold">Filters</p>
                                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <br></br>

                            <hr></hr>

                            <br></br>

                            <div className="flex flex-col py-2">
                                <div className="overflow-x-auto items-holder-8A">
                                    <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                        <div className="shadow-md shadow-slate-300 rounded-2xl">
                                            <table className="objects-8A min-w-full divide-y divide-slate-200">

                                                <thead className="bg-pacific-blue-50 sticky top-0">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => { setSortedField('projectNumber'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'projectNumber' && sortedOrder === 'asc' ? 'Project Number ↑' : sortedField === 'projectNumber' && sortedOrder === 'desc' ? 'Project Number ↓' : 'Project Number ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Name</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Client</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Deal Name</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Department</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Work Stage</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Comments</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => { setSortedField('totalFee'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'totalFee' && sortedOrder === 'asc' ? 'Total Fee Value ↑' : sortedField === 'totalFee' && sortedOrder === 'desc' ? 'Total Fee Value ↓' : 'Total Fee Value ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consultancy Fees</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => { setSortedField('totalCost'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'totalCost' && sortedOrder === 'asc' ? 'Total Cost ↑' : sortedField === 'totalCost' && sortedOrder === 'desc' ? 'Total Cost ↓' : 'Total Cost ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Status</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Status</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Manager</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Manager</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => { setSortedField('invoicedVal'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'invoicedVal' && sortedOrder === 'asc' ? 'Total On Billing Plan ↑' : sortedField === 'invoicedVal' && sortedOrder === 'desc' ? 'Total On Billing Plan ↓' : 'Total On Billing Plan ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => { setSortedField('billingPlanStatus'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'billingPlanStatus' && sortedOrder === 'asc' ? 'Billing Plan Status ↑' : sortedField === 'billingPlanStatus' && sortedOrder === 'desc' ? 'Billing Plan Status ↓' : 'Billing Plan Status ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => {setSortedField('serviceCreatedDate'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='serviceCreatedDate' && sortedOrder==='asc' ? 'Service Created Date ↑' : sortedField==='serviceCreatedDate' && sortedOrder==='desc' ? 'Service Created Date ↓' : 'Service Created Date ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => {setSortedField('serviceLiveDate'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='serviceLiveDate' && sortedOrder==='asc' ? 'Service Live Date ↑' : sortedField==='serviceLiveDate' && sortedOrder==='desc' ? 'Service Live Date ↓' : 'Service Live Date ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => {setSortedField('serviceClosedDate'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='serviceClosedDate' && sortedOrder==='asc' ? 'Service Closed Date ↑' : sortedField==='serviceClosedDate' && sortedOrder==='desc' ? 'Service Closed Date ↓' : 'Service Closed Date ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client to Invoice</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client PO</th>
                                                        {showWIP &&
                                                        <>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Total Estimated Hours</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Total Actual Hours</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Total Estimated Fee</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Fees Billed and Issued</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <button className="uppercase" type="button" onClick={() => {setSortedField('completionProgress'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='completionProgress' && sortedOrder==='asc' ? 'Completion Progress % ↑' : sortedField==='completionProgress' && sortedOrder==='desc' ? 'Completion Progress % ↓' : 'Completion Progress % ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <button className="uppercase" type="button" onClick={() => {setSortedField('billedProgress'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='billedProgress' && sortedOrder==='asc' ? 'Billing Progress % ↑' : sortedField==='billedProgress' && sortedOrder==='desc' ? 'Billing Progress % ↓' : 'Billing Progress % ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <button className="uppercase" type="button" onClick={() => {setSortedField('timeProgress'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='timeProgress' && sortedOrder==='asc' ? 'Time Progress % ↑' : sortedField==='timeProgress' && sortedOrder==='desc' ? 'Time Progress % ↓' : 'Time Progress % ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <button className="uppercase" type="button" onClick={() => {setSortedField('wip'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='wip' && sortedOrder==='asc' ? 'WIP / IIA ↑' : sortedField==='wip' && sortedOrder==='desc' ? 'WIP / IIA ↓' : 'WIP / IIA ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> <button className="uppercase" type="button" onClick={() => {setSortedField('wipPercentage'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='wipPercentage' && sortedOrder==='asc' ? 'WIP / IIA & ↑' : sortedField==='wip' && sortedOrder==='desc' ? 'WIP / IIA & ↓' : 'WIP / IIA % ⇅'}</button></th>
                                                        </>
                                                        }
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> QA Checked - Fees</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> QA Checked - Appointment</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-slate-200">
                                                    {servicesFiltered.slice(firstRecord, lastRecord).map((service) => (
                                                        <tr key={service.uniqueKey}>
                                                            {/* <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {users.find(user => user.id === activity.serviceManager).firstName + ' ' + users.find(user => user.id === activity.serviceManager).lastName}</td> */}
                                                            
                                                            <td onClick={() => viewProject(service.projectId)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                            </svg> {service.projectNumber}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.projectName}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.clientId ? clients.find(client => client.id === service.clientId).clientName : ""}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.dealName}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.department}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.workStage}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.service}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.serviceComments}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.projectCurrency + " " + internationalNumberFormat.format(service.totalFee)}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.projectCurrency + " " + internationalNumberFormat.format(service.totalFee - service.totalCost)}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.projectCurrency + " " + internationalNumberFormat.format(service.totalCost)}</td>

                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(service.serviceStatus); setFieldToSendToModal("serviceStatus"); setObjectIdToSendToModal(service.id); setCollectionToSendToModal("projects_services") }} className="px-4 md:px-6 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{service.serviceStatus}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {service.projectStatus}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {users.find(user => user.id === service.projectManager).firstName + ' ' + users.find(user => user.id === service.projectManager).lastName}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {users.find(user => user.id === service.serviceManager).firstName + ' ' + users.find(user => user.id === service.serviceManager).lastName}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.projectCurrency + " " + internationalNumberFormat.format(service.invoicedVal)}</td>
                                                            {service.billingPlanStatus === "On Billing Plan" ? <td className="px-4 md:px-6 py-2 "><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>{service.billingPlanStatus}</td> : <td className="px-4 md:px-6  py-2 "><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                            </svg>{service.billingPlanStatus}</td>}
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.serviceCreatedDate}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.serviceLiveDate}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.serviceClosedDate}</td>
                                                            {service.billingPlanStatus === "Not On Billing Plan" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(service.serviceInvoiceClient); setFieldToSendToModal("serviceInvoiceClient"); setObjectIdToSendToModal(service.id); setCollectionToSendToModal("projects_services") }} className="px-4 md:px-6 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{service.serviceInvoiceClient ? clients.find(client => client.id === service.serviceInvoiceClient).clientName : ""}</td> : <td className="px-4 md:px-6 py-2 whitespace-nowrap"> {service.serviceInvoiceClient ? clients.find(client => client.id === service.serviceInvoiceClient).clientName : ""}</td>
                                                            }
                                                            {service.billingPlanStatus === "Not On Billing Plan" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(service.customerPo); setFieldToSendToModal("customerPo"); setObjectIdToSendToModal(service.id); setCollectionToSendToModal("projects_services") }} className="px-4 md:px-6 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{service.customerPo ? service.customerPo : ""}</td> : <td className="px-4 md:px-6 py-2 whitespace-nowrap"> {service.customerPo}</td>
                                                            }
                                                            {showWIP &&
                                                            <>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.estimatedTotalHours}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.actualTotalHours}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.totalFeeEstimated}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.feesInvoicedAndIssued}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.completionProgress + "%"}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.billedProgress + "%"}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.timeProgress + "%"}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.wip}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {service.wipPercentage + "%"}</td>
                                                            </>
                                                            }
                                                            {service.projectBillingPlanAndFeesChecked !== "NO" ? <td className="px-4 md:px-6 py-2 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>{users.find(item => item.id === service.projectBillingPlanAndFeesChecked).firstName + ' ' + users.find(item => item.id === service.projectBillingPlanAndFeesChecked).lastName}</td> : <td className="px-4 md:px-6  py-2 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                            </svg>{service.projectBillingPlanAndFeesChecked}</td>}
                                                           
                                                            {service.projectInvoicingChecked !== "NO" ? <td className="px-4 md:px-6 py-2 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>{users.find(item => item.id === service.projectInvoicingChecked).firstName + ' ' + users.find(item => item.id === service.projectInvoicingChecked).lastName}</td> : <td className="px-4 md:px-6  py-2 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                            </svg>{service.projectInvoicingChecked}</td>}
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={services} role={role} onClose={() => setShowModal(false)} show={showModal} title="Update Service" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid gap-7 sm:grid-cols-3 ">
                                <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                                <div className="text-sm text-gray-500 text-center pt-2 ">{firstRecord + " to " + lastRecord + " of " + servicesFiltered.length}</div>
                                <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                            </div>

                            <div><div className="flex items-center bg-slate-100">
                                <div className="w-full px-5 mx-auto my-5">
                                    <div className="grid gap-7 sm:grid-cols-2 ">


                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Total Fee Value £</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-indigo-500 "><p className="my-1">£ {internationalNumberFormat.format(servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.totalFee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-sm text-gray-500 ">Total Fee Value €</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-indigo-500 "><p className="my-1">€ {internationalNumberFormat.format(servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.totalFee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                            <div>
                                                <div className="text-sm text-gray-500 ">Total on Billing Plan £</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-indigo-500 "><p className="my-1">£ {internationalNumberFormat.format(servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.invoicedVal).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-sm text-gray-500 ">Total on Billing Plan €</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-indigo-500 "><p className="my-1">€ {internationalNumberFormat.format(servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.invoicedVal).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                                </svg>
                                            </div>
                                        </div>
                                        {showWIP &&
                                        <>
                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Billed Progress £ %</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{(servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0)) !== 0 ? internationalNumberFormat.format(((servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.feesInvoicedAndIssued).reduce((prev, curr) => Number(prev) + Number(curr), 0) / servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0))*100).toFixed(2)) + "%" : "0%"}</p></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-sm text-gray-500 ">Billed Progress € %</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{(servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0)) !== 0 ? internationalNumberFormat.format(((servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.feesInvoicedAndIssued).reduce((prev, curr) => Number(prev) + Number(curr), 0) / servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0))*100).toFixed(2)) + "%" : "0%"}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                            </svg>
                                        </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Completion Progress £ %</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{(servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0)) !== 0 ? internationalNumberFormat.format(((servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.feesComplete).reduce((prev, curr) => Number(prev) + Number(curr), 0) / servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0))*100).toFixed(2)) + "%" : "0%"}</p></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-sm text-gray-500 ">Completion Progress € %</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{(servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0)) !== 0 ? internationalNumberFormat.format(((servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.feesComplete).reduce((prev, curr) => Number(prev) + Number(curr), 0) / servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.totalFeeEstimated).reduce((prev, curr) => Number(prev) + Number(curr), 0))*100).toFixed(2)) + "%" : "0%"}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                            </svg>
                                        </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Time Progress £ %</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{(servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.estimatedTotalHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)) !== 0 ? internationalNumberFormat.format(((servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.actualTotalHours).reduce((prev, curr) => Number(prev) + Number(curr), 0) / servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.estimatedTotalHours).reduce((prev, curr) => Number(prev) + Number(curr), 0))*100).toFixed(2)) + "%" : "0%"}</p></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-sm text-gray-500 ">Time Progress € %</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{(servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.estimatedTotalHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)) !== 0 ? internationalNumberFormat.format(((servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.actualTotalHours).reduce((prev, curr) => Number(prev) + Number(curr), 0) / servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.estimatedTotalHours).reduce((prev, curr) => Number(prev) + Number(curr), 0))*100).toFixed(2)) + "%" : "0%"}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                            </svg>
                                        </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">WIP £</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">£ {internationalNumberFormat.format((servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.wip).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="text-sm text-gray-500 ">WIP €</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">€ {internationalNumberFormat.format((servicesFiltered.filter(item => item.projectCurrency === "€").map(item => item.wip).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></div>
                                            </div>
                                        </div>

                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                            </svg>
                                        </div>
                                        </div>
                                        </>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            </div>
                            <br></br>
                            {!showWIP &&
                                <Button color="bg-bondi-blue" text="Calculate and Show WIP" onClick={() => calculcateAndShowWIP()} />
                            }
                            {showWIP &&
                                <Button color="bg-bondi-blue" text="Hide WIP" onClick={() => calculcateAndShowWIP()} /> 
                            }
                            <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(servicesFiltered)} />
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Projects" section="Services Tracker" showUserMenu='true' />

                        <br></br>
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>
                        <br></br>

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className=" bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )

    }

}

export default ProjectsTrackerServices

import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch, Navigate } from 'react-router-dom'
import authHeader from '../../services/AuthHeader'
import { v4 as uuidv4 } from 'uuid'
import AuthService from '../../services/AuthService';

const AddOrUpdateClientProjectActivity = () => {

    const match = useMatch('/addOrUpdateClientProjectActivity/:projectId/:serviceId/:activityId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const [activities, setActivities] = useState([])
    const [projectId, setProjectId] = useState('')
    const [service, setService] = useState('')
    const [serviceManager, setServiceManager] = useState('')
    const [department, setDepartment] = useState('')
    const [workStage, setWorkStage] = useState('')
    const [serviceStatus, setServiceStatus] = useState('')
    const [serviceCreatedDate, setServiceCreatedDate] = useState('')
    const [serviceLiveDate, setServiceLiveDate] = useState('')
    const [serviceClosedDate, setServiceClosedDate] = useState('')
    const [serviceComments, setServiceComments] = useState('')
    const [dealNumber, setDealNumber] = useState('')
    const [dealName, setDealName] = useState('')
    const [customerPo, setCustomerPo] = useState('')
    const [lineItemNumber, setLineItemNumber] = useState('')
    const [changedFlag, setChangedFlag] = useState(false)
    const [serviceInvoiceClient, setServiceInvoiceClient] = useState('')
    const [serviceInvoiceClientContact, setServiceInvoiceClientContact] = useState('')   

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([])

    const [activityId, setActivityId] = useState(0)
    const [activity, setActivity] = useState('')
    const [activityOwner, setActivityOwner] = useState('')
    const [activityComplete, setActivityComplete] = useState('')
    const [activityToBeInvoiced, setActivityToBeInvoiced] = useState('')
    const [canIssueDeliverables, setCanIssueDeliverables] = useState('')
    const [contractAgreed, setContractAgreed] = useState('')
    const [estimatedHours, setEstimatedHours] = useState(0)
    const [fee, setFee] = useState(0)
    const [hourlyRate, setHourlyRate] = useState(0)
    const [cost, setCost] = useState(0)
    const [costCategory, setCostCategory] = useState('')
    //const [toBeDeleted, setToBeDeleted] = useState('')
    const [redirecting, setRedirecting] = useState(false)
    const [editFee, setEditFee]= useState(false)

    const API_URL = process.env.REACT_APP_API_URL;


    useEffect(() => {

        axios.get(API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                if (match.params.activityId === "_add") {
                    setActivityOwner(res.data.sort((item1, item2) => item1.firstName < item2.firstName ? -1 : 0)[0].id)
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

        axios.get(API_URL + '/projects_services/' + match.params.serviceId, { headers: authHeader() })
            //fetch(process.env.REACT_APP_API_URL + '/projects_services/' + match.params.serviceId)
            //.then((res) => res.json())
            .then((response) => {

                setProjectId(response.data.projectId)
                setService(response.data.service)
                setServiceManager(response.data.serviceManager)
                setDepartment(response.data.department)
                setWorkStage(response.data.workStage)
                setServiceStatus(response.data.serviceStatus)
                setServiceCreatedDate(response.data.serviceCreatedDate)
                setServiceLiveDate(response.data.serviceLiveDate)
                setServiceClosedDate(response.data.serviceClosedDate)
                setServiceComments(response.data.serviceComments)
                setDealNumber(response.data.dealNumber)
                setDealName(response.data.dealName)
                setActivities(response.data.activities)
                setActivityComplete("NO")
                setCustomerPo(response.data.customerPo)
                setLineItemNumber(response.data.lineItemNumber)
                setChangedFlag(response.data.changedFlag)
                setServiceInvoiceClient(response.data.serviceInvoiceClient)
                setServiceInvoiceClientContact(response.data.serviceInvoiceClientContact)

                //if a scope item is being edited then set state for ItemID, Description etc for that scope item...
                if (match.params.activityId !== "_add") {
                    setActivityId(response.data.activities.find(activity => activity.activityId === match.params.activityId).activityId)
                    setActivity(response.data.activities.find(activity => activity.activityId === match.params.activityId).activity)
                    setActivityOwner(response.data.activities.find(activity => activity.activityId === match.params.activityId).activityOwner)
                    setActivityComplete(response.data.activities.find(activity => activity.activityId === match.params.activityId).activityComplete)
                    setActivityToBeInvoiced(response.data.activities.find(activity => activity.activityId === match.params.activityId).activityToBeInvoiced)
                    setCanIssueDeliverables(response.data.activities.find(activity => activity.activityId === match.params.activityId).canIssueDeliverables)
                    setContractAgreed(response.data.activities.find(activity => activity.activityId === match.params.activityId).contractAgreed)
                    setEstimatedHours(response.data.activities.find(activity => activity.activityId === match.params.activityId).estimatedHours)
                    setFee(response.data.activities.find(activity => activity.activityId === match.params.activityId).fee)
                    setHourlyRate(response.data.activities.find(activity => activity.activityId === match.params.activityId).hourlyRate)
                    setCost(response.data.activities.find(activity => activity.activityId === match.params.activityId).cost)
                    setCostCategory(response.data.activities.find(activity => activity.activityId === match.params.activityId).costCategory)
                } else {

                    // let sortedItems = response.data.activities.sort((item1, item2) => (item1.activityId < item2.activityId) ? -1 : (item1.activityId > item2.activityId) ? 1 : 0)

                    // if (sortedItems.length === 0) {
                    //     setActivityId(1)
                    // } else {
                    //     setActivityId(sortedItems[sortedItems.length - 1].activityId + 1)
                    // }

                    setActivityId(uuidv4())

                }
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(_content);
                    setRedirecting(true)
                })
            .finally(() => {
                setLoading(false);
            });

            if (match.params.activityId === "_add") {

                setHourlyRate(85)

            }

        if (AuthService.getCurrentUserName()==="stacey.cougill@eightversa.com" || AuthService.getCurrentUserName()==="yiota.paraskeva@eightversa.com" || AuthService.getCurrentUserName()==="chris.hocknell@eightversa.com" || AuthService.getCurrentUserName()==="will.fitzjohn@eightversa.com" || AuthService.getCurrentUserName()==="isobel.dunn@eightversa.com" || AuthService.getCurrentUserName()==="daniel.champ@eightversa.com" || department==="10. Operations"){
            setEditFee(true)
        }else{
            setEditFee(false)
        }

    }, [match.params.serviceId, match.params.activityId, department])

    useEffect(() => {

        setFee(Number((estimatedHours * hourlyRate).toFixed(2)))

    }, [estimatedHours, hourlyRate])

    const onSubmit = async (e) => {
        e.preventDefault()

        if (activity) {

            if (match.params.activityId === "_add") {
                activities.push({ activityId, activity, activityComplete, activityOwner, activityToBeInvoiced, canIssueDeliverables, contractAgreed, estimatedHours, fee, hourlyRate, cost, costCategory })
            }

            var newProjectsServices

            await axios.get(API_URL + '/projects_services/' + match.params.serviceId, { headers: authHeader() })
            .then((response) => {
                newProjectsServices = response.data
            })
            .catch((err) => {
                console.log(err);
            })

            //updateForm({ projectId, workStage, service, serviceManager, department, activities, serviceStatus, serviceComments, toBeDeleted: "NO", serviceCreatedDate, serviceLiveDate, serviceClosedDate, dealNumber, dealName, customerPo, lineItemNumber, changedFlag, serviceInvoiceClient, serviceInvoiceClientContact }).then(res => { navigate(-1) })
            updateForm(newProjectsServices).then(res => { navigate(-1) })

        }
    }

    const updateForm = async (form) => {
        if (match.params.activityId !== "_add") {
            form.activities.find(activity => activity.activityId === match.params.activityId).activityId = activityId
            form.activities.find(activity => activity.activityId === match.params.activityId).activity = activity
            form.activities.find(activity => activity.activityId === match.params.activityId).activityOwner = activityOwner
            form.activities.find(activity => activity.activityId === match.params.activityId).activityComplete = activityComplete
            form.activities.find(activity => activity.activityId === match.params.activityId).activityToBeInvoiced = activityToBeInvoiced
            form.activities.find(activity => activity.activityId === match.params.activityId).canIssueDeliverables = canIssueDeliverables
            form.activities.find(activity => activity.activityId === match.params.activityId).contractAgreed = contractAgreed
            form.activities.find(activity => activity.activityId === match.params.activityId).estimatedHours = estimatedHours
            form.activities.find(activity => activity.activityId === match.params.activityId).fee = fee
            form.activities.find(activity => activity.activityId === match.params.activityId).hourlyRate = hourlyRate
            form.activities.find(activity => activity.activityId === match.params.activityId).cost = cost
            form.activities.find(activity => activity.activityId === match.params.activityId).costCategory = costCategory

        } else {
            form.activities.push({activityId, activity, activityOwner, activityComplete, activityToBeInvoiced, canIssueDeliverables, contractAgreed, estimatedHours, fee, hourlyRate, cost, costCategory})
        }
        await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + match.params.serviceId, form)
    }

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (!loading && !usersLoading) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Projects - Add / Edit Activity" section="Activity Main Details" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Activity</label>
                            <input type="text" placeholder="Enter Activity Here" value={activity} onChange={(e) => setActivity(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Activity Owner</label>
                            <select value={activityOwner} onChange={(e) => setActivityOwner(e.target.value)}>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Activity Complete</label>
                            <select value={activityComplete} onChange={(e) => setActivityComplete(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>
                        {editFee === false &&
                        <div>
                        <div className="form-control-8A">
                            <label>Estimated Hours</label>
                            <input readOnly={cost !== 0 ? true : false} type="number" placeholder="Enter estimatedHours Here" value={estimatedHours} onChange={(e) => setEstimatedHours(Number(e.target.value))} />
                        </div>

                        <div className="form-control-8A">
                            <label>Hourly Rate</label>
                            <input readOnly={true} type="number" placeholder="Enter hourlyRate Here" value={hourlyRate} onChange={(e) => setHourlyRate(Number(e.target.value))} />
                        </div>

                        <div className="form-control-8A">
                            <label>Fee</label>
                            <input readOnly={true} type="number" placeholder="Enter fee Here" value={fee} onChange={(e) => setFee(Number(e.target.value))} />
                        </div>

                        <div className="form-control-8A">
                            <label>Cost</label>
                            <input readOnly={estimatedHours !== 0 ? true : false} type="number" placeholder="Enter cost Here" value={cost} onChange={(e) => {setCost(Number(e.target.value)); setFee(Number(e.target.value))}} />
                        </div>
                        </div>
                        }
                        {editFee === true &&
                        <div>
                        <div className="form-control-8A">
                            <label>Estimated Hours</label>
                            <input type="number" placeholder="Enter estimatedHours Here" value={estimatedHours} onChange={(e) => setEstimatedHours(Number(e.target.value))} />
                        </div>

                        <div className="form-control-8A">
                            <label>Hourly Rate</label>
                            <input type="number" placeholder="Enter hourlyRate Here" value={hourlyRate} onChange={(e) => setHourlyRate(Number(e.target.value))} />
                        </div>

                        <div className="form-control-8A">
                            <label>Fee</label>
                            <input type="number" placeholder="Enter fee Here" value={fee} onChange={(e) => setFee(Number(e.target.value))} />
                        </div>

                        <div className="form-control-8A">
                            <label>Cost</label>
                            <input type="number" placeholder="Enter cost Here" value={cost} onChange={(e) => {setCost(Number(e.target.value)); setFee(Number(e.target.value))}} />
                        </div>
                        </div>
                        }

                        <div className="form-control-8A">
                            <label>Cost Category</label>
                            <select value={costCategory} onChange={(e) => setCostCategory(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Certifications Fees">Certifications Fees</option>
                                <option value="Shipping and Couriers">Shipping and Couriers</option>
                                <option value="Data Search">Data Search</option>
                                {/* <option value="Entertaining">Entertaining</option> */}
                                <option value="Labs and Testing">Labs and Testing</option>
                                {/* <option value="Reprographics">Reprographics</option> */}
                                {/* <option value="Sundry">Sundry</option> */}
                                <option value="Travel and Accomodation">Travel</option>
                                <option value="Other">Other</option>
                                <option value="Freelance Environmental Consultants">Freelance Environmental Consultants</option>
                            </select>
                        </div>


                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Projects - Add / Edit Activity" section="Activity Main Details" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateClientProjectActivity
import { useEffect, useState } from 'react'
import { useNavigate, useMatch, Navigate } from 'react-router-dom'
import Options from '../UI/Options'
import Button from '../UI/Button'
import axios from 'axios'
// import authHeader from '../../services/AuthHeader'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ReactSelect from 'react-select'
// import Moment from 'react-moment'
import { v4 as uuidv4 } from 'uuid'
import { ExportToCsv } from 'export-to-csv'
import AuthService from '../../services/AuthService';

const ListClientProjectInvoiceDetails = (props) => {

    const match = useMatch('/viewClientProject/:projectId')

    const [loading, setLoading] = useState(true)
    const [projectServices, setProjectServices] = useState([])

    const [clientsLoading, setClientsLoading] = useState(true)
    const [clients, setClients] = useState([])

    const [contactsLoading, setContactsLoading] = useState(true)
    const [contacts, setContacts] = useState([])

    const [invoicesLoading, setInvoicesLoading] = useState(true)
    const [invoices, setInvoices] = useState([])
    const [invoicesFiltered, setInvoicesFiltered] = useState([])

    const [invoicesListViewLoading, setInvoicesListViewLoading] = useState(true)
    const [invoicesListView, setInvoicesListView] = useState([])
    const [redirecting, setRedirecting] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')
    const [projectIdToSendToModal, setProjectIdToSendToModal] = useState('')

    const [selectedDepartment, setSelectedDepartment] = useState('All')
    const [selectedService, setSelectedService] = useState('All')
    const [selectedInvoiceMonth, setSelectedInvoiceMonth] = useState('All')
    const [selectedInvoiceClientId, setSelectedInvoiceClientId] = useState('All')
    const [selectedInvoiceCustPo, setSelectedInvoiceCustPo] = useState('All')

    const [arrayForInvoiceMonths, setArrayForInvoiceMonths] = useState([])

    const [invUpdate, setInvUpdate] = useState('')

    const [adminUser, setAdminUser] = useState(false)

    // const [selectedService, setSelectedService] = useState('All')

    // const API_URL = process.env.REACT_APP_API_URL;

    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const navigate = useNavigate()

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 350,
            fontSize: 13
        }),
    }

    const arrayToCsv = (data) => {

        var j, k

        var dataToExport = []

        for (j = 0; j < data.length; j++) {

            for (k = 0; k < data[j].invoiceLines.length; k++) {

                dataToExport.push({
                invoiceNumber: data[j].invoiceNumber,
                invoiceDate: data[j].invoiceDate,
                invoiceIssued: data[j].invoiceIssued,
                invoicePaid: data[j].invoicePaid,
                invoiceClient: clients.find(client => client.id === data[j].clientId).clientName,
                invoiceContact: contacts.find(contact => contact.id === data[j].invoiceContactId).contactName,
                invoiceCustPo: data[j].invoiceCustPo,
                invoiceService: props.services.find(service => service.id === data[j].invoiceLines[k].serviceId).service,
                invoiceLineDescription: data[j].invoiceLines[k].invoiceLineDescription,
                invoiceLineValue: data[j].invoiceLines[k].value,
                invoiceOKToInvoice: data[j].invoiceLines[k].okToInvoice,
                invoiceLineComments: data[j].invoiceLines[k].invoiceLineComments,
                invoiceLineStatus: data[j].invoiceLines[k].invoiceLineStatus

            })

            }
            
        }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Billing Plan',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(dataToExport)

    }

    const deletionValidityCheck = (id) => {
        //check here to make sure no invoices lines are on the invoice

        if (invoices.find(item => item.id === id).invoiceLines.length === 0 || invoices.find(item => item.id === id).draftInvoice === "YES") {
            return true

        } else {
            return false
        }

    }

    const deleteInvoice = (id) => {

        if (window.confirm('Are you sure you want to delete Invoice?')) {

            var checkOK = deletionValidityCheck(id)

            if (checkOK) {

                axios.delete(process.env.REACT_APP_API_URL + '/invoices/' + id)
                setInvoices(invoices.filter((invoice) => invoice.id !== id))
                setInvoicesFiltered(invoices.filter((invoice) => invoice.id !== id))

            } else {

                window.alert("Please remove invoice lines in order to delete invoice")

            }
        }
    }

    const confirmInvoice = async (id) => {

        var newInvoices
        var newInvoiceNumber
        await axios.get(process.env.REACT_APP_API_URL + '/invoices_by_project/'+ match.params.projectId)
        .then((res) => {
            newInvoices = res.data
        })
        .catch((err) => {
            console.log(err);
        })

        //Add check to make sure all invoice lines are 'OK to Invoice' === YES before confirming an invoice
        if (newInvoices.find(item => item.id === id).invoiceLines.filter((item) => item.okToInvoice === "NO").length === 0) {

            newInvoices.find(item => item.id === id).draftInvoice = "NO"

            await axios.get(process.env.REACT_APP_API_URL + '/invoices')
                .then((res) => {
                    var allInvoiceData = []
                    allInvoiceData = res.data.filter(item => item.invoiceNumber.slice(0, 1) !== "D")
                        newInvoiceNumber = String(Number(allInvoiceData.sort((item1, item2) => (Number(item1.invoiceNumber) < Number(item2.invoiceNumber)) ? -1 : (Number(item1.invoiceNumber) > Number(item2.invoiceNumber)) ? 1 : 0)[allInvoiceData.length - 1].invoiceNumber) + 1)
                    }
                )
                .catch((err) => {
                    console.log(err);
                })

            newInvoices.find(item => item.id === id).invoiceNumber = newInvoiceNumber

            await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + id, newInvoices.find(item => item.id === id))

            setInvoices(newInvoices.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))

        } else {
            window.alert("Please ensure all invoice lines are tagged as 'SM Approved' before confirming the invoice")
        }

    }

    const cloneInvoice = async (id) => {

        // Get invoice master data
        const invoiceMaster = invoices.filter((invoice) => invoice.id === id)
        // Call the modal
        setShowModal(true); setValueToSendToModal(invoiceMaster[0].invoiceDate); setFieldToSendToModal("cloneInvoice"); setObjectIdToSendToModal(invoiceMaster[0].id); setCollectionToSendToModal("invoices"); setProjectIdToSendToModal(invoiceMaster[0].projectId)

    }

    const getDepartmentForService = (serviceId) => {

        var department
        var service

        service = props.services.find(item => item.id === serviceId).service

        department = props.services.find(item => item.service === service).department

        return department
    }

    const clearFilters = () => {
        setSelectedInvoiceMonth('All')
        setSelectedService('All')
        setSelectedDepartment('All')
        // setSelectedWorkStage('All')
        // setSelectedServiceManager('All')
        setSelectedInvoiceClientId('All')
        setSelectedInvoiceCustPo('All')
    }

    const aggregateInvoiceMonthArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceMonth === val.invoiceMonth);
            if (index === -1) {
                acc.push({
                    invoiceMonth: val.invoiceMonth,
                });
            };
            return acc.sort((item1, item2) => item1.invoiceMonth > item2.invoiceMonth ? 1 : 0)
        }, []);
    };

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: ((val.serviceComments.length!==0) ? val.service + ": " + val.serviceComments : val.service),
                    // service: val.service + ": " + val.serviceComments,
                    id: val.id,
                });
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department,
                });
            };
            return acc;
        }, []);
    };

    const aggregateClientsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.id === val.clientId);
            if (index === -1) {
                acc.push({
                    clientName: clients.find(client => client.id === val.clientId).clientName,
                    id: val.clientId
                });
            };
            return acc;
        }, []);
    };

    const aggregateInvoiceCustPoArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceCustPo === val.invoiceCustPo);
            if (index === -1) {
                if (val.invoiceCustPo.length > 0) {
                    acc.push({
                        invoiceCustPo: val.invoiceCustPo
                    });
                }
            };
            return acc;
        }, []);
    };

    const okToInvoicePressed = async (id) =>{
        
        if (adminUser) {

            if (window.confirm("Are you sure you want to set the Invoice Issued Status to NO") === true) {
              
                var newInvoices
                await axios.get(process.env.REACT_APP_API_URL + '/invoices_by_project/'+ match.params.projectId)
                .then((res) => {
                    newInvoices = res.data
                })
                .catch((err) => {
                    console.log(err);
                })
    
                newInvoices.find(item => item.id === id).invoiceIssued = "NO"    
                await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + id, newInvoices.find(item => item.id === id))    
                setInvoices(newInvoices.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))

            }

        }
        
    }

    useEffect(() => {

        const arrayForInvoiceMonths2=[]
        invoices.forEach(invoice => {
            if (!arrayForInvoiceMonths2.includes(invoice.invoiceDate.substring(0, 7))){
                arrayForInvoiceMonths2.push({"invoiceMonth": invoice.invoiceDate.substring(0, 7)})
            }
        });
        setArrayForInvoiceMonths(arrayForInvoiceMonths2)

    }, [invoices])

    useEffect(() => {

        //This should only run after the model used for clone invoice has been closed
        if (showModal===false && fieldToSendToModal==="cloneInvoice"){

            axios.get(process.env.REACT_APP_API_URL + '/invoices_by_project/'+match.params.projectId)
            .then((res) => {
                setInvoices(res.data.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                setInvoicesFiltered(res.data.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                var dummyID = uuidv4()
                setInvUpdate(dummyID)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                
            });

        }
        
    }, [showModal])

    useEffect(() => {

        var localServicesArrary

        if (props.billingCompany === "All") {

            setProjectServices(props.services)
            localServicesArrary = props.services

        }

        if (props.billingCompany === "Eight Versa") {

            setProjectServices(props.services.filter(item => item.department.slice(0,2) !== "20"))
            localServicesArrary = props.services.filter(item => item.department.slice(0,2) !== "20")

        }

        if (props.billingCompany === "NCS") {

            setProjectServices(props.services.filter(item => item.department.slice(0,2) === "20"))
            localServicesArrary = props.services.filter(item => item.department.slice(0,2) === "20")

        }

        setLoading(false)

        // axios.get(process.env.REACT_APP_API_URL + '/invoices')
        axios.get(process.env.REACT_APP_API_URL + '/invoices_by_project/'+match.params.projectId)
            .then((res) => {

                var invoicesLocalArray

                if (props.billingCompany === "All") {

                    setInvoices(res.data.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                    setInvoicesFiltered(res.data.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                    invoicesLocalArray = res.data.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)

                }

                if (props.billingCompany === "Eight Versa") {

                    setInvoices(res.data.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => getDepartmentForService(invoiceLine.serviceId).slice(0,2) !== "20")).sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                    setInvoicesFiltered(res.data.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => getDepartmentForService(invoiceLine.serviceId).slice(0,2) !== "20")).sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                    invoicesLocalArray = res.data.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => getDepartmentForService(invoiceLine.serviceId).slice(0,2) !== "20")).sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)

                }

                if (props.billingCompany === "NCS") {

                    setInvoices(res.data.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => getDepartmentForService(invoiceLine.serviceId).slice(0,2) === "20")).sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                    setInvoicesFiltered(res.data.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => getDepartmentForService(invoiceLine.serviceId).slice(0,2) === "20")).sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0))
                    invoicesLocalArray = res.data.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => getDepartmentForService(invoiceLine.serviceId).slice(0,2) === "20")).sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)

                }

                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                var service
                var invoice
                var invoiceLine
                var invoiceLineId = 0
                var invoicesLinesToList = []

                for (service = 0; service < localServicesArrary.length; service++) {
                    for (invoice = 0; invoice < invoicesLocalArray.length; invoice++) {
                        for (invoiceLine = 0; invoiceLine < invoicesLocalArray[invoice].invoiceLines.length; invoiceLine++) {

                            if (
                                invoicesLocalArray[invoice].invoiceLines[invoiceLine].serviceId === localServicesArrary[service].id
                            ) {
                                invoiceLineId++
                                var invoiceDate = new Date(invoicesLocalArray[invoice].invoiceDate)
                                var invoiceMonth = months[invoiceDate.getMonth()] + '-' + invoiceDate.getFullYear()
                                var invoiceDay = invoiceDate.getDate()

                                invoicesLinesToList.push({ invoiceLineId, service: localServicesArrary[service].service, invoiceNumber: invoicesLocalArray[invoice].invoiceNumber, value: invoicesLocalArray[invoice].invoiceLines[invoiceLine].value, description: invoicesLocalArray[invoice].invoiceLines[invoiceLine].invoiceLineDescription, invoiceIssued: invoicesLocalArray[invoice].invoiceIssued, invoiceDate: invoiceDay, invoiceMonth, clientId: invoicesLocalArray[invoice].clientId, invoiceContactId: invoicesLocalArray[invoice].invoiceContactId, invoicePaid: invoicesLocalArray[invoice].invoicePaid, invoiceId: invoicesLocalArray[invoice].id })
                            }
                        }
                    }
                }

                setInvoicesListView(invoicesLinesToList.sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setInvoicesListViewLoading(false);
                setInvoicesLoading(false);
            });

        setClients(props.clients)
        setClientsLoading(false)
        setContacts(props.contacts)
        setContactsLoading(false);

    }, [match.params.projectId, props.services, props.timesheets, props.clients, props.contacts, props.billingCompany])

    useEffect(() => {

        setInvoicesFiltered(invoices)

        if (selectedInvoiceMonth !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => item.invoiceDate.substring(0,7) === selectedInvoiceMonth.value))
        }

        if (selectedService !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => invoiceLine.serviceId === selectedService.value)))
        }

        if (selectedDepartment !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((invoice) => invoice.invoiceLines.some((invoiceLine) => getDepartmentForService(invoiceLine.serviceId) === selectedDepartment.value)))
        }

        if (selectedInvoiceClientId !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => item.clientId === selectedInvoiceClientId.value))
        }

        if (selectedInvoiceCustPo !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => item.invoiceCustPo === selectedInvoiceCustPo.value))
        }

    }, [selectedInvoiceMonth, selectedService, selectedInvoiceClientId, selectedInvoiceCustPo, selectedDepartment, invUpdate, invoices, props.billingCompany, props.services])

    useEffect(() => {

        var username = AuthService.getCurrentUserName()
        if (username === "stacey.cougill@eightversa.com" || username === "yiota.paraskeva@eightversa.com" || username === "chris.hocknell@eightversa.com" || username === "violeta.kriauciunaite@eightversa.com" || username === "amina.mustaeva@eightversa.com" || username === "james.wilton@eightversa.com" ) {
            setAdminUser(true)
        }else{
            setAdminUser(false)
        }

    },[])

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (invoices.length > 0 && !loading && !invoicesLoading && !clientsLoading && !contactsLoading && projectServices.length > 0) {

        return (

            <div>

                <h2 className='pt-4 px-3 pb-2 w-full max-w-9xl mx-auto header-8A'>Billing Plan</h2>

                <div className="flex items-center">
                    <div className="w-full mx-auto m-5 px-3">
                        <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                            
                            <div>
                                <p className="text-xs">Invoice Month</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedInvoiceMonth}
                                    onChange={setSelectedInvoiceMonth}
                                    options={aggregateInvoiceMonthArray(arrayForInvoiceMonths).map((item) => {
                                        return {
                                            label: item.invoiceMonth,
                                            value: item.invoiceMonth
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Department</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedDepartment}
                                    onChange={setSelectedDepartment}
                                    options={aggregateDepartmentsArray(projectServices).map((item) => {
                                        return {
                                            label: item.department,
                                            value: item.department
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedService}
                                    onChange={setSelectedService}
                                    options={aggregateServicesArray(projectServices).map((item) => {
                                        return {
                                            label: item.service,
                                            value: item.id
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Invoice Client</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedInvoiceClientId}
                                    onChange={setSelectedInvoiceClientId}
                                    options={aggregateClientsArray(invoices).map((item) => {
                                        return {
                                            label: item.clientName,
                                            value: item.id
                                        }
                                    })} />
                            </div>

                            {/* <div>
                                <p className="text-xs">Service Comment</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedServiceManager}
                                    onChange={setSelectedServiceManager}
                                    options={aggregateServiceManagersArray(projectServices).sort((item1, item2) => item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : 0).map((item) => {
                                        return {
                                            label: item.serviceManagerName,
                                            value: item.serviceManager
                                        }
                                    })} />
                            </div> */}

                            <div>
                                <p className="text-xs">Client PO</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedInvoiceCustPo}
                                    onChange={setSelectedInvoiceCustPo}
                                    options={aggregateInvoiceCustPoArray(invoices).sort((item1, item2) => item1.invoiceCustPo < item2.invoiceCustPo ? -1 : item1.invoiceCustPo > item2.invoiceCustPo ? 1 : 0).map((item) => {
                                        return {
                                            label: item.invoiceCustPo,
                                            value: item.invoiceCustPo
                                        }
                                    })} />
                            </div>

                            <div className="">
                                <p className="text-xs font-bold pl-2">Filters</p>
                                <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                            </div>
                            <div className="">
                                <p className="text-xs font-bold pl-2">Export Data</p>
                                <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(invoicesFiltered)} />
                            </div>
                        </div>
                    </div>
                </div>
                {props.role.toString().slice(0,1) === "2" &&                  
                <Button text="Add New Invoice" onClick={() => navigate(`/addOrUpdateClientProjectInvoiceDetails/${match.params.projectId}/_add`)} />
                }
                <br />

                <br></br>

                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="text-eight-text align-middle inline-block min-w-full px-3">
                            <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl">
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Number</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Day</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Value</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Issued</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Paid</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Client</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Contact</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client PO</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-xs font-medium text-congress-blue uppercase tracking-wider text-center"> Actions</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-2/3"> Invoice Items</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {invoicesFiltered.map((item) => (
                                            <tr key={item.id}>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap"> {item.invoiceNumber}</td>
                                                {/* <td className="px-4 md:px-2  py-2 whitespace-nowrap hover:cursor-pointer"> {item.invoiceDate}</td> */}
                                                {item.invoiceIssued === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(item.invoiceDate); setFieldToSendToModal("invoiceDate"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("invoices"); setProjectIdToSendToModal(item.projectId) }} className="pr-10 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg> {item.invoiceDate}</td> : <td>{item.invoiceDate}</td>}
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap"> {internationalNumberFormat.format(item.invoiceLines.map(item => item.value).reduce((prev, curr) => Number(prev) + Number(curr), 0))}</td>
                                                {item.invoiceIssued === "YES" ? <td className="bg-lime px-4 md:px-6  py-2 whitespace-nowrap" onDoubleClick={() => { okToInvoicePressed(item.id) }}>{item.invoiceIssued}</td> : <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {item.invoiceIssued}</td>}
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap"> {item.invoicePaid}</td>
                                                <td className="px-4 md:px-2  py-2 "> {clients.find(client => client.id === item.clientId).clientName}</td>
                                                <td className="px-4 md:px-2  py-2 "> {contacts.find(contact => contact.id === item.invoiceContactId).contactName}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap"> {item.invoiceCustPo}</td>
                                                {item.invoiceIssued === "NO" && props.role.toString().slice(0,1) === "2" && item.draftInvoice === "YES" ? <td className="text-center"><Options edit={`/addOrUpdateClientProjectInvoiceDetails/${match.params.projectId}/${item.id}`} deleteFunction={deleteInvoice} deleteObject={item.id} cloneFunction={cloneInvoice} cloneObject={item.id}></Options><Button color="bg-bondi-blue" text="Confirm Invoice" onClick={() => confirmInvoice(item.id)} /></td> : item.invoiceIssued === "NO" && props.role.toString().slice(0,1) === "2" && item.draftInvoice === "NO" ? <td className="text-center"><Options edit={`/addOrUpdateClientProjectInvoiceDetails/${match.params.projectId}/${item.id}`} deleteFunction={deleteInvoice} deleteObject={item.id} cloneFunction={cloneInvoice} cloneObject={item.id}></Options></td> : item.invoiceIssued === "NO" && props.role.toString().slice(0,1) !== "2" && item.invoiceLines.length > 0 &&item.draftInvoice === "YES" ? <td className="text-center"><Button color="bg-bondi-blue" text="Confirm Invoice" onClick={() => confirmInvoice(item.id)} /></td> : item.invoiceIssued === "NO" && props.role.toString().slice(0,1) !== "2" && item.invoiceLines.length === 0 ? <td className="text-center"><Options deleteFunction={deleteInvoice} deleteObject={item.id}></Options></td> : <td></td>}
                                                <td>
                                                    <table className="min-w-full divide-y divide-gray-200">

                                                        <thead className="bg-pacific-blue-50 min-w-full">
                                                            <tr>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/3 border-4 border-white"> Service</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/3 border-4 border-white"> Description</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/12 border-4 border-white"> Value</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/12 border-4 border-white"> SM Approved</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/12 border-4 border-white"> Invoice Line Comment</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/12 border-4 border-white"> Invoice Line Status</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {item.invoiceLines.map((invoiceLineItem) => (
                                                                <tr key={invoiceLineItem.invoiceLineItemId}>
                                                                    {/* <td className="px-4 md:px-2 py-2 hover:cursor-pointer w-1/3"> {projectServices.filter(service => service.id === invoiceLineItem.serviceId).length > 0 ? projectServices.find(service => service.id === invoiceLineItem.serviceId).service + " (" + projectServices.find(service => service.id === invoiceLineItem.serviceId).serviceComments + ")" : "Service ID No Longer Exists"}</td> */}
                                                                    {item.invoiceIssued === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(item.id); setFieldToSendToModal("invoiceNumber"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(invoiceLineItem.invoiceLineItemId); setProjectIdToSendToModal(item.projectId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="#6c6c6c" className="h-5 float-left w-5 mr-2">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                                                        </svg>{projectServices.filter(service => service.id === invoiceLineItem.serviceId).length > 0 ? projectServices.find(service => service.id === invoiceLineItem.serviceId).service + " (" + projectServices.find(service => service.id === invoiceLineItem.serviceId).serviceComments + ")" : "Service ID No Longer Exists"}</td> : <td className="px-4 md:px-2 py-2">{projectServices.filter(service => service.id === invoiceLineItem.serviceId).length > 0 ? projectServices.find(service => service.id === invoiceLineItem.serviceId).service + " (" + projectServices.find(service => service.id === invoiceLineItem.serviceId).serviceComments + ")" : "Service ID No Longer Exists"}</td>}
                                                                    {item.invoiceIssued === "NO" && invoiceLineItem.okToInvoice === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(invoiceLineItem.invoiceLineDescription); setFieldToSendToModal("invoiceLineDescription"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(invoiceLineItem.invoiceLineItemId); setProjectIdToSendToModal(item.projectId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                    </svg> {invoiceLineItem.invoiceLineDescription}</td> : <td className="px-4 md:px-2 py-2">{invoiceLineItem.invoiceLineDescription}</td>}
                                                                    {item.invoiceIssued === "NO" && invoiceLineItem.okToInvoice === "NO" && props.role.toString().slice(0,1) === "2" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(invoiceLineItem.value); setFieldToSendToModal("value"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(invoiceLineItem.invoiceLineItemId); setProjectIdToSendToModal(item.projectId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                    </svg> {internationalNumberFormat.format(invoiceLineItem.value)}</td> : <td className="px-4 md:px-2 py-2">{internationalNumberFormat.format(invoiceLineItem.value)}</td>}
                                                                    {item.invoiceIssued === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(invoiceLineItem.okToInvoice); setFieldToSendToModal("okToInvoice"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(invoiceLineItem.invoiceLineItemId); setProjectIdToSendToModal(item.projectId) }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                    </svg> {invoiceLineItem.okToInvoice}</td> : <td className="px-4 md:px-2 py-2">{invoiceLineItem.okToInvoice}</td>}
                                                                    {/* <td className="px-4 md:px-2 py-2 w-1/3 text-xs"> {invoiceLineItem.invoiceLineComments}</td> */}
                                                                    {item.invoiceIssued === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(invoiceLineItem.invoiceLineComments); setFieldToSendToModal("invoiceLineComments"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(invoiceLineItem.invoiceLineItemId); setProjectIdToSendToModal(item.projectId) }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                    </svg> <p className="max-h-[200px] text-ellipsis overflow-hidden">{invoiceLineItem.invoiceLineComments}</p></td> : <td className="px-4 md:px-2 py-2"><p className="max-h-[200px] text-ellipsis overflow-hidden">{invoiceLineItem.invoiceLineComments}</p></td>}
                                                                    {item.invoiceIssued === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(invoiceLineItem.invoiceLineStatus); setFieldToSendToModal("invoiceLineStatus"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(invoiceLineItem.invoiceLineItemId); setProjectIdToSendToModal(item.projectId) }} className="p-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                    </svg><svg className={invoiceLineItem.invoiceLineStatus === "Green" ? "inline-block fill-current text-lime" : invoiceLineItem.invoiceLineStatus === "Red" ? "inline-block fill-current text-red-500" : invoiceLineItem.invoiceLineStatus === "Amber" ? "inline-block fill-current text-amber-300" : "inline-block fill-current text-gray-500"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg><div className="inline-block pr-1">{invoiceLineItem.invoiceLineStatus}</div></td> : <td className="px-4 md:px-2 py-2"><svg className={invoiceLineItem.invoiceLineStatus === "Green" ? "inline-block fill-current text-lime" : invoiceLineItem.invoiceLineStatus === "Red" ? "inline-block fill-current text-red-500" : invoiceLineItem.invoiceLineStatus === "Amber" ? "inline-block fill-current text-amber-300" : "inline-block fill-current text-gray-500"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg>{invoiceLineItem.invoiceLineStatus}</td> }
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                    </table>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                                <ModalUpdateValue role ={props.role} objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={invoices} invoiceProjectId={projectIdToSendToModal} onClose={() => setShowModal(false)} show={showModal} title={fieldToSendToModal==="cloneInvoice" ? "Clone Invoice" : "Update Invoice Line Item"} />
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>
                <div><div className="flex items-center">
                    <div className="w-full mx-auto mb-5 px-3">
                        <div className="grid gap-2 w-full sm:gap-7 sm:grid-cols-2">

                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500">Total Scheduled</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{props.currency} {internationalNumberFormat.format(invoicesListView.map(item => item.value).reduce((prev, curr) => Number(prev) + Number(curr), 0))}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                <div>
                                    <div className="text-sm text-gray-500 ">Total Invoiced</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{props.currency} {internationalNumberFormat.format(invoicesListView.filter(item => item.invoiceIssued === "YES").map(item => item.value).reduce((prev, curr) => Number(prev) + Number(curr), 0))}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                No Billing Plan
            </div>)

    }

}

export default ListClientProjectInvoiceDetails

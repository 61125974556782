const Button = (props) => {
    return (
            <button type="button" onClick={props.onClick} style={{ backgroundColor: props.color }}
                className='shadow-md shadow-slate-300 rounded-full px-2 py-1 m-1 text-white bg-pacific-blue-500 whitespace-nowrap'>
                {props.text}
            </button>
    )
}

export default Button

import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch, Navigate } from 'react-router-dom'
import Button from '../UI/Button'
import Options from '../UI/Options'
import authHeader from '../../services/AuthHeader'
import { v4 as uuidv4 } from 'uuid'
import ModalUpdateValue from '../UI/ModalUpdateValue'


const AddOrUpdateClientProjectInvoiceDetails = () => {

    const match = useMatch('/AddOrUpdateClientProjectInvoiceDetails/:projectId/:invoiceId')

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

    const [addToInvoiceToggle, setAddToInvoiceToggle] = useState(false)

    const [clientsLoading, setClientsLoading] = useState(true)
    const [clients, setClients] = useState([])

    const [contactsLoading, setContactsLoading] = useState(true)
    const [contacts, setContacts] = useState([])

    const [invoices, setInvoices] = useState([])
    const [invoicesLoading, setInvoicesLoading] = useState(true)

    const [invoicesSchedule, setInvoicesSchedule] = useState([])
    const [invoicesScheduleLoading, setInvoicesScheduleLoading] = useState(true)

    const [projectServices, setProjectServices] = useState([])
    const [projectServicesLoading, setProjectServicesLoading] = useState(true)
    const [projectsLoading, setProjectsLoading] = useState(true)
    const [projects, setProjects] = useState(true)

    const [invoiceLoading, setInvoiceLoading] = useState(true)

    const navigate = useNavigate()

    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [invoiceDate, setInvoiceDate] = useState('')
    const [invoicePaid, setInvoicePaid] = useState('')
    const [invoiceContactId, setInvoiceContactId] = useState('')
    const [invoiceIssued, setInvoiceIssued] = useState('')
    const [invoiceNotes, setInvoiceNotes] = useState('')
    const [invoiceCustPo, setInvoiceCustPo] = useState('')
    
    const [clientId, setClientId] = useState('')
    const [invoiceLines, setInvoiceLines] = useState([])
    const [draftInvoice, setDraftInvoice] = useState('')
    const [projectId, setProjectId] = useState('')

    const [serviceToAdd, setServiceToAdd] = useState('')
    const [descriptionToAdd, setDescriptionToAdd] = useState('')
    const [valueToAdd, setValueToAdd] = useState(0)
    const [serviceCommentsToAdd, setServiceCommentsToAdd] = useState('')
    const [redirecting, setRedirecting] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL;

    const serviceIncludedInBillingPlan = (id) => {

        //checks to see if serviceId is present on an invoice and
        //checks total fee by service against total value added to billing plan by service

        var invoice
        var invoiceLine
        var totalFeeOnBillingPlan = 0
        var onInvoice = false

        //console.log(invoices)

        for (invoice = 0; invoice < invoices.length; invoice++) {
            for (invoiceLine = 0; invoiceLine < invoices[invoice].invoiceLines.length; invoiceLine++) {

                if (
                    invoices[invoice].invoiceLines[invoiceLine].serviceId === id
                ) {
                    onInvoice = true
                    totalFeeOnBillingPlan = totalFeeOnBillingPlan + invoices[invoice].invoiceLines[invoiceLine].value
                }
            }
        }

        if (onInvoice && totalFeeOnBillingPlan === 0) {
            totalFeeOnBillingPlan = -1
        }

        return totalFeeOnBillingPlan

    }

    const onSubmit = (e) => {

        e.preventDefault()

        // Before running any add/update code, check if the invoice cust has been chosen and check if a PO is required for the client
        if (!invoiceCustPo && clients.find(client => client.id === clientId).requirePoForInvoicing==="YES" && invoiceLines.length > 0){  //No cust PO but one is required
             window.alert("A PO is required for this client");
        }else{

            //if _add then get next D number for the invoice number
            var invoiceNum = invoiceNumber

            if (match.params.invoiceId === "_add") {

                if (invoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length > 0) {
                    invoiceNum = Number(invoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").sort((item1, item2) => Number(item1.invoiceNumber.slice(1)) < Number(item2.invoiceNumber.slice(1)) ? -1 : Number(item1.invoiceNumber.slice(1)) > Number(item2.invoiceNumber.slice(1)) ? 1 : 0)[invoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length - 1].invoiceNumber.slice(1)) + 1
                    console.log(invoiceNum)
                    invoiceNum = "D" + invoiceNum
                } else {
                    invoiceNum = "D1"
                }
            }

            if (invoiceDate && clientId && invoiceContactId) {
                if (match.params.invoiceId !== "_add") {
                    if (draftInvoice === "NO" && invoiceNumber.slice(0, 1) === "D") {
                        axios.get(API_URL + '/invoices')
                            .then((res) => {
                                var filteredInvoiceData = []
                                var allInvoiceData = []
                                //setInvoices(res.data.filter(item => item.invoiceNumber.slice(0,1) !== "D" && item.projectId === match.params.projectId))
                                //filteredInvoiceData = res.data.filter(item => item.invoiceNumber.slice(0,1) !== "D" && item.projectId === match.params.projectId)
                                allInvoiceData = res.data.filter(item => item.invoiceNumber.slice(0, 1) !== "D")
                                if (allInvoiceData.length === 0) {
                                    updateForm({ invoiceNumber: "80000", invoiceDate, invoicePaid, invoiceContactId, invoiceIssued, invoiceNotes, invoiceCustPo, clientId, invoiceLines, draftInvoice, projectId }).then(res => { navigate(-1) })
                                } else {
                                    updateForm({ invoiceNumber: String(Number(allInvoiceData.sort((item1, item2) => (Number(item1.invoiceNumber) < Number(item2.invoiceNumber)) ? -1 : (Number(item1.invoiceNumber) > Number(item2.invoiceNumber)) ? 1 : 0)[allInvoiceData.length - 1].invoiceNumber) + 1), invoiceDate, invoicePaid, invoiceContactId, invoiceIssued, invoiceNotes, invoiceCustPo, clientId, invoiceLines, draftInvoice, projectId }).then(res => { navigate(-1) })
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            }).finally(() => {
                                setInvoicesLoading(false)
                            });

                    } else {
                        updateForm({ invoiceNumber, invoiceDate, invoicePaid, invoiceContactId, invoiceIssued, invoiceNotes, invoiceCustPo, clientId, invoiceLines, draftInvoice, projectId }).then(res => { navigate(-1) })
                    }

                } else {
                    addForm({ invoiceNumber: invoiceNum, invoiceDate, invoicePaid, invoiceContactId, invoiceIssued, invoiceNotes, invoiceCustPo, clientId, invoiceLines, draftInvoice: "YES", projectId: match.params.projectId }).then(res => { navigate(-1) })
                }
            }else if (!invoiceDate){
                window.alert("Please select a date")
            }else if (!clientId){
                window.alert("Please confirm that a client has been selected")
            }else if (!invoiceContactId){
                window.alert("Please confirm that a contact has been selected")
            }
        }
    }

    const updateForm = async (form) => {

        await axios.put(API_URL + '/invoices/' + match.params.invoiceId, form)
    }

    const addForm = async (form) => {

        await axios.post(process.env.REACT_APP_API_URL + '/invoices', form)
    }

    const addInvoiceItem = () => {

        var uniqueId = uuidv4()

        var projServiceCustomer = projectServices.find(service => service.id === serviceToAdd).serviceInvoiceClient
        var projServiceCustomerContact = projectServices.find(service => service.id === serviceToAdd).serviceInvoiceClientContact
        var projServicePO = projectServices.find(service => service.id === serviceToAdd).customerPo
        var projServiceStatus = projectServices.find(service => service.id === serviceToAdd).serviceStatus

        //is the serviceStatus Quote?
        if (projServiceStatus.slice(0,5) === "Quote"){
            window.alert("This service line cannot be added to the billing plan because it's status is Quote")
        }else{

            //UNCOMMENT THIS
            if (projServiceCustomer.length===0 || projServiceCustomerContact.length===0) {  //if project service client is empty, get the user to add one
                window.alert("The 'client to invoice' and 'client contact' need to be set by the Finance Team before this service can be added to the billing plan")            
            }else if (!projServicePO && clients.find(client => client.id === clientId).requirePoForInvoicing==="YES"){  //No client PO but one is required
                window.alert("The client PO needs to be entered before this service can be added to the billing plan");
            }else{
                if (invoiceLines.length>0) {  // invoices lines already exist on the invoice
                    console.log("Here")

                    if (projServiceCustomer !== clientId) { // the client on the project service is not equal to the client on the invoice
                        window.alert("The client that has been set for the service does not match the client that this invoice is for.  Please review the details and try again.")
                    }
                    else if (projServicePO!==invoiceCustPo) {  //the service po doesn't match the invoice po
                        window.alert("The PO that has been set for the service does not match the PO applied to this invoice.  Please review the details and try again.")
                    // } else if (projServiceCustomerContact!==invoiceContactId) {  //the service contact doesn't match the invoice contact
                    //     window.alert("The client contact that has been set for the service does not match the client contact applied to this invoice.  Please review the details and try again.")
                    }
                    else {
                        setInvoiceLines((items) => { return [...items, { invoiceLineItemId: uniqueId, serviceId: serviceToAdd, invoiceLineDescription: descriptionToAdd, value: valueToAdd, invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: projectServices.find(item => item.id === serviceToAdd).serviceManager, projectManager: projects.find(item => item.id === match.params.projectId).projectManagerId }] })
                    }
        
                }else{  //the first record being added to the invoice, so use the service line PO and service client to invoice
                    console.log("Here")
                    setClientId(projServiceCustomer)
                    setInvoiceCustPo(projServicePO)
                    //setInvoiceContactId(contacts.filter(item => item.clientId === projServiceCustomer)[0].id)
                    setInvoiceContactId(projServiceCustomerContact)
                    setInvoiceLines((items) => { return [...items, { invoiceLineItemId: uniqueId, serviceId: serviceToAdd, invoiceLineDescription: descriptionToAdd, value: valueToAdd, invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: projectServices.find(item => item.id === serviceToAdd).serviceManager, projectManager: projects.find(item => item.id === match.params.projectId).projectManagerId }] })
                }
            }
        }
    }

    const deleteInvoiceItem = async (id) => {

        if (window.confirm('Are you sure you want to delete Invoice Line?')) {
            const index = invoiceLines.findIndex(item => item.invoiceLineItemId === id)

            if (invoiceLines.length===1){  //last record being deleted so reset inv client and po fields
                setClientId(await projects.find((item) => item.id === match.params.projectId).clientId)
                setInvoiceCustPo("")
                setInvoiceContactId(await projects.find((item) => item.id === match.params.projectId).projectClientContactId)
            }
            invoiceLines.splice(index, 1)
            setInvoiceLines(invoiceLines.filter((item) => item.invoiceLineItemId !== id))

        }

    }

    const confirmInvoice = () => {

        //Add check to make sure all invoice lines are 'OK to Invoice' === YES before confirming an invoice
        if (invoiceLines.filter((item) => item.okToInvoice === "NO").length === 0) {

            setDraftInvoice("NO")

        } else {
            window.alert("Please ensure all invoice lines are tagged as 'OK to Invoice' before confirming the invoice")
        }

    }

    useEffect(() => {

        axios.get(API_URL + '/projects', { headers: authHeader() })
            .then((response) => {
                setProjects(response.data.filter((item) => item.id === match.params.projectId))
                if (match.params.invoiceId === "_add") {
                    setClientId(response.data.find((item) => item.id === match.params.projectId).clientId)
                    setInvoiceContactId(response.data.find((item) => item.id === match.params.projectId).projectClientContactId)
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setProjectsLoading(false);
            });

        axios.get(API_URL + '/invoices')
            .then((res) => {
                setInvoices(res.data.filter((invoice) => invoice.projectId === match.params.projectId))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setInvoicesLoading(false);
            });

        if (match.params.invoiceId !== "_add") {

            axios.get(API_URL + '/invoices/' + match.params.invoiceId)
                .then((res) => {
                    setInvoiceNumber(res.data.invoiceNumber)
                    setInvoiceDate(res.data.invoiceDate)
                    setInvoicePaid(res.data.invoicePaid)
                    setInvoiceContactId(res.data.invoiceContactId)
                    setInvoiceIssued(res.data.invoiceIssued)
                    setInvoiceNotes(res.data.invoiceNotes)
                    setInvoiceCustPo(res.data.invoiceCustPo)
                    setClientId(res.data.clientId)
                    setInvoiceLines(res.data.invoiceLines)
                    setDraftInvoice(res.data.draftInvoice)
                    setProjectId(res.data.projectId)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setInvoiceLoading(false);
                });

        } else {
            setInvoiceLoading(false)
            setInvoicePaid("NO")
            setInvoiceIssued("NO")
            setAddToInvoiceToggle(true)
        }

        axios.get(API_URL + "/clients", { headers: authHeader() })
            .then((response) => {
                setClients(response.data.sort((item1, item2) => item1.clientName < item2.clientName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setClientsLoading(false);
                
            });

        axios.get(API_URL + '/contacts')
            .then((res) => {
                setContacts(res.data.sort((item1, item2) => item1.contactName < item2.contactName ? -1 : 0))
                // if (match.params.invoiceId === "_add") {
                //     setInvoiceContactId(res.data[0].id)
                // }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setContactsLoading(false);
            });

        axios.get(API_URL + '/invoices_schedule')
            .then((res) => {
                setInvoicesSchedule(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setInvoicesScheduleLoading(false);
            });

        axios.get(API_URL + '/projects_services_by_projectId/' + match.params.projectId, { headers: authHeader() })
            .then((response) => {
                setProjectServices(response.data.filter((item) => item.toBeDeleted === "NO").sort((item1, item2) => item1.service < item2.service ? -1 : 0))
                setServiceToAdd(response.data.filter((item) => item.toBeDeleted === "NO").sort((item1, item2) => item1.service < item2.service ? -1 : 0)[0].id)
                setServiceCommentsToAdd(response.data.filter((item) => item.toBeDeleted === "NO").sort((item1, item2) => item1.service < item2.service ? -1 : 0)[0].serviceComments)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setProjectServicesLoading(false);
            });

    }, [match.params.projectId, match.params.invoiceId])

    useEffect(() => {

        if (match.params.invoiceId !== "_add") {

            if (serviceToAdd && invoices.length > 0) {

                invoices.find((item) => item.id === match.params.invoiceId).invoiceLines = invoiceLines

                setValueToAdd((projectServices.find((service) => service.id === serviceToAdd).activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)) - serviceIncludedInBillingPlan(serviceToAdd))

            }

        }

    }, [invoiceLines, invoices])


    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (projectServices.length > 0 && !clientsLoading && !contactsLoading && !invoicesLoading && !invoiceLoading && !projectServicesLoading) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Add / Edit Project Invoice Details" section="Project Invoice Details" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        {match.params.invoiceId !== "_add" &&
                            <div className="form-control-8A">
                                <label>Invoice Number</label>
                                <input readOnly={true} className="bg-slate-100" type="text" placeholder="Enter Invoice Number Here" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} />
                            </div>
                        }

                        {draftInvoice === "YES" && <Button color="bg-bondi-blue" text="Confirm Invoice" onClick={() => confirmInvoice()} />}

                        <div className="form-control-8A">
                            <label>Invoice Date</label>
                            <input type="date" placeholder="Enter Invoice Date Here" value={invoiceDate} onChange={(e) => setInvoiceDate(e.target.value)} />
                        </div>

                        {draftInvoice === "NO" &&
                        <div>
                            <div className="form-control-8A">
                                <label>Invoice Issued</label>
                                <select value={invoiceIssued} onChange={(e) => setInvoiceIssued(e.target.value)}>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </div>
                            <div className="form-control-8A">
                                <label>Invoice Paid</label>
                                <select value={invoicePaid} onChange={(e) => setInvoicePaid(e.target.value)}>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                    <option value="CREDIT">CREDIT</option>
                                </select>
                            </div>
                        </div>
                        }

                        {invoiceLines.length > 0 &&
                        <div className="form-control-8A">
                            <label>Invoicing Client</label>

                                <select value={clientId} onChange={(e) => { setClientId(e.target.value); setInvoiceContactId(contacts.filter(item => item.clientId === e.target.value)[0].id) }} onFocus={(e) => { setClientId(e.target.value); setInvoiceContactId(contacts.filter(item => item.clientId === e.target.value)[0].id) }}>
                                    <option value="">Please Select...</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.id}>{client.clientName}</option>
                                    ))}
                                </select>
                        </div>
                        }
                        
                        {invoiceLines.length > 0 &&
                        <div className="form-control-8A">
                            <label>Invoicing Contact</label>
                            <select value={invoiceContactId} onChange={(e) => setInvoiceContactId(e.target.value)} onFocus={(e) => setInvoiceContactId(e.target.value)}>
                                {contacts.filter(item => item.clientId === clientId).map((contact) => (
                                    <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                ))}
                            </select>
                        </div>
                        }

                        <div className="form-control-8A">
                            <label>Invoicing Notes</label>
                            <input type="text" placeholder="Enter invoicing notes Here" value={invoiceNotes} onChange={(e) => setInvoiceNotes(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Client PO</label>
                            <input type="text" placeholder="Enter PO Number" value={invoiceCustPo} onChange={(e) => setInvoiceCustPo(e.target.value)} />
                            {/* <input className="bg-slate-100 cursor-not-allowed placeholder-gray-300" readOnly type="text" placeholder="No PO Number" value={invoiceCustPo} /> */}
                        </div>

                        {/* {match.params.invoiceId !== "_add" && 
                    <p className="font-bold text-xl">Invoice Items:</p>
                    } */}

                        <p className="font-bold text-xl">Invoice Items:</p>

                        {addToInvoiceToggle && (
                            <div>
                                <div className="grid grid-cols-3 gap-3">
                                    <div className="form-control-8A">
                                        <label className="text-center">Service</label>
                                        <select value={serviceToAdd} onChange={(e) => { setServiceToAdd(e.target.value); setServiceCommentsToAdd(projectServices.find((item) => item.id === e.target.value).serviceComments); setValueToAdd((projectServices.find((service) => service.id === e.target.value).activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)) - serviceIncludedInBillingPlan(e.target.value)); setDescriptionToAdd(projects.find((item) => item.id === match.params.projectId).projectNumber + " - " + projects.find((item) => item.id === match.params.projectId).projectName + ": " + projectServices.find((item) => item.id === e.target.value).service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>") }}>
                                            {projectServices.filter(item => item.serviceStatus === "Live").map((service) => (
                                                <option key={service.id} value={service.id}>{service.service + ' (Work Stage: ' + service.workStage + '; Comments: ' + service.serviceComments + ')'}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Service Comments</label>
                                        <input readOnly={true} className="bg-slate-100"  type="text" value={serviceCommentsToAdd} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Value</label>
                                        <input type="number" placeholder="Enter Value Here" value={valueToAdd} onChange={(e) => setValueToAdd(Number(e.target.value))} />
                                    </div>
                                </div>
                                <div className="form-control-8A">
                                    <label className="text-center pb-1">Description</label>
                                    <textarea className="w-full h-16 border-2 rounded-lg shadow-md" type="text" placeholder="Enter LCAPMDescription Here" value={descriptionToAdd} onChange={(e) => setDescriptionToAdd(e.target.value)} />
                                </div>
                                <div className="h3">
                                    <Button color="bg-bondi-blue" text="Add to invoice >>" onClick={() => addInvoiceItem()} />
                                </div>
                            </div>
                        )}

                        {/* {!addToInvoiceToggle &&
                    match.params.invoiceId !== "_add" &&
                        <Button color="bg-bondi-blue" text="Click here to add invoice items" onClick={() => { setValueToAdd((projectServices.find((service) => service.id === serviceToAdd).activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)) - serviceIncludedInBillingPlan(serviceToAdd)); setDescriptionToAdd(projects.find((item) => item.id === match.params.projectId).projectNumber + " - " + projects.find((item) => item.id === match.params.projectId).projectName + ": " + projectServices.find((service) => service.id === serviceToAdd).service); setAddToInvoiceToggle(true) }} />
                    } */}

                        {!addToInvoiceToggle &&
                            <Button color="bg-bondi-blue" text="Click here to add invoice items" onClick={() => { setValueToAdd((projectServices.find((service) => service.id === serviceToAdd).activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)) - serviceIncludedInBillingPlan(serviceToAdd)); setDescriptionToAdd(projects.find((item) => item.id === match.params.projectId).projectNumber + " - " + projects.find((item) => item.id === match.params.projectId).projectName + ": " + projectServices.find((service) => service.id === serviceToAdd).service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>"); setAddToInvoiceToggle(true) }} />
                        }

                        {match.params.invoiceId !== "_add" && (
                            <div className="py-2 align-middle inline-block min-w-full">
                                <div className="overflow-hidden rounded-2xl shadow-md shadow-slate-200 border border-slate-200">
                                    <table className="objects-8A min-w-full divide-y divide-gray-200">
                                        <thead className="bg-pacific-blue-50">
                                            <tr>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Value</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Description</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> OK to Invoice</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {invoiceLines.map((item) => (
                                                <tr key={item.invoiceLineItemId}>
                                                    <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {projectServices.find(service => service.id === item.serviceId).service}</td>
                                                    {item.okToInvoice === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(item.value); setFieldToSendToModal("value"); setObjectIdToSendToModal(match.params.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(item.invoiceLineItemId) }} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                    </svg>{item.value}</td> : <td className="pl-2">{item.value}</td>}
                                                    
                                                    {item.okToInvoice === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(item.invoiceLineDescription); setFieldToSendToModal("invoiceLineDescription"); setObjectIdToSendToModal(match.params.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(item.invoiceLineItemId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                    </svg>{item.invoiceLineDescription}</td> : <td className="pl-2">{item.invoiceLineDescription}</td>}
                                                    <td onClick={() => { setShowModal(true); setValueToSendToModal(item.okToInvoice); setFieldToSendToModal("okToInvoice"); setObjectIdToSendToModal(match.params.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLines"); setCollectionArrayIdToSendToModal(item.invoiceLineItemId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                    </svg> {item.okToInvoice}</td>
                                                    <td className="px-4 md:px-2 py-2 whitespace-nowrap">
                                                        <Options deleteFunction={deleteInvoiceItem} deleteObject={item.invoiceLineItemId}></Options>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        )}

                        {match.params.invoiceId === "_add" && (
                            <div className="py-2 align-middle inline-block min-w-full">
                                <div className="overflow-hidden rounded-2xl shadow-md shadow-slate-200 border border-slate-200">
                                    <table className="objects-8A min-w-full divide-y divide-gray-200">
                                        <thead className="bg-pacific-blue-50">
                                            <tr>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Value</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Description</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> OK to Invoice</th>
                                                <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {invoiceLines.map((item) => (
                                                <tr key={item.invoiceLineItemId}>
                                                    <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {projectServices.find(service => service.id === item.serviceId).service}</td>
                                                    <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.value}</td>
                                                    <td className="px-4 md:px-2 py-2"> {item.invoiceLineDescription}</td>
                                                    <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.okToInvoice}</td>
                                                    <td className="px-4 md:px-2 py-2 whitespace-nowrap">
                                                        <Options deleteFunction={deleteInvoiceItem} deleteObject={item.invoiceLineItemId}></Options>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        )}


                        <br></br>
                        <br></br>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                    <button onClick={() => navigate(-1)} className="btn-8A w-full">Cancel</button>
                    
                    <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={invoiceLines} projectId={match.params.projectId} onClose={() => { setShowModal(false); invoices.find((item) => item.id === match.params.invoiceId).invoiceLines = invoiceLines; setValueToAdd((projectServices.find((service) => service.id === serviceToAdd).activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)) - serviceIncludedInBillingPlan(serviceToAdd)); setDescriptionToAdd(projects.find((item) => item.id === match.params.projectId).projectNumber + " - " + projects.find((item) => item.id === match.params.projectId).projectName + ": " + projectServices.find((service) => service.id === serviceToAdd).service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>") }} show={showModal} title={"Update Invoice Line Item"} />

                </div>
            </div>
        )

    } else {

        return (
            <div>
                <div className="container-form-8A">
                    <Header title="Project - Add / Edit Project Invoice Details" section="Project Invoice Details" />
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateClientProjectInvoiceDetails